import React, { useState } from "react";
import { Card } from "antd-mobile";
import { Upload } from "antd";

import { PlusOutlined } from "@ant-design/icons";

// const timestamp = new Date().getTime();

const maxCount = 5;

// const initialState = (urlList = []) => {
//   console.log("initialState", urlList);
//   const _list = urlList.map(l => {
//     const url = typeof l === "string" ? l : URL.createObjectURL(l);
//     return { url };
//   });
//   console.log("_list", _list);
//   return urlList;
// };

/**
 * @param {*} param0.onChange 回傳 [file,file,file..]
 * @param {*} param0.urlList 帶入後轉為 { url: 'https://xxx' }
 * @returns
 */
function ImageUploaderC({
  // urlList = [],
  onChange = () => {},
  // loading = false,
}) {
  // const fileList = useRef([]);

  // const _onChange = (files = []) => {
  //   console.log("fileList current", fileList.current);
  //   // console.log("_onChange", files);
  //   // let fileList = [];
  //   // files.forEach((f, idx) => {
  //   //   const file = f.file;
  //   //   if (file) {
  //   //     fileList.push(file);
  //   //   }
  //   // });
  //   // console.log("fileList", fileList);
  //   onChange(fileList.current);
  // };

  // const upload = file => {
  //   const url = URL.createObjectURL(file);
  //   let newFile = new File(
  //     [file],
  //     `${url.slice(url.lastIndexOf("/") + 1)}${file.name.slice(
  //       file.name.lastIndexOf("."),
  //     )}`,
  //     { type: "image/png" },
  //   );

  //   console.log("upload url", url);
  //   console.log("upload newFile", newFile);

  //   fileList.current = [...fileList.current, newFile];

  //   return {
  //     url: url,
  //     file: newFile,
  //   };
  // };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const [fileList, setFileList] = useState([]);

  const handleChange = ({ file, fileList }) => {
    setFileList(fileList);
  };

  const beforeUpload = file => {
    let newFile = new File([file], `${file.uid}`, { type: "image/png" });
    onChange(newFile);
    return null;
  };

  return (
    <div className="mt-1 mb-1">
      <div className="flex justify-between align-center mb-1">
        <h6 className="text-12  mb-0">上传回单</h6>
        <span className="text-12 text-red">
          如若不上传回单，将无法进行下一步操作
        </span>
      </div>
      <Card>
        <h6 className="text-12" style={{ marginBottom: "0.8em" }}>
          最多选择 <span className="text-red">{maxCount}</span> 张图片
        </h6>

        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          beforeUpload={file => beforeUpload(file)}
          accept="image/*"
          // capture="camera"
        >
          {fileList.length >= maxCount ? null : uploadButton}
        </Upload>

        {/* <ImageUploader
          // value={initialState(urlList)}
          onChange={_onChange}
          beforeUpload={null}
          upload={upload}
          accept="image/*"
          multiple
          disableUpload={loading}
          maxCount={maxCount}
          onCountExceed={exceed => {
            Toast.show(`最多选择 ${maxCount} 张图片，你多选了 ${exceed} 张`);
          }}
        ></ImageUploader> */}
      </Card>
    </div>
  );
}

export default ImageUploaderC;
