import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Input } from "antd-mobile";

import useDetail from "@/hook/useDetail";
import { addCard, selectCard } from "@/store/slice/card";

import FormExtraLabel from "@/components/mobile/FormExtraLabel";
import {
  PickerBank,
  PickerArea,
} from "@/components/mobile/FormItemSelectorPicker";
import ModalConfirm from "@/components/mobile/ModalConfirm";

function CardAdd() {
  const { loading, handleAdd } = useDetail({ id: null }, selectCard, false);

  const [visibleSuccessModal, setSuccessModal] = useState(false);

  const history = useHistory();
  const goPath = () => history.push("/card");

  const onFinish = val => {
    const params = {
      ...val,
      bank_code: val.bank_code[0],
      ...(val.prov ? { prov: val.prov[0], city: val.prov[1] } : {}),
    };
    handleAdd({
      action: addCard,
      onSuccess: () => setSuccessModal(true),
      ...params,
    });
  };

  return (
    <div className="mb-4">
      <Form
        name="form"
        onFinish={onFinish}
        footer={
          <Button block type="submit" color="primary" loading={loading}>
            立即添加
          </Button>
        }
      >
        <Form.Item
          name="fund_password"
          label={
            <FormExtraLabel
              label="资金密码"
              extra="忘记资金密码?"
              isService={true}
            />
          }
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请输入资金密码" />
        </Form.Item>

        <Form.Item
          name="name"
          label="持卡人"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请输入持卡人姓名" />
        </Form.Item>
        <Form.Item
          name="account"
          label="银行卡号"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请输入银行卡号" />
        </Form.Item>
        <Form.Item
          name="bank_code"
          label="开户行"
          rules={[{ required: true, message: "必填" }]}
        >
          <PickerBank />
        </Form.Item>
        <Form.Item name="sub_bank" label="分行">
          <Input placeholder="请输入分行" />
        </Form.Item>
        <Form.Item name="prov" label="省市">
          <PickerArea />
        </Form.Item>
      </Form>
      <ModalConfirm
        type="success"
        visible={visibleSuccessModal}
        text={
          <>
            操作成功
            <br />
            反回收款方式
          </>
        }
        onConfirm={goPath}
      />
    </div>
  );
}

export default CardAdd;
