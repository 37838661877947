import { Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { dateFormat, priceFormat } from "@/utils/format";
import { copyText } from "@/utils/others";
import { Currency, transferStatus, isSuccessLang, FeeType } from "@/utils/enum";
import Tag from "@/components/Tag";

export const accountColumns = [
  {
    title: "银行",
    dataIndex: "bank_name",
  },
  {
    title: "分行",
    dataIndex: "sub_bank",
  },
  {
    title: "帐号",
    dataIndex: "account",
    render: val => (
      <>
        {val}
        <Button
          type="link"
          size="small"
          icon={<CopyOutlined />}
          onClick={() => copyText(val)}
        />
      </>
    ),
  },
  {
    title: "真实姓名",
    dataIndex: "name",
    render: val => (
      <>
        {val}
        <Button
          type="link"
          size="small"
          icon={<CopyOutlined />}
          onClick={() => copyText(val)}
        />
      </>
    ),
  },
];

export const columns = [
  { title: "ID", dataIndex: "id", sorter: true },
  { title: "开发者订单号", dataIndex: "order_no" },
  {
    title: "出款金额",
    dataIndex: "amount",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "实际出款金额",
    dataIndex: "amount_paid",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "出款货币类型",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "商户手续费类型",
    dataIndex: "app_fee_rate",
    render: val => FeeType[val] || "",
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "商户实收手续费",
    dataIndex: "amount_app_fee",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "出帐金额",
    dataIndex: "amount_app",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "创建时间",
    dataIndex: "created",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
  {
    title: "备注",
    dataIndex: "note",
    sorter: true,
  },
  {
    title: "是否成功",
    dataIndex: "succeeded",
    render: (val, { status }) =>
      [10, 13, 14, 15, 16, 17].includes(status) ? (
        <Tag val={val}>{isSuccessLang(val)}</Tag>
      ) : null,
  },
  {
    title: "成功时间",
    dataIndex: "succeeded_at",
    render: (val, { status, succeeded }) =>
      [10, 13, 14, 15, 16, 17].includes(status) && succeeded === true
        ? dateFormat(val)
        : null,
  },
  {
    title: "订单状态",
    dataIndex: "status",
    className: "text-nowrap",
    render: val => transferStatus[val],
  },
];

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

// 定義需要 額外 Render 的欄位
export const exportColumnsRenderMap = {
  // status: val => OrderStatus[val] || "",
  // pay_type: val => WXPayType[val] || "",
  // pay_method: val => PayMethod[val] || "",
  // payer_cred: val => JSON.stringify(val),
  // pay_status: val => PayStatus[val] || "",
  // paid_at: val => dateFormat(val),
  // approval_status: val => OrderStatus[val] || "",
  // notify_status: val => OrderStatus[val] || "",
  // notified_at: val => dateFormat(val),
  amount: (val, record) => excelPriceFormat(val, record.currency),
  amount_paid: (val, record) => excelPriceFormat(val, record.currency),
  currency: val => Currency[val] || "",
  created: val => dateFormat(val),
  succeeded: (val, { status }) =>
    [10, 13, 14, 15, 16, 17].includes(status) ? isSuccessLang(val) : null,
  succeeded_at: (val, { status, succeeded }) =>
    [10, 13, 14, 15, 16, 17].includes(status) && succeeded === true
      ? dateFormat(val)
      : null,

  // bonus: (val, record) => excelPriceFormat(val, record.currency),
  // amount_rand: (val, record) => excelPriceFormat(val, record.currency),

  // paid: val => isActiveLang(val),
  // approved: val => isActiveLang(val),
  // is_crypto: val => isActiveLang(val),
  // is_online: val => isActiveLang(val),
  // settled: val => isActiveLang(val),
  // amount_gateway: (val, record) => excelPriceFormat(val, record.currency),
  // amount_app: (val, record) => excelPriceFormat(val, record.currency),
  // amount_app_fee: (val, record) => excelPriceFormat(val, record.currency),

  // updated: val => dateFormat(val),
};

const detailColumnsCardArr = [
  "id",
  "order_no",
  "bank_name",
  "sub_bank",
  "account",
  "name",
  "amount",
  "amount_paid",
  "currency",
  "created",
  "succeeded",
  "status",
];
const detailColumnsUSDTArr = [
  "id",
  "order_no",
  "bank_name",
  "sub_bank",
  "account",
  "name",
  "amount",
  "amount_paid",
  "currency",
  "created",
  "succeeded",
  "status",
];
export const sumColumns = [
  {
    title: "订单次数",
    dataIndex: "total_times",
  },
  {
    title: "订单成功次数",
    dataIndex: "total_succeeded_times",
  },
  {
    title: "订单成功金额",
    dataIndex: "total_succeeded_amount",
    render: val => priceFormat({ val, currency: 0 }),
  },
];
const formatColumn = arr => columns.filter(i => arr.indexOf(i.dataIndex) >= 0);
export const detailColumnsCard = formatColumn(detailColumnsCardArr);
export const detailColumnsUSDT = formatColumn(detailColumnsUSDTArr);
