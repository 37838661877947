import React from "react";
import { useLocation } from "react-router-dom";
import { Card, SpinLoading } from "antd-mobile";

import { getOrder, selectOrder } from "@/store/slice/order";
import useDetail from "@/hook/useDetail";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";

import { ListItem } from "@/components/mobile/List";
import { dateFormat, priceFormat, cnyPriceFormat } from "@/utils/format";
import { OrderStatus } from "@/utils/enum";

const StatusDisplay = ({ icon, text, color }) => {
  return (
    <div className="text-center" style={{ color }}>
      {icon({ style: { fontSize: "3em" } })}
      <div className="text-14">{text}</div>
    </div>
  );
};

const orderStatusHandleMap = {
  3: {
    color: "var(--adm-color-primary)",
    text: "未付款",
    icon: p => <CloseCircleFilled {...p} />,
  },
  5: {
    color: "var(--grey-1)",
    text: "失败",
    icon: p => <CloseCircleFilled {...p} />,
  },
  7: {
    color: "var(--grey-1)",
    text: "已取消",
    icon: p => <CloseCircleFilled {...p} />,
  },
  11: {
    color: "var(--adm-color-success)",
    text: "待通知",
    icon: p => <CheckCircleFilled {...p} />,
  },
  15: {
    color: "var(--adm-color-success)",
    text: "已完成",
    icon: p => <CheckCircleFilled {...p} />,
  },
};

function SellUsdtOrderDetail() {
  const { state } = useLocation();

  const { currentRow, loading } = useDetail(
    { action: getOrder, id: state.id },
    selectOrder,
  );

  if (loading || !currentRow.id)
    return (
      <SpinLoading
        color="primary"
        style={{ margin: "auto", "--size": "48px" }}
      />
    );

  return (
    <div className="box-wrap mt-1 mb-4">
      <Card>
        <div className="mt-1 mb-1 text-center">
          {orderStatusHandleMap[currentRow.status] ? (
            StatusDisplay({ ...orderStatusHandleMap[currentRow.status] })
          ) : (
            <span className="text-14">{OrderStatus[currentRow.status]}</span>
          )}
        </div>
        <ListItem label="订单号" value={currentRow.order_no} showCopy={true} />
        <ListItem
          label="数量"
          value={priceFormat({
            val: currentRow.amount,
            currency: currentRow.currency,
          })}
          style={{ color: "var(--adm-color-primary)" }}
        />
        <ListItem
          label="汇率"
          value={`¥ ${currentRow.credential.data.price} / USDT`}
          style={{ color: "var(--grey-2)" }}
        />

        <ListItem
          label="总价"
          value={`${cnyPriceFormat(currentRow.credential.data.cny_price)} CNY`}
        />

        <ListItem
          label="交易时间"
          value={dateFormat(currentRow.created)}
          style={{ color: "var(--grey-2)" }}
          showBorderBottom={false}
        />
      </Card>

      <Card className="mt-1">
        <h5 className="mb-1">收款账户</h5>
        <ListItem
          label="姓名"
          value={currentRow.recipient_cred.account}
          showCopy={true}
        />
        <ListItem
          label="银行卡"
          value={currentRow.recipient_cred.account}
          showCopy={true}
        />
        <ListItem
          label="银行"
          value={`(${currentRow.recipient_cred.bank_code})${currentRow.recipient_cred.open_bank}`}
          showCopy={true}
        />
        <ListItem
          label="分行"
          value={currentRow.recipient_cred.sub_bank}
          showCopy={true}
          showBorderBottom={false}
        />
      </Card>
    </div>
  );
}

export default SellUsdtOrderDetail;
