import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Divider, Switch, Space, Toast } from "antd-mobile";
import { ExclamationCircleFilled } from "@ant-design/icons";

import {
  selectCard,
  getCardsV2,
  activeCard,
  deleteCard,
  defaultCard,
} from "@/store/slice/card";
import useDetail from "@/hook/useDetail";

import FullLoading from "@/components/mobile/FullLoading";
import ModalConfirm from "@/components/mobile/ModalConfirm";
import { selectAuth } from "@/store/slice/auth";

function BankCard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const goPath = path => {
    history.push(path);
  };

  const { user } = useSelector(selectAuth);
  const { list } = useSelector(selectCard);
  const [loading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const listSort = useMemo(() => {
    const _l = [...list];
    _l.sort(({ is_default }) => (is_default ? -1 : 1));
    return _l;
  }, [list]);

  const { loading: activeLoading, handleEdit } = useDetail(
    { id: null },
    selectCard,
  );

  const [deleteID, setDeleteID] = useState(null);
  const handleDeleteCard = () => {
    handleEdit({
      action: deleteCard,
      onSuccess: () => {
        setDeleteID(null);
        handleGetList();
      },
      id: deleteID,
    });
  };

  const handleActiveCard = (id, val) => {
    handleEdit({ action: activeCard, id, is_active: val });
  };

  const handleSetDefault = (id, is_default) => {
    if (is_default) {
      Toast.show({ icon: "success", content: "操作成功" });
      return;
    }
    handleEdit({
      action: defaultCard,
      onSuccess: () => handleGetList(),
      id,
    });
  };

  const handleGetList = async () => {
    setLoading(true);
    await dispatch(getCardsV2());
    setLoading(false);
  };

  useEffect(() => {
    handleGetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="box-wrap mb-4">
      <div className="flex justify-between align-center mt-1">
        <p className="mb-0">
          我的银行卡 <span className="text-grey-1">{list?.length || 0}/5</span>
        </p>
        {user.fund_password && (
          <Button
            size="mini"
            fill="none"
            style={{ "--text-color": "var(--adm-color-info-1)" }}
            onClick={() => setEdit(!isEdit)}
          >
            {isEdit ? "完成" : "管理"}
          </Button>
        )}
      </div>
      <Divider style={{ marginTop: "8px" }} />

      {user.fund_password ? (
        <>
          <FullLoading
            loading={loading || activeLoading}
            color="primary"
            style={{ margin: "auto", "--size": "48px" }}
          >
            {listSort.map(item => (
              <div key={item.id}>
                <div className="flex">
                  <div style={{ width: "32px", marginRight: "0.8em" }}>
                    <img src={item.logo_url} width={"100%"} alt="" />
                  </div>
                  <div style={{ flex: "1 1 auto" }}>
                    <h4 className="text-14">{item.bank_name}</h4>
                    <p className="text-12 text-grey-2 mb-0">{item.account}</p>
                    <p className="text-12 text-grey-2 mb-0">{item.name}</p>
                  </div>

                  <Space direction="vertical" align="end">
                    <Button
                      size="mini"
                      onClick={() => handleSetDefault(item.id, item.is_default)}
                      style={{
                        "--text-color": "white",
                        "--background-color": item.is_default
                          ? "rgb(103 189 78)"
                          : "rgb(255 171 171)",
                      }}
                    >
                      {item.is_default ? "默认" : "设为默认"}
                    </Button>

                    {isEdit ? (
                      <Button
                        size="mini"
                        onClick={() => setDeleteID(item.id)}
                        color="primary"
                        fill="outline"
                      >
                        删除
                      </Button>
                    ) : (
                      <Switch
                        defaultChecked={item.is_active}
                        onChange={val => handleActiveCard(item.id, val)}
                        style={{
                          "--height": "20px",
                          "--width": "40px",
                          "--adm-color-primary": "#35cc90",
                        }}
                      />
                    )}
                  </Space>
                </div>
                <Divider />
              </div>
            ))}
          </FullLoading>
          {list?.length < 5 && (
            <div className="flex-center">
              <Button color="primary" onClick={() => goPath("/cardAdd")}>
                + 添加银行卡
              </Button>
            </div>
          )}

          <ModalConfirm
            visible={Boolean(deleteID)}
            onConfirm={handleDeleteCard}
            onClose={() => setDeleteID(null)}
            text="确定删除该银行卡?"
          />
        </>
      ) : (
        // 未設定資金密碼
        <div className="flex-center mt-1">
          <ExclamationCircleFilled
            style={{
              fontSize: "4em",
              color: "var(--adm-color-primary)",
              marginBottom: "0.2em",
            }}
          />
          <p className="text-center">
            未设置资金密码!
            <br />
            为了您的账户安全，请设置资金密码!
          </p>
          <Button color="primary" onClick={() => goPath("/fundPassword")}>
            去设置
          </Button>
        </div>
      )}
    </div>
  );
}

export default BankCard;
