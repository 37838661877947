import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, List } from "antd-mobile";
import { useHistory } from "react-router-dom";

import { selectAuth, logout } from "@/store/slice/auth";

function Setting() {
  const dispatch = useDispatch();
  const history = useHistory();
  const goPath = path => history.push(path);
  const { user } = useSelector(selectAuth);

  const handleLogout = async () => {
    history.push("/Login");
    await dispatch(logout());
  };

  return (
    <>
      <List header="账户安全">
        <List.Item
          extra={user.fund_password ? "修改" : ""}
          onClick={() => goPath("/fundPassword")}
        >
          设置资金密码
        </List.Item>
      </List>
      <div className="box-wrap mt-auto" style={{ marginBottom: "1em" }}>
        <Button block onClick={handleLogout}>
          退出登陆
        </Button>
      </div>
    </>
  );
}

export default Setting;
