import React from "react";
import { Card } from "antd-mobile";

import { ExclamationCircleFilled } from "@ant-design/icons";

import { dateFormat, priceFormat } from "@/utils/format";
import { ListItem } from "@/components/mobile/List";
import BlockBtnGroup from "@/components/mobile/BlockBtnGroup";

import { useHistory } from "react-router-dom";

const TransferSuccess = ({ data = {} }) => {
  const history = useHistory();
  const activeBtnGroup = [
    {
      key: "1",
      label: "返回首页",
      color: "primary",
      fill: "outline",
      block: true,
      onClick: () => {
        history.push("/");
      },
    },
    {
      key: "2",
      label: "再来一单",
      color: "primary",
      block: true,
      onClick: () => {
        history.push("/transferList");
      },
    },
  ];
  return (
    <div className="transfer box-wrap mt-1">
      <Card>
        <div className="flex-center">
          <ExclamationCircleFilled className="icon mb-1" />
          <h2 className="text-16">提交成功，存款资料审核中</h2>
        </div>
        <ListItem label="支付单号" value={data.order_no} showCopy={true} />
        <ListItem label="收款账号" value={data.account} />
        <ListItem label="收款人姓名" value={data.name} />
        <ListItem label="付款时间" value={dateFormat(data.paid_at)} />
        <ListItem
          label="付款金额"
          value={priceFormat({
            val: data.amount_paid,
            currency: data.currency,
          })}
          showBorderBottom={false}
        />
      </Card>
      <BlockBtnGroup source={activeBtnGroup} />
    </div>
  );
};

export default TransferSuccess;
