import { getTransfersClaim } from "@/store/slice/transfer";
import TransferTypes from "./indexTemplate";

const TransferFinished = () => {
  return (
    <TransferTypes
      key="finish"
      action={getTransfersClaim}
      params={{ status__in: "9,10,15,16" }}
    />
  );
};
export default TransferFinished;
