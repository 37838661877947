import { rangeDateFormat } from "./format";
import moment from "moment";

export const todayRangeFormat = () => {
  return rangeDateFormat([moment().startOf("days"), moment().endOf("day")]);
};

export const dayAgoRangeFormat = (dayAgo = 1) => {
  return rangeDateFormat([
    moment().subtract(dayAgo, "days").startOf("days"),
    moment().subtract(dayAgo, "days").endOf("day"),
  ]);
};

const today = moment().startOf("days");
const todayEnd = moment().endOf("days");
const threeDaysAgo = moment().subtract(3, "days").startOf("days");
const sevenDaysAgo = moment().subtract(7, "days").startOf("days");
const thirtyDaysAgo = moment().subtract(30, "days").startOf("days");

export const todayDate = today.toDate();
export const todayEndDate = todayEnd.toDate();
export const threeDaysAgoDate = threeDaysAgo.toDate();
export const sevenDaysAgoDate = sevenDaysAgo.toDate();
export const thirtyDaysAgoDate = thirtyDaysAgo.toDate();

export const formatDateStr = (dataStr, format = "YYYY-MM-DD") => {
  return moment(dataStr).format(format);
};

export const compareRangeDateStrSize = (d1Str, d2Str) => {
  const date1 = moment(d1Str);
  const date2 = moment(d2Str);

  if (date1.isBefore(date2)) {
    console.log("date1 在 date2 之前");
    return true;
  } else if (date1.isSame(date2)) {
    console.log("date1 和 date2 相同");
    return true;
  } else {
    console.log("date1 在 date2 之後");
    return false;
  }
};

export const formattedMinTime = (seconds = 0) => {
  const duration = moment.duration(seconds, "seconds");
  const minutes = Math.floor(duration.asMinutes());
  const secondsRemaining = Math.floor(duration.asSeconds()) % 60;
  const formattedTime = `${minutes}.${secondsRemaining
    .toString()
    .padStart(2, "0")} 分钟`;
  return formattedTime;
};
