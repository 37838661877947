import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "uuii",
  initialState: {
    navBar: { SellUsdtOrderList: "inProgress" }, // completed
  },
  reducers: {
    setNavBar: (state, action) => {
      state.navBar = action.payload;
    },
  },
});
export const { setNavBar } = slice.actions;
export const selectUuii = state => state.uuii;
export default slice.reducer;
