import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Select, Modal, Checkbox, Row, Col } from "antd";
const { Option } = Select;

const ColumnsSelect = ({ columns, value, onChange }) => {
  const { pathname } = useLocation();

  const [options, setOptions] = useState(columns);
  const handleChange = value => {
    onChange({
      columns: columns.filter(i => value.indexOf(i.dataIndex) > -1),
      pathname,
    });
  };
  const handleSearch = value => {
    const filterOptions = columns.filter(
      i => i.title.includes(value) || i.dataIndex.includes(value),
    );
    setOptions(filterOptions);
  };
  return (
    <>
      自订列表：
      <Select
        size="middle"
        mode="multiple"
        filterOption={false}
        value={value.map(i => i.dataIndex)}
        onChange={handleChange}
        onSearch={handleSearch}
        onBlur={() => setOptions(columns)}
        style={{ minWidth: 240 }}
      >
        {options.map(i => (
          <Option key={i.dataIndex} value={i.dataIndex}>
            {i.title}
          </Option>
        ))}
      </Select>
    </>
  );
};

export const ColumnsCheckbox = ({
  columns,
  value: checkedColumns,
  onChange = () => {},
}) => {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);
  const handleClick = () => setVisible(true);
  const handleCancel = () => setVisible(false);
  const handleChange = (e, item) => {
    const tempColumns = e.target.checked
      ? [...checkedColumns, item]
      : checkedColumns.filter(cl => cl.dataIndex !== item.dataIndex);
    onChange({ columns: tempColumns, pathname });
  };
  return (
    <>
      <Button type="default" size="small" onClick={handleClick}>
        自订列表栏位
      </Button>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        closable={false}
        width={"100%"}
        centered
        footer={[
          <Button key="close" type="primary" onClick={handleCancel}>
            关闭
          </Button>,
        ]}
      >
        <Row>
          {columns.map(el => (
            <Col span={24} style={{ marginBottom: "8px" }} key={el.dataIndex}>
              <Checkbox
                onChange={e => handleChange(e, el)}
                style={{ width: "100%" }}
                defaultChecked={checkedColumns.some(
                  v => v.dataIndex === el.dataIndex,
                )}
              >
                {el.title}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default ColumnsSelect;
