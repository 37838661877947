import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";

export const login = async (data = {}) => {
  return await request({
    url: "/auth/login/agent",
    method: "post",
    data,
  });
};

export const getOptStatus = createAsyncThunk("auth/optStatus", async () => {
  const { status, data } = await request({
    url: "/auth/agent/opt",
    method: "get",
  });
  if (status !== 200) return;
  return data;
});

export const verifyOpt = async data => {
  const res = await request({
    url: "/auth/otp/verify",
    method: "post",
    data,
  });
  return res;
};

export const putIsTransfer = async data => {
  const res = await request({
    url: "api/agent/agents/is_transfer",
    method: "post",
    data,
  });
  return res;
};

export const putIsOrder = async data => {
  const res = await request({
    url: "api/agent/agents/is_order",
    method: "post",
    data,
  });
  return res;
};

export const slice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) || null,
    isOtp: false,
  },
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.user = action.payload;
    },
    logout: state => {
      state.user = null;
      localStorage.removeItem("user");
    },
  },
  extraReducers: {
    [getOptStatus.fulfilled]: (state, action) => {
      state.isOtp = action.payload;
    },
  },
});
export const { setUser, logout } = slice.actions;
export const selectAuth = state => state.auth;
export default slice.reducer;
