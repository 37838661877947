import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";

export const getService = createAsyncThunk(
  "service/getService",
  async (params = {}) => {
    const res = await request({
      url: "/api/config/customer_service",
      method: "get",
      params,
    });
    return res;
  },
);

export const slice = createSlice({
  name: "service",
  initialState: {
    url: "",
    visible: false,
    newMsg: "",
  },
  reducers: {
    setServiceVisible: (state, action) => {
      state.visible = action.payload;
    },
    setNewMsg: (state, action) => {
      state.newMsg = action.payload;
    },
  },
  extraReducers: {
    [getService.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.url = data.url;
    },
  },
});
export const { setServiceVisible, setNewMsg } = slice.actions;
export const selectService = state => state.service;
export default slice.reducer;
