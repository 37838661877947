import { getComponent } from "@/utils/others";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => getComponent("Home"),
    exact: true,
    displayName: "首頁",
    showNavBar: false,
  },

  {
    path: "/userCenter",
    name: "UserCenter",
    component: () => getComponent("UserCenter"),
    exact: true,
    displayName: "我的账户",
    showNavBar: true,
  },

  {
    path: "/withdraw",
    name: "Withdraw",
    component: () => getComponent("Withdraw"),
    exact: true,
    displayName: "提現",
    showNavBar: true,
    containerBg: "var(--adm-color-background)",
  },

  // 自助進貨 (代付)
  {
    path: "/transferList",
    name: "TransferList",
    component: () => getComponent("TransferList"),
    exact: true,
    displayName: "抢单",
    showNavBar: true,
    goBackPath: "/",
  },
  {
    path: "/transfer",
    name: "Transfer",
    component: () => getComponent("Transfer"),
    exact: true,
    displayName: "拼多多訂單",
    showNavBar: true,
  },

  // 自助出貨（代收）
  {
    path: "/orderList",
    name: "OrderList",
    component: () => getComponent("OrderList"),
    displayName: "京东商城出售",
    showNavBar: true,
    goBackPath: "/",
  },
  {
    path: "/orderDetail",
    name: "OrderDetail",
    component: () => getComponent("OrderDetail"),
    displayName: "订单详情",
    showNavBar: true,
  },

  // 財務中心
  {
    path: "/financialCenter",
    name: "FinancialCenter",
    component: () => getComponent("FinancialCenter"),
    exact: true,
    displayName: "財務中心",
    showNavBar: true,
  },
  {
    path: "/financialCenter/agentDaily",
    name: "AgentDaily",
    component: () => getComponent("AgentDaily"),
    exact: true,
    displayName: "我的報表",
    showNavBar: true,
  },
  {
    path: "/financialCenter/subAgentDaily",
    name: "SubAgentDaily",
    component: () => getComponent("SubAgentDaily"),
    displayName: "子代理报表",
    showNavBar: true,
  },

  // 團隊中心
  {
    path: "/subAgent",
    name: "SubAgent",
    component: () => getComponent("SubAgent"),
    exact: true,
    displayName: "团队中心",
    showNavBar: true,
  },

  // 銀行卡
  {
    path: "/card",
    name: "Card",
    component: () => getComponent("Card"),
    exact: true,
    displayName: "收款方式",
    showNavBar: true,
    containerBg: "var(--adm-color-background)",
  },
  {
    path: "/cardAdd",
    name: "CardAdd",
    component: () => getComponent("CardAdd"),
    exact: true,
    displayName: "添加收款方式",
    showNavBar: true,
    containerBg: "var(--adm-color-background)",
  },

  // 收貨地址
  {
    path: "/addresses",
    name: "Addresses",
    component: () => getComponent("Addresses"),
    exact: true,
    displayName: "收货地址",
    showNavBar: true,
    containerBg: "var(--adm-color-background)",
    goBackPath: "/userCenter",
  },
  {
    path: "/addressAdd",
    name: "AddressesAdd",
    component: () => getComponent("AddressAddEdit"),
    exact: true,
    displayName: "添加收货地址",
    showNavBar: true,
    containerBg: "var(--adm-color-background)",
  },
  {
    path: "/addressEdit",
    name: "AddressesEdit",
    component: () => getComponent("AddressAddEdit"),
    exact: true,
    displayName: "编辑收货地址",
    showNavBar: true,
  },

  // 推廣下級
  {
    path: "/promoteSubAgent",
    name: "PromoteSubAgent",
    component: () => getComponent("SubAgent"),
    exact: true,
    displayName: "推广下级",
    showNavBar: true,
  },

  // 設置
  {
    path: "/setting",
    name: "Setting",
    component: () => getComponent("Setting"),
    exact: true,
    displayName: "设置",
    showNavBar: true,
  },

  // 設置資金密碼
  {
    path: "/fundPassword",
    name: "FundPassword",
    component: () => getComponent("FundPassword"),
    exact: true,
    displayName: "资金密码",
    showNavBar: true,
    containerBg: "var(--adm-color-background)",
  },

  // 進貨訂單查詢 (已處理+已認領)
  {
    path: "/transferSearchList",
    name: "TransferSearchList",
    component: () => getComponent("TransferSearchList"),
    exact: true,
    displayName: "进货订单查询",
    showNavBar: true,
  },
  {
    path: "/transferSearchDetail",
    name: "TransferSearchDetail",
    component: () => getComponent("TransferSearchDetail"),
    exact: true,
    displayName: "订单详情",
    showNavBar: true,
  },

  // 出貨訂單 (代收/全部訂單)
  {
    path: "/orderSearchList",
    name: "OrderSearchList",
    component: () => getComponent("OrderSearchList"),
    exact: true,
    displayName: "出货订单查询",
    showNavBar: true,
  },
  {
    path: "/orderSearchDetail",
    name: "OrderSearchDetail",
    component: () => getComponent("OrderSearchDetail"),
    exact: true,
    displayName: "订单详情",
    showNavBar: true,
  },

  // 出售USDT
  {
    path: "/sellUsdt",
    name: "SellUsdt",
    component: () => getComponent("SellUsdt"),
    exact: true,
    displayName: "出售USDT",
    showNavBar: true,
  },

  // 出售USDT 訂單記錄
  {
    path: "/sellUsdtOrderList",
    name: "SellUsdtOrderList",
    component: () => getComponent("SellUsdtOrderList"),
    exact: true,
    displayName: "订单记录",
    showNavBar: true,
  },

  // 出售USDT 訂單記錄詳情
  {
    path: "/sellUsdtOrderDetail",
    name: "SellUsdtOrderDetail",
    component: () => getComponent("SellUsdtOrderDetail"),
    exact: true,
    displayName: "订单详情",
    showNavBar: true,
  },

  // 二維碼轉帳
  {
    path: "/qrcodeTransfer",
    name: "QrcodeTransfer",
    component: () => getComponent("QrcodeTransfer"),
    exact: true,
    displayName: "转账地址",
    showNavBar: true,
  },
];

export default routes;
