import { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

function useListM({ action = () => {}, defaultSearch = {} }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const currentPage = useRef(0);
  const isSearch = useRef(false);
  const searchData = useRef({});
  const isRefresh = useRef(false);

  const handleGetList = useCallback(
    async (params = {}) => {
      setLoading(true);
      const { payload } = await dispatch(
        action({ per_page: 10, ...defaultSearch, ...params }),
      );
      const { data, status } = payload;
      setLoading(false);

      if (status !== 200) return { error: true };
      return { resData: data };
    },
    [action, dispatch, defaultSearch],
  );

  const loadMore = useCallback(async () => {
    console.log("loadMore");
    console.log("isRefresh", isRefresh.current);
    console.log("searchData", searchData.current);

    let params = {};
    params = { page: currentPage.current + 1, ...searchData.current };

    const { resData, error } = await handleGetList(params);

    if (error) {
      setHasMore(false);
      return;
    }

    const { data, meta } = resData;

    if (isRefresh.current || isSearch.current) {
      isRefresh.current = false;
      isSearch.current = false;
      setList(data);
    } else {
      const _data = [...list, ...data];
      setList(_data);
    }

    setHasMore(meta.page < meta.pages);
    currentPage.current = meta.page;
  }, [currentPage, handleGetList, list]);

  const onRefresh = useCallback(
    callback => {
      console.log("onRefresh");
      isRefresh.current = true;
      currentPage.current = 0;
      callback?.(searchData.current);
      loadMore();
    },
    [loadMore],
  );

  const onSearch = useCallback(
    params => {
      console.log("onSearch");
      isSearch.current = true;
      searchData.current = { ...params };
      currentPage.current = 0;
      loadMore();
    },
    [loadMore],
  );

  // defaultSearch 改變觸發 onRefresh
  const defaultSearchChange = useRef(null);
  useEffect(() => {
    if (!defaultSearchChange.current) {
      defaultSearchChange.current = defaultSearch;
    } else {
      if (
        JSON.stringify(defaultSearchChange.current) !==
        JSON.stringify(defaultSearch)
      ) {
        defaultSearchChange.current = defaultSearch;
        searchData.current = {};
        onRefresh();
      }
    }
  }, [defaultSearch, onRefresh]);

  return {
    loading,
    hasMore,
    list,
    loadMore,
    onRefresh,
    onSearch,
    handleGetList,
  };
}

export default useListM;
