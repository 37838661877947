import React from "react";
import { useLocation } from "react-router-dom";
import { Card, Ellipsis, Image, SpinLoading } from "antd-mobile";

import { getOrder, selectOrder } from "@/store/slice/order";
import useDetail from "@/hook/useDetail";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";

import { ListItem } from "@/components/mobile/List";
import { dateFormat, priceFormat } from "@/utils/format";
import { OrderStatus, isSuccessLang } from "@/utils/enum";

const StatusDisplay = ({ icon, text, color }) => {
  return (
    <div className="text-center" style={{ color }}>
      {icon({ style: { fontSize: "3em" } })}
      <div className="text-14">{text}</div>
    </div>
  );
};

const orderStatusHandleMap = {
  3: {
    color: "var(--adm-color-primary)",
    text: "未付款",
    icon: p => <CloseCircleFilled {...p} />,
  },
  6: {
    color: "var(--grey-1)",
    text: "已超时",
    icon: p => <CloseCircleFilled {...p} />,
  },
  7: {
    color: "var(--grey-1)",
    text: "已取消",
    icon: p => <CloseCircleFilled {...p} />,
  },
  13: {
    color: "var(--adm-color-success)",
    text: "通知成功",
    icon: p => <CheckCircleFilled {...p} />,
  },
  15: {
    color: "var(--adm-color-success)",
    text: "已完成",
    icon: p => <CheckCircleFilled {...p} />,
  },
};

function OrderSearchDetail() {
  const { state } = useLocation();

  const { currentRow, loading } = useDetail(
    { action: getOrder, id: state.id },
    selectOrder,
  );

  if (loading || !currentRow.id)
    return (
      <SpinLoading
        color="primary"
        style={{ margin: "auto", "--size": "48px" }}
      />
    );

  return (
    <div className="box-wrap mt-1 mb-4">
      <Card>
        <div className="mt-1 mb-1">
          {orderStatusHandleMap[currentRow.status] ? (
            StatusDisplay({ ...orderStatusHandleMap[currentRow.status] })
          ) : (
            <span className="text-14">{OrderStatus[currentRow.status]}</span>
          )}
        </div>
        <div className="flex mb-1">
          <div className="mr-1">
            <Image
              src={currentRow.extra?.product?.url || ""}
              width={66}
              height={66}
              fit="fill"
              style={{ borderRadius: 4 }}
            />
          </div>
          <div style={{ flex: "1 1 auto", textAlign: "end" }}>
            <Ellipsis
              className="text-14"
              direction="end"
              rows={2}
              content={currentRow.extra?.product?.name || "商品名称"}
            />
            <span className="text-16 text-orange text-w-600">
              {priceFormat({
                val: currentRow.amount,
                currency: currentRow.currency,
              })}
            </span>
          </div>
        </div>
        <ListItem label="订单号" value={currentRow.order_no} showCopy={true} />
        <ListItem label="付款人" value={currentRow.payer_name} />
        <ListItem label="付款卡号" value={currentRow.payer_card} />
        <ListItem label="通道名称" value={currentRow.gateway_name} />
        <ListItem label="成功时间" value={dateFormat(currentRow.paid_at)} />
        <ListItem
          label="支付金额"
          value={priceFormat({
            val: currentRow.amount,
            currency: currentRow.currency,
          })}
          style={{ color: "var(--adm-color-primary)" }}
        />
        <ListItem
          label="实际付款金额"
          value={priceFormat({
            val: currentRow.amount_paid,
            currency: currentRow.currency,
          })}
          style={{ color: "var(--adm-color-primary)" }}
        />
        <ListItem
          label="是否成功"
          value={
            [4, 5, 6, 7, 9, 10, 13, 14, 15].includes(currentRow.status)
              ? isSuccessLang(currentRow.succeeded)
              : "-"
          }
        />
        <ListItem
          label="创建日期"
          value={dateFormat(currentRow.created)}
          showBorderBottom={false}
        />
      </Card>
    </div>
  );
}

export default OrderSearchDetail;
