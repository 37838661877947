import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Space, Button, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { IS_MOBILE } from "@/const";
import {
  selectTransfer,
  getTransfersSum,
  succeededTransfer,
  failedTransfer,
  claimTransfer,
  // cancelClaimTransfer,
  exportOrder,
} from "@/store/slice/transfer";
import { selectAuth } from "@/store/slice/auth";
import { useList, useExportExcel, useSearchCache } from "@/utils/hook";
import {
  priceFormat,
  rangeLimitDays,
  objFormateToSearchFields,
  searchFieldsInitialValuesFormat,
} from "@/utils/format";
import { IsBoolEnum } from "@/utils/enum";
import SearchBar from "@/components/SearchBar";
import SumTable from "@/components/SumTable";
import JsonModal from "@/components/JsonModal";
import Detail from "@/components/Detail";
import { NormalTable } from "@/components/factory/TableFactory";
import EditableConfirm from "@/components/EditableConfirm";
import {
  columns as ListColumns,
  accountColumns,
  detailColumnsCard,
  detailColumnsUSDT,
  sumColumns,
  exportColumnsRenderMap,
} from "./Columns";

const TYPE_ENUMS = {
  failed: "出款失败",
  claim: "认领",
  // cancelClaim: "退回认领",
};

const Transfer = ({ action, params }) => {
  const searchFields = useMemo(
    () => ({
      ...(IS_MOBILE && {
        created__btw: {
          type: "rangeDate",
          label: "创建时间",
          initialValue: [moment().startOf("days"), moment().endOf("day")],
        },
      }),
      order_no__in: { type: "string", label: "开发者订单号" },
      succeeded: {
        type: "select",
        label: "是否成功",
        options: IsBoolEnum,
        isBool: true,
      },
      ...(IS_MOBILE || {
        created__btw: {
          type: "rangeDate",
          label: "创建时间",
          initialValue: [moment().startOf("days"), moment().endOf("day")],
        },
      }),
      ...objFormateToSearchFields(params),
    }),
    [params],
  );
  const fieldsInitialValues = useMemo(() => {
    return searchFieldsInitialValuesFormat(searchFields);
  }, [searchFields]);
  const { handleGetPageSearchCache } = useSearchCache();

  const { user } = useSelector(selectAuth);
  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportOrder,
  });
  const handleExportClick = async formModel => {
    let search = { ...formModel, ...(params && params) };
    handleExport(search);
  };

  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
  } = useList(action, selectTransfer, { ...fieldsInitialValues }, true);
  const dispatch = useDispatch();
  const handleGetTransfersSum = (search = {}) => {
    dispatch(
      getTransfersSum({
        ...fieldsInitialValues,
        ...(IS_MOBILE || handleGetPageSearchCache()),
        ...search,
      }),
    );
  };
  useEffect(() => {
    handleGetTransfersSum();
    // eslint-disable-next-line
  }, []);
  const handleCustomSearch = formModel => {
    if (!formModel.created__btw) {
      message.warning("「创建时间」必填");
      return;
    }
    if (!rangeLimitDays(62, formModel.created__btw)) {
      message.warning("「创建时间」不得超過62天");
      return;
    }
    handleSearch(formModel);
    handleGetTransfersSum(formModel);
  };
  const [jsonVisible, setJsonVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const handleJsonClick = record => {
    setCurrentRow(record);
    setJsonVisible(true);
  };

  const [detailVisible, setDetailVisible] = useState(false);
  const handleDetailClick = record => {
    setCurrentRow(record);
    setDetailVisible(true);
  };

  const [editLoading, setEditLoading] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [editMode, setEditMode] = useState("approve");
  const fields =
    editMode === "failed"
      ? [
          {
            label: "错误码",
            name: "failure_code",
            inputType: "string",
          },
          {
            label: "失败原因",
            name: "failure_msg",
            inputType: "string",
            required: true,
          },
        ]
      : editMode === "claim"
      ? [
          {
            label: "备注",
            name: "note",
            inputType: "string",
          },
          {
            label: "出款人姓名",
            name: "transferName",
            inputType: "string",
            required: true,
          },
          {
            label: "打款卡尾号四位",
            name: "transferUsername",
            inputType: "string",
            required: true,
          },
        ]
      : [
          {
            label: "备注",
            name: "note",
            inputType: "string",
          },
        ];
  const handleEditClick = (record, mode) => {
    setCurrentRow(record);
    setEditMode(mode);
    setEditVisible(true);
  };
  const handleEdit = async formModel => {
    setEditLoading(true);
    const action =
      editMode === "failed"
        ? failedTransfer // failed: "出款失败"
        : claimTransfer; // claim: "认领"
    let tempData = { ...formModel };
    if (editMode === "claim") {
      tempData.note = `出款人: ${formModel.transferName}\n尾号四位: ${formModel.transferUsername}\n备注: ${formModel.note}`;
      delete tempData.transferName;
      delete tempData.transferUsername;
    }
    const { status } = await action({
      id: currentRow.id,
      formModel: tempData,
    });
    setEditLoading(false);
    setEditVisible(false);
    if (status !== 200) return;
    message.success(`已${TYPE_ENUMS[editMode]}`);
    await handleGetList(params);
  };

  // const handleCancelClaimTransferClick = id => {
  //   Modal.confirm({
  //     title: "是否退回认领",
  //     icon: <ExclamationCircleOutlined />,
  //     content: `即将退回订单 ${id}，是否继续？`,
  //     okText: "确认",
  //     cancelText: "取消",
  //     onOk: close => handleCancelClaimTransfer(close, id),
  //   });
  // };
  // const handleCancelClaimTransfer = async (close, id) => {
  //   const { status } = await cancelClaimTransfer({ id });
  //   close();
  //   if (status !== 200) return;
  //   handleGetList(params);
  // };
  const handleSucceededClick = async record => {
    Modal.confirm({
      title: "是否出款成功",
      icon: <ExclamationCircleOutlined />,
      content: `即将出款成功 ${priceFormat({
        val: record.amount,
        currency: record.currency,
      })}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleSucceeded(close, record),
    });
  };
  const handleSucceeded = async (close, record) => {
    const { status } = await succeededTransfer({
      id: record.id,
      formModel: { paid_id: user.id },
    });
    close();
    if (status !== 200) return;
    handleGetList(params);
  };
  const columns = [
    ...ListColumns,
    ...(params.status__in !== "8,20" ? accountColumns : []),
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          {!IS_MOBILE && params.status === 19 && (
            <Button
              size="small"
              onClick={() => handleJsonClick(record)}
              type="link"
              className="p-0"
            >
              json
            </Button>
          )}
          <Button
            size="small"
            onClick={() => handleDetailClick(record)}
            type="link"
            className="p-0"
          >
            查看
          </Button>
          {params.status === 19 && (
            <>
              <Button
                size="small"
                type="text"
                className="p-0"
                onClick={() => handleSucceededClick(record)}
              >
                出款成功
              </Button>
              {/* <Button
                size="small"
                type="text"
                className="p-0"
                onClick={() => handleCancelClaimTransferClick(record.id)}
              >
                退回认领
              </Button> */}
            </>
          )}
          {/* {params.status === 19 && (
            <Button
              size="small"
              type="text"
              className="p-0"
              onClick={() => handleEditClick(record, "failed")}
            >
              {TYPE_ENUMS.failed}
            </Button>
          )} */}
          {params.status__in === "8,20" && (
            <Button
              size="small"
              type="text"
              className="p-0"
              onClick={() => handleEditClick(record, "claim")}
            >
              {TYPE_ENUMS.claim}
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "order_no",
    "bank_name",
    "account",
    "name",
    "amount",
    "amount_paid",
    "note",
    "succeeded_at",
    "created",
    "succeeded",
    "action",
  ];

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchBar
        fields={searchFields}
        handleSubmit={handleCustomSearch}
        showExportBtn={params?.status__in === "9,10,15,16"}
        handleExport={handleExportClick}
        exportLoading={exportLoading}
      />
      <SumTable data={sum} labels={sumColumns} />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        setSelectedColumns={handleSelectedColumns}
        onShowSizeChange={handleChangePage}
      />
      <JsonModal
        width={650}
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
        loading={false}
      />
      <Detail
        title="代付明細"
        visible={detailVisible}
        data={currentRow}
        onCancel={() => setDetailVisible(false)}
        loading={false}
        columns={
          currentRow.currency === 0 ? detailColumnsCard : detailColumnsUSDT
        }
      />
      <EditableConfirm
        title={TYPE_ENUMS[editMode]}
        fields={fields}
        visible={editVisible}
        data={currentRow}
        onCancel={() => setEditVisible(false)}
        loading={editLoading}
        onOk={handleEdit}
      />
    </Space>
  );
};
export default Transfer;
