import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { Card, Divider, InfiniteScroll, PullToRefresh } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";

import { getUsersV2, getUsersSumV2, selectUser } from "@/store/slice/user";

import useLoadMoreList from "@/hook/useLoadMoreList";
import { priceFormat } from "@/utils/format";
import { todayRangeFormat } from "@/utils/dateTime";

import LightTable from "@/components/mobile/LightTable";
import SearchBar from "@/components/mobile/SearchBar";

const sumColumns = [
  {
    key: "total_succeeded_amount",
    label: "总流水",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    key: "total_order_profit",
    label: "下级总收益",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    key: "my_profit",
    label: "我的收益",
    render: val => priceFormat({ val, currency: 0 }),
  },
];

const userColumns = [
  {
    key: "total_succeeded_amount",
    label: "流水",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    key: "total_succeeded_times",
    label: "交易笔数",
  },
  {
    key: "total_order_profit",
    label: "收益",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    key: "agent_rate",
    label: "费率",
    render: val => <>{val}%</>,
  },
];

const searchFields = {
  created__btw: {
    type: "rangeDate",
    label: "时间",
  },
  name__k: { type: "string", label: "代理名称" },
};

const defaultSearch = { created__btw: todayRangeFormat() };

function SubAgent() {
  const { loading, hasMore, list, loadMore, onSearch, onRefresh } =
    useLoadMoreList({
      action: getUsersV2,
      defaultSearch,
    });

  const dispatch = useDispatch();
  const [sumLoading, setSumLoading] = useState(false);
  const { sum } = useSelector(selectUser);
  const handleGetTransfersSum = async params => {
    setSumLoading(true);
    await dispatch(getUsersSumV2({ ...params }));
    setSumLoading(false);
  };
  useEffect(() => {
    handleGetTransfersSum(defaultSearch);
    // eslint-disable-next-line
  }, []);

  const handleSearch = val => {
    handleGetTransfersSum({ ...defaultSearch, ...val });
    onSearch(val);
  };

  const _onRefresh = () => {
    onRefresh(search => {
      handleGetTransfersSum({ ...defaultSearch, ...search });
    });
  };

  return (
    <div>
      <SearchBar fields={searchFields} onSubmit={handleSearch} />
      <PullToRefresh onRefresh={_onRefresh}>
        <Spin spinning={loading}>
          <Card>
            <LightTable
              columns={sumColumns}
              source={sum}
              size="small"
              sumLoading={sumLoading}
            />
          </Card>
          <div className="box-wrap">
            {list.map(item => (
              <Card key={item.id} className="mt-1">
                <div className="flex justify-between text-12 ">
                  <span className="text-grey-3">下级代理 {item.name}</span>
                  {/* <span className="text-grey-2">{dateFormat(item.created)}</span> */}
                </div>
                <Divider style={{ margin: "0.6em 0" }} />
                <LightTable
                  source={item}
                  columns={userColumns}
                  size="small"
                  gridColumns={4}
                />
              </Card>
            ))}
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
          </div>
        </Spin>
      </PullToRefresh>
    </div>
  );
}

export default SubAgent;
