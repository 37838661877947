/**
 * 財務中心
 */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PullToRefresh, Card, Grid, Image } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { getAgentDailySum } from "@/store/slice/agentDaily";
import LightTable from "@/components/mobile/LightTable";
import { todayRangeFormat, dayAgoRangeFormat } from "@/utils/dateTime";
import { priceFormat } from "@/utils/format";

const todaySumColumn = [
  { key: "total_times", label: "今日售出" },
  {
    key: "total_succeeded_times",
    label: "今日交易数",
  },
  {
    key: "total_order_profit",
    label: "今日收益",
    render: val => priceFormat({ val, currency: 0 }),
  },
];

const yesterdaySumColumn = [
  { key: "total_times", label: "昨日售出" },
  { key: "total_succeeded_times", label: "昨日笔数" },
  {
    key: "total_succeeded_amount",
    label: "昨日收益",
    render: val => priceFormat({ val, currency: 0 }),
  },
];

const menu = [
  {
    key: "/transferSearchList",
    label: "进货订单查询",
    img: require(`@/assets/mobile/financialCenter/menu-1.png`).default,
  },
  {
    key: "/orderSearchList",
    label: "出货订单查询",
    img: require(`@/assets/mobile/financialCenter/menu-2.png`).default,
  },
  {
    key: "/financialCenter/agentDaily",
    label: "我的报表",
    img: require(`@/assets/mobile/financialCenter/menu-3.png`).default,
  },
  {
    key: "/financialCenter/subAgentDaily",
    label: "子代理报表",
    img: require(`@/assets/mobile/financialCenter/menu-4.png`).default,
  },
];

function FinancialCenter() {
  const history = useHistory();
  const dispatch = useDispatch();
  const goPath = path => {
    history.push(path);
  };

  const [todaySum, setTodaySum] = useState({});
  const [yesterdaySum, setYesterdaySum] = useState({});

  const handleAgentDailySum = async () => {
    Promise.all([
      dispatch(getAgentDailySum({ created__btw: todayRangeFormat() })),
      dispatch(getAgentDailySum({ created__btw: dayAgoRangeFormat() })),
    ]).then(([res1, res2]) => {
      if (res1.payload.status === 200) {
        setTodaySum(res1.payload.data);
      }
      if (res2.payload.status === 200) {
        setYesterdaySum(res2.payload.data);
      }
    });
  };

  useEffect(() => {
    handleAgentDailySum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PullToRefresh onRefresh={handleAgentDailySum}>
    <div className="financial-center">
      <Grid columns={1} gap={16}>
        <Grid.Item span={1}>
          <Card>
            <LightTable
              columns={todaySumColumn}
              source={todaySum}
              size="small"
            />
            <LightTable
              columns={yesterdaySumColumn}
              source={yesterdaySum}
              size="small"
            />
          </Card>
        </Grid.Item>

        <Grid.Item className="box-wrap">
          <Grid columns={2} gap={16}>
            {menu.map(item => (
              <Grid.Item key={item.key} span={1}>
                <Card onClick={() => goPath(item.key)}>
                  <div className="flex-center mt-1 mb-1">
                    <Image src={item.img} width={64} className="mb-1" />
                    <span className="text-14 text-grey-3">{item.label}</span>
                  </div>
                </Card>
              </Grid.Item>
            ))}
          </Grid>
        </Grid.Item>
      </Grid>
    </div>
    </PullToRefresh>
  );
}

export default FinancialCenter;
