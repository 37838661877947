import React, { useEffect, useMemo, useState } from "react";
import { Button, Divider, ActionSheet, Space, Toast } from "antd-mobile";
import { ExclamationCircleFilled, EditFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getAddresses,
  deleteAddress,
  defaultAddress,
  selectAddresses,
} from "@/store/slice/addresses";
import useDetail from "@/hook/useDetail";

import FullLoading from "@/components/mobile/FullLoading";
import ModalConfirm from "@/components/mobile/ModalConfirm";

function Addresses() {
  const dispatch = useDispatch();
  const history = useHistory();
  const goPath = (pathname, state) => {
    history.push({ pathname, state });
  };

  const hasPassword = true;

  const { list } = useSelector(selectAddresses);
  const [loading, setLoading] = useState(false);
  const [currentRow, setCurrentRow] = useState({});

  const listSort = useMemo(() => {
    const _l = [...list];
    _l.sort(({ is_default }) => (is_default ? -1 : 1));
    return _l;
  }, [list]);

  const { loading: activeLoading, handleEdit } = useDetail(
    { id: null },
    selectAddresses,
  );

  const [deleteID, setDeleteID] = useState(null);
  const handleDeleteAddress = () => {
    handleEdit({
      action: deleteAddress,
      onSuccess: () => {
        setDeleteID(null);
        setCurrentRow({});
        handleGetList();
      },
      id: deleteID,
    });
  };

  const handleSetDefault = (id, is_default) => {
    if (is_default) {
      Toast.show({ icon: "success", content: "操作成功" });
      return;
    }
    handleEdit({
      action: defaultAddress,
      onSuccess: () => handleGetList(),
      id,
    });
  };

  const handleGetList = async () => {
    setLoading(true);
    await dispatch(getAddresses());
    setLoading(false);
  };

  useEffect(() => {
    handleGetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = [
    {
      text: "编辑地址",
      key: "edit",
      onClick: () => goPath("/addressEdit", currentRow),
    },
    {
      text: "删除地址",
      key: "delete",
      onClick: () => setDeleteID(currentRow.id),
    },
  ];

  return (
    <div className="box-wrap">
      <div className="flex justify-between align-center mt-1">
        <p className="mb-0">常用收货地址</p>
      </div>
      <Divider style={{ marginTop: "8px" }} />

      <FullLoading loading={loading || activeLoading}>
        {listSort.map(item => (
          <div key={item.id}>
            <div className="flex">
              <div style={{ flex: "1 1 auto" }}>
                <h4 className="text-14">{item.receiver_name}</h4>
                <p className="text-12 text-grey-2 mb-0">
                  {item.receiver_mobile}
                </p>
                <p className="text-12 text-grey-2 mb-0">{`${item.province} ${item.city} ${item.district} ${item.address}`}</p>
              </div>

              <Space direction="vertical" align="end" justify="between">
                <Button
                  size="mini"
                  onClick={() => handleSetDefault(item.id, item.is_default)}
                  style={{
                    "--text-color": "white",
                    "--background-color": item.is_default
                      ? "rgb(103 189 78)"
                      : "rgb(255 171 171)",
                  }}
                >
                  <span style={{ whiteSpace: "nowrap" }}>
                    {item.is_default ? "默认" : "设为默认"}
                  </span>
                </Button>

                <Button
                  fill="none"
                  style={{ padding: "0" }}
                  onClick={() => setCurrentRow(item)}
                >
                  <EditFilled
                    style={{
                      color: "var(--adm-color-info-1)",
                      fontSize: "1.4em",
                    }}
                  />
                </Button>
              </Space>
            </div>
            <Divider />
          </div>
        ))}
      </FullLoading>
      <div className="flex-center">
        <Button color="primary" onClick={() => goPath("/addressAdd")}>
          + 添加收货地址
        </Button>
      </div>

      {/* 未設定資金密碼 */}
      {hasPassword || (
        <div className="flex-center mt-1">
          <ExclamationCircleFilled
            style={{
              fontSize: "4em",
              color: "var(--adm-color-primary)",
              marginBottom: "0.2em",
            }}
          />
          <p className="text-center">
            未设置资金密码!
            <br />
            为了您的账户安全，请设置资金密码！
          </p>
          <Button color="primary">去设置</Button>
        </div>
      )}

      <ModalConfirm
        visible={Boolean(deleteID)}
        onConfirm={handleDeleteAddress}
        onClose={() => setDeleteID(null)}
        loading={activeLoading}
        text="确定删除该地址?"
      />

      <ActionSheet
        visible={currentRow?.id}
        actions={actions}
        cancelText="取消"
        onClose={() => setCurrentRow({})}
      />
    </div>
  );
}

export default Addresses;
