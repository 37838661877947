import { useState } from "react";

function useFetch({ action }) {
  const [loading, setLoading] = useState(false);
  const handelFetch = async params => {
    setLoading(true);
    const res = await action(params);
    setLoading(false);
    return res;
  };

  return { loading, handelFetch };
}

export default useFetch;
