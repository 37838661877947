import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchBar, Input, CascadePicker, Picker, Button } from "antd-mobile";
import { RightOutlined } from "@ant-design/icons";

import { getBanks, selectBank } from "@/store/slice/bank";

// import Banks from "@/utils/enum/bank";
import Prov from "@/utils/enum/prov";
import Area from "@/utils/enum/area";

const FormItemSelectorPicker = ({
  columns = [],
  options = [],
  value = "",
  placeholder = "请输入",
  showSearch = false,
  type = "",
  onChange = () => {},
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const [displayVal, setDisplayVal] = useState(value);
  const [filterVal, setFilterVal] = useState("");

  const _columns = useMemo(() => {
    return columns.filter(({ label }) => label.indexOf(filterVal) >= 0);
  }, [filterVal, columns]);

  const onConfirm = (value, extend) => {
    onChange(value);
    const _displayVal = extend.items.map(item => item.label);
    setDisplayVal(_displayVal.join(" "));
  };

  const CompPicker = useMemo(() => {
    if (type === "cascade") return CascadePicker;
    return Picker;
  }, [type]);

  return (
    <>
      <div className="flex" onClick={() => setVisible(true)}>
        <Input
          value={displayVal}
          placeholder={placeholder}
          style={{ pointerEvents: "none" }}
        />
        <RightOutlined style={{ color: "var(--grey-2)" }} />
      </div>
      <CompPicker
        value={value}
        visible={visible}
        {...(type === "cascade" ? { options } : { columns: [_columns] })}
        cancelText={
          showSearch
            ? null
            : props.cancelText || (
                <Button size="small" fill="none">
                  取消
                </Button>
              )
        }
        confirmText={
          showSearch ? (
            <Button size="small" color="primary" fill="outline">
              确定
            </Button>
          ) : (
            props.confirmText || (
              <Button size="small" color="primary" fill="none">
                确定
              </Button>
            )
          )
        }
        title={
          showSearch ? <SearchBar onChange={setFilterVal} /> : props?.title
        }
        onClose={() => setVisible(false)}
        onConfirm={onConfirm}
      />
    </>
  );
};

/**
 * 選擇 開戶行
 */
const bankColumns = (list = [], valueType = "key") => {
  // const _d = Object.keys(Banks).map(key => {
  //   const label = `${key} ${Banks[key]}`;
  //   const value =
  //     valueType === "key" ? key : valueType === "label" ? label : Banks[key];

  //   return { label, value };
  // });
  // return _d;
  const _d = list.map(item => {
    const label = `${item.code} ${item.name}`;
    const value =
      valueType === "code"
        ? item.code
        : valueType === "label"
        ? label
        : item.code;

    return { label, value };
  });
  return _d;
};

export const PickerBank = ({ valueType = "code", ...props }) => {
  const { list } = useSelector(selectBank);
  const dispatch = useDispatch();

  const _bankColumns = useMemo(
    () => bankColumns(list, valueType),
    [list, valueType],
  );

  const handleGetBank = async () => {
    await dispatch(getBanks({ per_page: 999 }));
  };
  useEffect(() => {
    list.length || handleGetBank();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormItemSelectorPicker
      columns={_bankColumns}
      showSearch={true}
      placeholder="请输入开户行"
      {...props}
    />
  );
};

/**
 * 選擇 省市区
 */
const provCityColumn = () => {
  const _d = Prov.map(pp => {
    let children = [];
    if (Area[pp]) {
      // let areaChildren = [];
      children = Object.keys(Area[pp].items).map(aa => {
        // areaChildren = Object.keys(Area[pp].items[aa].items).map(item => ({
        //   label: item,
        //   value: item,
        // }));
        return {
          label: aa,
          value: aa,
          // children: areaChildren
        };
      });
    }
    return { label: pp, value: pp, children };
  });
  return _d;
};
const _provCityColumn = provCityColumn();

export const PickerArea = props => {
  return (
    <FormItemSelectorPicker
      type="cascade"
      options={_provCityColumn}
      placeholder="请输入省市"
      {...props}
    />
  );
};

export default FormItemSelectorPicker;
