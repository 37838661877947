import React from "react";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import zh_CN from "antd/lib/locale/zh_CN";
import MainRoute from "@/views/MainRoute";
import store from "@/store";
import "@/less/mobile/main.less";
import { interceptor } from "@/utils/request";
import MobileGlobalLayout from "@/MobileGlobalLayout";

interceptor(store);
function MobileApp() {
  return (
    <ConfigProvider locale={zh_CN}>
      <Provider store={store}>
        <MainRoute>
          <MobileGlobalLayout />
        </MainRoute>
      </Provider>
    </ConfigProvider>
  );
}

export default MobileApp;
