import { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "antd-mobile";

const useDetail = ({ action, id } = {}, selector, showToast = true) => {
  const dispatch = useDispatch();
  const { currentRow } = useSelector(selector);
  const [loading, setLoading] = useState(false);
  const handleGetDetail = useCallback(async () => {
    setLoading(true);
    await dispatch(action(id));
    setLoading(false);
  }, [dispatch, action, id]);

  const handleAdd = async ({ action: addAction, onSuccess, ...params }) => {
    setLoading(true);
    const { status, data } = await addAction(params);
    if (status === 200) {
      showToast &&
        Toast.show({
          icon: "success",
          content: "操作成功",
        });
      onSuccess && onSuccess(data);
    }
    setLoading(false);
    return { status, data };
  };

  const handleEdit = async ({
    action: editAction,
    id: editId,
    onSuccess,
    ...params
  }) => {
    setLoading(true);
    const { status, data } = await editAction({
      id: editId,
      formModel: params,
    });
    if (status === 200) {
      showToast &&
        Toast.show({
          icon: "success",
          content: "操作成功",
        });
      onSuccess && onSuccess(data);
    }
    setLoading(false);
    return { status, data };
  };
  useEffect(() => {
    id && handleGetDetail();
  }, [handleGetDetail, id]);
  return { currentRow, loading, setLoading, handleAdd, handleEdit };
};

export default useDetail;
