import React, { useState } from "react";
import { Card, Popover, Button } from "antd-mobile";
import { QuestionOutlined, CloseOutlined } from "@ant-design/icons";
import { priceFormat } from "@/utils/format";

const mainMenuImag = {
  deposit: require("@/assets/mobile/deposit.png").default,
  withdraw: require("@/assets/mobile/withdraw.png").default,
};

const leftMenu = [
  { path: "/transfer", img: mainMenuImag.deposit },
  { path: "/withdraw", img: mainMenuImag.withdraw },
];

const rightMenu = [
  {
    key: "balance",
    title: "库存余额",
    description: "进货后产生的库存，表示商户目前可以出货的数额。",
    value: 0,
  },
  {
    key: "profit",
    title: "我的佣金",
    description: "目前商户赚取的佣金。",
    value: 0,
  },
  {
    key: "block_amount",
    title: "进行中",
    description: "正在进行中的出货，该部分额度会暂时冻结。",
    value: 0,
  },
];

function TopMenu({ data = {}, goPath = () => {} }) {
  const [activePopKey, setActivePopKey] = useState("");

  return (
    <div className="box-wrap">
      <Card bodyClassName="top-menu">
        <div className="left-block">
          {leftMenu.map(item => (
            <div
              key={item.path}
              onClick={() => goPath(item.path)}
              style={{ marginRight: "6px", width: "100%", height: "100%" }}
            >
              <div
                className="bg-img-center"
                style={{ backgroundImage: `url(${item.img})` }}
              />
            </div>
          ))}
        </div>

        <div className="right-block">
          {rightMenu.map(item => (
            <React.Fragment key={item.key}>
              <PopoverItem
                {...item}
                value={data[item.key]}
                visible={item.key === activePopKey}
                onClick={() => setActivePopKey(item.key)}
                onClose={() => setActivePopKey("")}
              />
            </React.Fragment>
          ))}
        </div>
      </Card>
    </div>
  );
}

const PopoverItem = ({
  title = "",
  value = "0",
  description = "",
  visible = false,
  onClick = () => {},
  onClose = () => {},
}) => {
  const Content = () => {
    return (
      <div className="popover-content">
        <div className="title">
          <span className="text-16">{title}</span>
          <Button
            size="mini"
            fill="none"
            style={{ paddingRight: 0 }}
            onClick={onClose}
          >
            <CloseOutlined />
          </Button>
        </div>
        <p className="text-12">{description}</p>
      </div>
    );
  };

  return (
    <Popover visible={visible} content={<Content />} placement="bottom-start">
      <div onClick={onClick}>
        <div className="flex align-center">
          <div style={{ width: "35%", textAlign: "left" }}>
            <span className="text-12" style={{ whiteSpace: "nowrap" }}>
              {title}
            </span>
          </div>
          <div className="flex-center" style={{ marginRight: "0.4em" }}>
            <div className="question">
              <QuestionOutlined
                className="text-12"
                style={{ color: "white" }}
              />
            </div>
          </div>
          <span className="text-12 text-red">
            {priceFormat({ val: value })}
          </span>
        </div>
      </div>
    </Popover>
  );
};

export default TopMenu;
