import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getService, setServiceVisible } from "@/store/slice/service";

const withService = Component => props => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    if (loading) return;
    setLoading(true);
    await dispatch(getService());
    await dispatch(setServiceVisible(true));
    setLoading(false);
  };
  return <Component {...props} loading={loading} onClick={onClick} />;
};

export default withService;
