import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Image, Swiper } from "antd-mobile";

import { selectAgentAccts } from "@/store/slice/agentAccts";
import { selectAuth } from "@/store/slice/auth";

import NoticeBar from "@/components/mobile/NoticeBar";
import BeltSection from "./BBeltSection";
import BodyMenu from "./BodyMenu";

const logoImg = require("@/assets/mobile/logo.png").default;

const swiperItems = [
  { img: require("@/assets/mobile/home/banner1.jpg").default },
  { img: require("@/assets/mobile/home/banner2.jpg").default },
];
function Home() {
  const { user } = useSelector(selectAuth);
  const { currentRow } = useSelector(selectAgentAccts);
  const history = useHistory();
  const goPath = path => {
    history.push(path);
  };

  const defaultCurrencyInfo = useMemo(() => {
    const {
      block_amount = "0", // 進行中
      balance = "0", // 餘額
      profit = "0", // 佣金
    } = currentRow;
    return { block_amount, balance, profit };
  }, [currentRow]);

  return (
    <div className="home mb-2">
      <div className="header">
        <div className="logo-section">
          <Image src={logoImg} width={26} height={26} />
          <span className="title">博通商城</span>
        </div>
        <div className="text-14">
          欢迎你：<span className="text-red">{user.username}</span>
        </div>
      </div>

      <Swiper loop autoplay>
        {swiperItems.map((item, idx) => (
          <Swiper.Item key={idx}>
            <Image lazy src={item.img} />
          </Swiper.Item>
        ))}
      </Swiper>

      <NoticeBar />

      <BeltSection data={defaultCurrencyInfo} goPath={goPath} />

      <BodyMenu goPath={goPath} />
    </div>
  );
}

export default Home;
