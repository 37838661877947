import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd-mobile";
import withService from "@/hoc/withService";
import ModalConfirm from "./ModalConfirm";

const ExtraBtn = ({ extra, onClick }) => (
  <Button
    size="mini"
    fill="none"
    style={{ color: "var(--adm-color-info-1)", padding: 0 }}
    onClick={onClick}
  >
    {extra}
  </Button>
);

const ModalConfirmWrap = withService(
  ({ onClick, onClose, loading, ...props }) => {
    const isClick = useRef(false);

    const _onClick = () => {
      isClick.current = true;
      onClick();
    };

    useEffect(() => {
      if (isClick.current && !loading) {
        onClose();
        isClick.current = false;
      }
    }, [loading, onClose]);

    return (
      <ModalConfirm
        onConfirm={_onClick}
        loading={loading}
        onClose={onClose}
        {...props}
      />
    );
  },
);

function FormExtraLabel({
  label = "",
  extra = "",
  onClick = () => {},
  isService = false,
}) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className="flex justify-between align-center">
        <div>{label}</div>
        {isService ? (
          <ExtraBtn extra={extra} onClick={() => setVisible(true)} />
        ) : (
          <ExtraBtn extra={extra} onClick={onClick} />
        )}
      </div>
      <ModalConfirmWrap
        text="请联系客服协助找回资金密码"
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  );
}

export default FormExtraLabel;
