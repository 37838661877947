import { useCallback, useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { message } from "antd";
import ExcelJs from "exceljs";
import { getColumns } from "@/utils/format";
import { IS_MOBILE } from "@/const";

export const useList = (
  action,
  selector,
  originParams = {},
  isUseSearchCache = false,
) => {
  const dispatch = useDispatch();
  const states = useSelector(selector);
  const [searchParams, setSearchParams] = useState(null);
  const [sortParams, setSortParams] = useState(null);
  const [pageSize, setPageSize] = useState(states.meta?.pageSize);
  const [current, setCurrent] = useState(states.meta?.current);
  const [loading, setLoading] = useState(false);
  const { handleGetPageSearchCache } = useSearchCache();
  const handleGetList = useCallback(
    async (params = {}) => {
      setLoading(true);
      await dispatch(
        action({
          page: current,
          per_page: pageSize,
          ...originParams,
          ...(IS_MOBILE ? {} : isUseSearchCache && handleGetPageSearchCache()), // mobile 不使用 cacheSearch
          ...searchParams,
          ...sortParams,
          ...params,
        }),
      );
      setLoading(false);
    },
    // eslint-disable-next-line
    [dispatch, action, pageSize, current, searchParams, sortParams],
  );
  const handleSearch = formModel => {
    setSearchParams(formModel);
    setCurrent(1);
  };
  const handleChangePage = (page, pageSize) => {
    setCurrent(page);
    setPageSize(pageSize);
  };
  const handleChange = (pagination, filters, sorter) => {
    const order = sorter.order === "ascend" ? "" : "-";
    setSortParams(sorter.order ? { o: order + sorter.field } : null);
  };
  const handleAdd = async ({ action: addAction, ...params }) => {
    setLoading(true);
    const { status } = await addAction(params);
    status === 200 && message.success("新增成功！");
    handleGetList();
  };
  const handleDelete = async ({ action: deleteAction, ...params }) => {
    setLoading(true);
    const { status } = await deleteAction(params);
    status === 200 && message.success("刪除成功！");
    handleGetList();
  };

  useEffect(() => {
    handleGetList();
  }, [handleGetList]);
  return {
    res: { ...states },
    loading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
    handleAdd,
    handleDelete,
    setLoading,
  };
};

export const useDetail = ({ action, id } = {}, selector) => {
  const dispatch = useDispatch();
  const { currentRow } = useSelector(selector);
  const [loading, setLoading] = useState(false);
  const handleGetDetail = useCallback(async () => {
    setLoading(true);
    await dispatch(action(id));
    setLoading(false);
  }, [dispatch, action, id]);

  const handleEdit = async ({ action: editAction, id: editId, ...params }) => {
    setLoading(true);
    const { status, data } = await editAction({
      id: editId,
      formModel: params,
    });
    status === 200 && message.success("更新成功！");
    setLoading(false);
    return { status, data };
  };
  useEffect(() => {
    id && handleGetDetail();
  }, [handleGetDetail, id]);
  return { currentRow, loading, setLoading, handleEdit };
};

const getCols = (cols, filter) => {
  return cols.filter(i => filter.indexOf(i.dataIndex) > -1);
};

export const useColumnsSelect = ({
  columns: defaultColumns,
  defaultColumns: defaultFilter,
}) => {
  const [filter, setFilter] = useState(
    getColumns()?.length ? getColumns() : defaultFilter,
  );

  const handleSelectedColumnsChange = useCallback(({ columns }) => {
    setFilter(columns.map(i => i.dataIndex));
  }, []);

  const selectedColumns = useMemo(() => {
    return getCols(defaultColumns, filter);
  }, [defaultColumns, filter]);

  useEffect(() => {
    const storageColumns =
      JSON.parse(localStorage.getItem("columns") || "{}") || {};
    localStorage.setItem(
      "columns",
      JSON.stringify({
        ...storageColumns,
        [window.location.pathname]: filter,
      }),
    );
  }, [filter]);

  return { selectedColumns, handleSelectedColumnsChange };
};

export const useSearchCache = () => {
  const handleGetSearchCache = () =>
    JSON.parse(localStorage.getItem("searchCachee") || "{}") || {};
  const handleSetSearchCache = ({ pathname, formModel }) => {
    const storageSearchCache = handleGetSearchCache();
    localStorage.setItem(
      "searchCachee",
      JSON.stringify({
        ...storageSearchCache,
        [pathname]: formModel,
      }),
    );
  };
  const handleResetSearchCache = ({ pathname }) => {
    const storageSearchCache = handleGetSearchCache();
    delete storageSearchCache[pathname];
    localStorage.setItem("searchCachee", JSON.stringify(storageSearchCache));
  };
  const { pathname } = useLocation();
  const handleGetPageSearchCache = () => handleGetSearchCache()[pathname] || {};
  return {
    handleGetSearchCache,
    handleSetSearchCache,
    handleResetSearchCache,
    handleGetPageSearchCache,
  };
};

export const useExportExcel = ({
  exportColumnsRenderMap = {},
  exportAction = () => {},
  handleExportData = null,
}) => {
  const selectedColumns = useRef([]);

  const handleSelectedColumns = columns => {
    let tempArr = [];
    columns.forEach(el => {
      if (el.dataIndex !== "action") {
        tempArr.push({
          ...el,
          render: exportColumnsRenderMap[el.dataIndex],
        });
      }
    });
    selectedColumns.current = tempArr;
  };
  const handleColumns = useCallback(() => {
    return selectedColumns.current.map(c => ({ name: c.title }));
  }, [selectedColumns]);

  const handleRows = useCallback(
    dataList => {
      let rowList = [];
      dataList.forEach(el => {
        let row = []; // [["小明", "20", "0987654321"],["小美", "23", "0912345678"]];
        for (let i = 0; i < selectedColumns.current.length; i++) {
          let rowItem = el[selectedColumns.current[i].dataIndex];
          if (
            typeof rowItem !== "undefined" &&
            selectedColumns.current[i].render
          ) {
            rowItem = selectedColumns.current[i].render(rowItem, el);
          }
          row.push(rowItem);
        }
        rowList.push(row);
      });
      return rowList;
    },
    [selectedColumns],
  );
  const handleExportExcel = useCallback(
    ({ data }) => {
      // 建立表格
      const workbook = new ExcelJs.Workbook();
      const sheet = workbook.addWorksheet("工作表"); // 工作區域
      const columns = handleColumns();
      const rows = handleRows(data);
      sheet.addTable({
        name: "table_name", // 表格內看不到的，算是key值，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
        ref: "A1", // 從A1開始
        columns,
        rows,
      });
      workbook.xlsx.writeBuffer().then(content => {
        const link = document.createElement("a");
        const blobData = new Blob([content], {
          type: "application/vnd.ms-excel;charset=utf-8;",
        });
        link.download = "試算表.xlsx";
        link.href = URL.createObjectURL(blobData);
        link.click();
      });
    },
    [handleColumns, handleRows],
  );

  const [loading, setLoading] = useState(false);
  const handleExport = async params => {
    setLoading(true);
    const { status, data } = await exportAction(params);
    setLoading(false);
    if (status !== 200) return;
    const exportData = handleExportData ? handleExportData(data) : data;
    handleExportExcel({ data: exportData });
  };

  return { loading, handleExport, handleSelectedColumns };
};
