import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";

import { selectAuth } from "@/store/slice/auth";
import Login from "./Login";
import { connectSocket, pingTimer } from "@/utils/ws";

import history from "@/utils/history";
const MainRoute = ({ children = null }) => {
  const { user } = useSelector(selectAuth);
  useEffect(() => {
    user !== null && connectSocket();
    user !== null || clearInterval(pingTimer);
    return () => {
      pingTimer && clearInterval(pingTimer);
    };
  }, [user]);
  return (
    <Router history={history}>
      <Switch>
        <Route path="/Login">
          <Login />
        </Route>
        {children}
      </Switch>
    </Router>
  );
};
export default MainRoute;
