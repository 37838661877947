import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Typography, Button, Form, Input, Checkbox } from "antd";
import { Checkbox as CheckboxM, Button as ButtonM } from "antd-mobile";
import { IS_MOBILE } from "@/const";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login, selectAuth, getOptStatus, setUser } from "@/store/slice/auth";
import OtpModal from "@/components/OtpModal";

const logo = require("@/assets/mobile/logo.png").default;
const loginBg = require("@/assets/mobile/loginBg.jpg").default;

const { Title } = Typography;
const Login = () => {
  const { user, isOtp } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [userData, setUserData] = useState(null);
  const handleUserData = data => {
    dispatch(setUser(data));
  };

  const [otpVisible, setOtpVisible] = useState(false);
  const [otpData, setOtpData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleLogin = async () => {
    setLoading(true);
    const { status, data } = await login({ ...form.getFieldValue() });
    setLoading(false);
    if (status !== 200) return;
    setUserData(data);

    // opt 未綁定
    if (data.next) {
      setOtpVisible(true);
      setOtpData(data.next?.otp);
      return;
    }
    handleUserData(data);
  };
  useEffect(() => {
    dispatch(getOptStatus());
  });

  return (
    <Route path="/Login">
      {!user ? (
        <>
          <div
            className="login"
            style={{
              background: `url(${loginBg}) no-repeat center`,
              backgroundSize: "cover",
            }}
          >
            <div className="login--box">
              {IS_MOBILE && (
                <img src={logo} alt="logo" style={{ marginBottom: "0.6em" }} />
              )}
              {IS_MOBILE ? (
                <h2
                  style={{
                    fontSize: "var(--adm-font-size-10)",
                    marginBottom: "3em",
                  }}
                >
                  {process.env.REACT_APP_MOBILE_TITLE}
                </h2>
              ) : (
                <Title className="mb-2" level={2}>
                  {process.env.REACT_APP_MOBILE_TITLE}
                </Title>
              )}
              <Form
                form={form}
                className="login--box--form"
                size="large"
                onFinish={handleLogin}
              >
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: "請輸入用戶名" }]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="用戶名"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "請輸入密碼" }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="密碼"
                  />
                </Form.Item>
                {isOtp && (
                  <Form.Item
                    name="opt"
                    // rules={[{ required: true, message: "請輸入二步验证码" }]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="二步验证码"
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    {IS_MOBILE ? (
                      <CheckboxM style={{ "--icon-size": "20px" }}>
                        記住密碼
                      </CheckboxM>
                    ) : (
                      <Checkbox>記住密碼</Checkbox>
                    )}
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  {IS_MOBILE ? (
                    <ButtonM
                      color="primary"
                      block
                      loading={loading}
                      onClick={handleLogin}
                    >
                      登入
                    </ButtonM>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                      loading={loading}
                    >
                      登入
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </div>
          </div>
          <OtpModal
            visible={otpVisible}
            otpAuthUrl={otpData.otp_auth_url}
            secret={otpData.secret}
            handleClose={() => handleUserData(userData)}
          />
        </>
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )}
    </Route>
  );
};
export default Login;
