import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectNotices } from "@/store/slice/notices";
import { NoticeBar } from "antd-mobile";
import { SoundFilled } from "@ant-design/icons";

function NoticeBarC({ style = {} }) {
  const { list } = useSelector(selectNotices);
  const content = useMemo(() => {
    const _cc = list.map(item => item.content);
    const _ccStr = _cc.join(" ");
    return _ccStr || "尚无公告";
  }, [list]);

  return (
    <div className="box-wrap">
      <NoticeBar
        content={content}
        icon={<SoundFilled style={{ color: "var(--adm-color-primary)" }} />}
        style={{
          "--background-color": "none",
          "--text-color": "var(--adm-color-text)",
          "--border-color": "none",
          "--font-size": "var(--adm-font-size-6)",
          padding: 0,
          ...style,
        }}
      />
    </div>
  );
}

export default NoticeBarC;
