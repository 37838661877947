import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getAgentAccts = createAsyncThunk(
  "agentAccts/getList",
  async () => {
    const res = await request({
      url: `/api/agent/agentaccts`,
      method: "get",
    });
    return res;
  },
);

export const getAgentsInfo = createAsyncThunk(
  "agentAcctsInfo/getList",
  async () => {
    const res = await request({
      url: `/api/agent/agentaccts/info`,
      method: "get",
    });
    return res;
  },
);

export const slice = createSlice({
  name: "appAccts",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
    agentInfo: {},
  },
  reducers: {
    setLogs: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {
    [getAgentAccts.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.currentRow = data.data.find(el => el.currency === 0) || {};
      state.meta = metaToPagin(data.meta);
    },
    [getAgentsInfo.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.agentInfo = data;
    },
  },
});
export const { setAgentAccts } = slice.actions;
export const selectAgentAccts = state => state.agentAccts;
export default slice.reducer;
