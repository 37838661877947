import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Space, Button } from "antd";
import {
  selectAgentDaily,
  getAgentDaily,
  getAgentDailySum,
} from "@/store/slice/agentDaily";
import SearchBar from "@/components/SearchBar";
import { useList, useSearchCache } from "@/utils/hook";
import { dateFormat, priceFormat } from "@/utils/format";
import { Currency, IsBoolEnum } from "@/utils/enum";
import { NormalTable } from "@/components/factory/TableFactory";
import Tag from "@/components/Tag";
import SumTable from "@/components/SumTable";
import Detail from "@/components/Detail";
import { IS_MOBILE } from "@/const";

const searchFields = {
  created__btw: { type: "rangeDate", label: "报表时间", showTime: false },
  id__in: { type: "string", label: "ID" },
  agent_id__in: { type: "string", label: "用户ID" },
  agent_name__k: { type: "string", label: "代理名称" },
  currency: { type: "select", label: "货币类型", options: Currency },
  is_online: {
    type: "select",
    label: "是否线上渠道",
    options: IsBoolEnum,
  },
};
const AgentDaily = () => {
  const { handleGetPageSearchCache } = useSearchCache();
  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getAgentDaily, selectAgentDaily, {}, true);

  const dispatch = useDispatch();
  const handleGetAgentDailySum = (search = {}) => {
    dispatch(
      getAgentDailySum({
        ...(IS_MOBILE || handleGetPageSearchCache()),
        ...search,
      }),
    );
  };
  useEffect(() => {
    handleGetAgentDailySum();
    // eslint-disable-next-line
  }, []);

  const handleSearchClick = params => {
    handleSearch(params);
    handleGetAgentDailySum(params);
  };

  const [currentRow, setCurrentRow] = useState({});
  const [detailVisible, setDetailVisible] = useState(false);
  const handleDetailClick = record => {
    setCurrentRow(record);
    setDetailVisible(true);
  };

  const moneyRender = () => {
    return {
      render: (val, record) =>
        priceFormat({ val: val || 0, currency: record.currency }),
      className: "text-nowrap",
      sorter: true,
    };
  };
  const columns = [
    { title: "ID", dataIndex: "id", sorter: true },
    { title: "用户ID", dataIndex: "agent_id", sorter: true },
    { title: "代理名称", dataIndex: "agent_name", sorter: true },
    {
      title: "报表时间",
      dataIndex: "created",
      className: "text-nowrap",
      render: val => dateFormat(val, "YYYY-MM-DD"),
      sorter: true,
    },
    {
      title: "是否线上渠道",
      dataIndex: "is_online",
      render: val => <Tag val={val} />,
      sorter: true,
    },
    {
      title: "货币类型",
      dataIndex: "currency",
      render: val => Currency[val] || "",
      className: "text-nowrap",
    },
    {
      title: "帐户余额",
      dataIndex: "total_balance",
      ...moneyRender(),
    },
    {
      title: "圈存余额",
      dataIndex: "total_block",
      ...moneyRender(),
    },
    {
      title: "累计订单次数",
      dataIndex: "total_times",
      sorter: true,
    },
    {
      title: "累计金额",
      dataIndex: "total_amount",
      ...moneyRender(),
    },
    {
      title: "累计赠金金额",
      dataIndex: "total_bonus",
      ...moneyRender(),
    },
    {
      title: "累计成功金额",
      dataIndex: "total_succeeded_amount",
      ...moneyRender(),
    },
    {
      title: "累计成功次数",
      dataIndex: "total_succeeded_times",
      sorter: true,
    },
    {
      title: "代收订单笔数",
      dataIndex: "total_order_times",
      sorter: true,
    },
    {
      title: "代收订单金额",
      dataIndex: "total_order_amount",
      ...moneyRender(),
    },
    {
      title: "代收订单分润",
      dataIndex: "total_order_profit",
      sorter: true,
    },
    {
      title: "代付订单笔数",
      dataIndex: "total_trans_times",
      sorter: true,
    },
    {
      title: "代付订单金额",
      dataIndex: "total_trans_amount",
      ...moneyRender(),
    },
    {
      title: "代付订单收受手续费",
      dataIndex: "total_trans_fee",
      ...moneyRender(),
    },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleDetailClick(record)}
            type="link"
            className="p-0"
          >
            查看
          </Button>
        </Space>
      ),
    },
  ];
  const sumColumns = [
    {
      title: "订单次数",
      dataIndex: "total_times",
    },
    {
      title: "订单成功次数",
      dataIndex: "total_succeeded_times",
    },
    {
      title: "订单成功金额",
      dataIndex: "total_succeeded_amount",
      render: val => priceFormat({ val, currency: 0 }),
    },
    {
      title: "总代付收入分润金额",
      dataIndex: "total_order_profit",
      render: val => priceFormat({ val, currency: 0 }),
    },
    {
      title: "总代付笔数",
      dataIndex: "total_trans_times",
      render: val => priceFormat({ val, currency: 0 }),
    },
    {
      title: "总代付金额",
      dataIndex: "total_trans_amount",
      render: val => priceFormat({ val, currency: 0 }),
    },
    {
      title: "总代付收受手续费",
      dataIndex: "total_trans_fee",
      render: val => priceFormat({ val, currency: 0 }),
    },
  ];
  const statisticsColumns = columns.filter(
    i =>
      [
        "total_times",
        "total_succeeded_times",
        "total_trans_times",
        "total_order_times",
        "total_order_profit",
      ].indexOf(i.dataIndex) !== -1,
  );
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchBar fields={searchFields} handleSubmit={handleSearchClick} />
      <SumTable data={sum} labels={sumColumns} />
      <NormalTable
        allColumns={columns}
        defaultColumns={
          IS_MOBILE
            ? ["id", "agent_id", "agent_name", "action"]
            : columns.map(i => i.dataIndex)
        }
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        statisticsColumns={statisticsColumns}
      />
      <Detail
        title="代理帐户资金纪录明細"
        visible={detailVisible}
        data={currentRow}
        onCancel={() => setDetailVisible(false)}
        loading={false}
        columns={columns.filter(i => i.dataIndex !== "action")}
      />
    </Space>
  );
};
export default AgentDaily;
