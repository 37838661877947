import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getOrderSell = createAsyncThunk(
  "usdt/exchange",
  async (params = {}) => {
    const res = await request({
      url: "/api/agent/orders/sell",
      method: "get",
      params,
    });
    return res;
  },
);

// 匯率
export const getExchange = async () => {
  const res = await request({
    url: "/api/config/exchange",
    method: "get",
  });
  return res;
};

// 出售USDT
export const postSellUsdt = async (data = {}) => {
  const res = await request({
    url: "/payments/agents/sell_usdt",
    method: "post",
    data,
  });
  return res;
};

export const slice = createSlice({
  name: "usdt",
  initialState: {
    list: [],
    meta: {},
  },
  reducers: {},
  extraReducers: {
    [getOrderSell.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
  },
});
// export const { setUsdt } = slice.actions;
export const selectUsdt = state => state.usdt;
export default slice.reducer;
