import React, { useState } from "react";
import QRCode from "qrcode.react";
import { Card, Button, ErrorBlock, Toast } from "antd-mobile";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";

import { selectOrder, approveOrderV2 } from "@/store/slice/order";
import useDetail from "@/hook/useDetail";

import CopyBottom from "@/components/mobile/CopyBottom";
import { ListItem } from "@/components/mobile/List";
import ImageUploader from "@/components/mobile/ImageUploader";
import ModalConfirm from "@/components/mobile/ModalConfirm";

import { priceFormat, cnyPriceFormat } from "@/utils/format";

function QrcodeTransfer() {
  const { state: currentRow } = useLocation();
  const history = useHistory();
  const goPath = path => history.push(path);

  const [approveSuccessModal, setApproveSuccessModal] = useState(null);
  const [files, setFiles] = useState([]);

  const { loading, handleEdit } = useDetail({ id: null }, selectOrder);
  const handleApprove = () => {
    if (!files.length) {
      Toast.show({
        icon: "fail",
        content: (
          <div className="text-center">
            請上传回单，若不上传回单，将无法进行下一步操作
          </div>
        ),
      });
      return;
    }

    const formData = new FormData();
    formData.append("id", currentRow.id);
    files.forEach(f => formData.append("images", f));
    // formData.append("amount_paid", currentRow.amount);

    handleEdit({
      action: approveOrderV2,
      id: currentRow.id,
      formData,
      onSuccess: () => {
        setApproveSuccessModal(true);
      },
    });
  };

  const handleDownloadQRcode = () => {
    const canvasImg = document.getElementById("QRCode");
    const img = new Image();
    img.src = canvasImg.toDataURL("image/png"); // 将canvas对象转换为图片的data url
    const downLink = document.createElement("a");
    downLink.href = img.src;
    downLink.download = "虚拟币钱包付款二维码地址"; // 图片name
    downLink.click();
  };

  if (!currentRow) return <ErrorBlock status="empty" />;

  const cnyPrice = cnyPriceFormat(currentRow.credential.data.cny_price);

  const formatPrice = priceFormat({
    val: currentRow.amount,
    currency: currentRow.currency,
  });

  return (
    <div className="box-wrap mt-1 mb-1">
      <Card>
        <div className="text-center">
          <span className="text-14">钱包地址（TRC20）</span>
          <div className="flex justify-center align-center">
            <p className="text-12 mb-0">
              请向以下地址转账
              <span className="text-red">
                {formatPrice}
                USDT
              </span>
            </p>
            <CopyBottom text={formatPrice} />
          </div>

          <div className="flex justify-center align-center text-grey-1 mb-1">
            <span>{currentRow.credential.url}</span>
            <CopyBottom text={currentRow.credential.url} />
          </div>

          <div>
            <QRCode id="QRCode" value={currentRow.credential.url} size={150} />
          </div>

          <Button
            fill="none"
            style={{ "--text-color": "var(--adm-color-info-1)" }}
            onClick={handleDownloadQRcode}
          >
            保存二维码
          </Button>
        </div>
      </Card>

      <div className="flex align-center" style={{ margin: "0.8em 0" }}>
        <ExclamationCircleFilled
          style={{
            fontSize: "16px",
            color: "var(--adm-color-primary)",
            marginRight: "0.4em",
          }}
        />
        <p className="text-12 text-grey-2 mb-0">
          请勿向以上地址转账任何非<span className="text-red">USDT-TRC20</span>
          协议的资产，否则资金无法到账及找回
        </p>
      </div>

      <Card>
        <h5 className="text-16 text-red">转账明细</h5>
        <ListItem
          label="转账数量"
          value={`${formatPrice} USDT`}
          showBorderBottom={false}
          showCopy={true}
          wrapProps={{ style: { marginBottom: "0.5em" } }}
        />
        <ListItem
          label="汇率"
          value={`¥ ${currentRow.credential.data.price} / USDT`}
          showBorderBottom={false}
          wrapProps={{ style: { marginBottom: "0.5em" } }}
        />
        <ListItem
          label="总价"
          value={cnyPrice}
          showBorderBottom={false}
          showCopy={true}
          wrapProps={{ style: { marginBottom: "0.5em" } }}
        />
        <ListItem
          label="订单号"
          value={currentRow.order_no}
          showBorderBottom={false}
          showCopy={true}
          wrapProps={{ style: { marginBottom: "0.5em" } }}
        />
        <ListItem
          label="收款银行卡"
          value={currentRow.recipient_cred.account}
          showBorderBottom={false}
          showCopy={true}
          wrapProps={{ style: { marginBottom: "0.5em" } }}
        />
      </Card>

      <ImageUploader
        onChange={file =>
          setFiles(pre => {
            return [...pre, file];
          })
        }
      />

      <Button color="primary" block onClick={handleApprove} loading={loading}>
        我已转账
      </Button>

      <ModalConfirm
        type="success"
        visible={approveSuccessModal}
        closeText="确认"
        confirmText="再来一笔"
        onClose={() => setApproveSuccessModal(false)}
        onConfirm={() => goPath("/sellUsdtOrderList")}
        textNode={
          <div>
            <h6 className="text-16">操作成功!</h6>
            <p className="text-12 text-grey-3">
              平台已收到您的需求，您可以继续操作取得钱包地址，向平台转账，或前往
              <span className="text-red">“订单记录”</span> 查看订单状态详情。
            </p>
          </div>
        }
      />
    </div>
  );
}

export default QrcodeTransfer;
