import { dateFormat, priceFormat } from "@/utils/format";
import { Currency, AgentAcctLogType } from "@/utils/enum";

export const listColumns = [
  { title: "ID", dataIndex: "id", sorter: true },
  { title: "代理商ID", dataIndex: "agent_id", sorter: true },
  { title: "代理商名称", dataIndex: "agent_name", sorter: true },
  {
    title: "款项类型",
    dataIndex: "type",
    render: val => AgentAcctLogType[val],
    sorter: true,
  },
  { title: "订单号", dataIndex: "order_no" },
  {
    title: "变动前金额",
    dataIndex: "b1",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "变动后金额",
    dataIndex: "b2",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "变动金额",
    dataIndex: "amount",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  {
    title: "订单金额/支付金额",
    dataIndex: "order_amount",
    render: (val, record) => priceFormat({ val, currency: record.currency }),
    sorter: true,
    className: "text-nowrap",
  },
  { title: "备注", dataIndex: "note" },
  { title: "代理商账户_账户ID", dataIndex: "agent_acct_id", sorter: true },
  { title: "代理商账户_用户ID", dataIndex: "agent_user_id", sorter: true },
  {
    title: "币种",
    dataIndex: "currency",
    render: val => Currency[val] || "",
    className: "text-nowrap",
  },
  { title: "第三方订单号", dataIndex: "trans_no" },
  { title: "操作人ID", dataIndex: "op_user_id" },
  { title: "操作人", dataIndex: "op_username" },
  { title: "客户端IP", dataIndex: "client_ip" },
  {
    title: "创建日期",
    dataIndex: "created",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
  {
    title: "更新日期",
    dataIndex: "updated",
    className: "text-nowrap",
    render: val => dateFormat(val),
    sorter: true,
  },
];

const excelPriceFormat = (val, currency) =>
  priceFormat({ val, currency, showUnit: false });

export const exportColumnsRenderMap = {
  type: val => AgentAcctLogType[val] || "",
  b1: (val, record) => excelPriceFormat(val, record.currency),
  b2: (val, record) => excelPriceFormat(val, record.currency),
  amount: (val, record) => excelPriceFormat(val, record.currency),
  order_amount: (val, record) => excelPriceFormat(val, record.currency),
  currency: val => Currency[val] || "",
  created: val => dateFormat(val),
  updated: val => dateFormat(val),
};

export const defaultColumns = [
  "id",
  "agent_id",
  "agent_name",
  "type",
  "order_no",
  "b1",
  "b2",
  "amount",
  "order_amount",
  "note",
  "created",
  "action",
];
