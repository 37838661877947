import React, { useMemo } from "react";
import {
  PullToRefresh,
  Card,
  Divider,
  Image,
  Ellipsis,
  Space,
  Button,
  InfiniteScroll,
} from "antd-mobile";
import { Statistic } from "antd";
import { HourglassOutlined, BellFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { dateFormat, priceFormat } from "@/utils/format";
import { OrderStatus } from "@/utils/enum";

import useLoadMoreList from "@/hook/useLoadMoreList";
import { getOrders } from "@/store/slice/order";

import SearchBar, {
  defaultSelectorOptions,
} from "@/components/mobile/SearchBar";

import moment from "moment";
const { Countdown } = Statistic;

const orderStatusHandleMap = {
  3: () => (
    <Button color="primary" size="small">
      确认到账
    </Button>
  ),
  6: () => (
    <Button
      size="small"
      style={{
        "--background-color": "var(--grey-1)",
        "--text-color": "white",
      }}
    >
      已超时
    </Button>
  ),
  15: () => (
    <Button
      size="small"
      style={{
        "--background-color": "var(--adm-color-info-3)",
        "--text-color": "white",
      }}
    >
      已到账
    </Button>
  ),
};

function OrderList() {
  const history = useHistory();
  const handleDetail = state => {
    history.push({ pathname: "/orderDetail", state });
  };

  const { hasMore, list, loadMore, onRefresh, onSearch } = useLoadMoreList({
    action: getOrders,
  });

  // 未處理訂單數
  const haveStatus2 = useMemo(() => {
    return list.some(ll => ll.status === 2);
  }, [list]);

  const searchFields = {
    status: {
      type: "selector",
      label: "状态",
      initialValue: null,
      options: [
        ...defaultSelectorOptions,
        { label: OrderStatus[3], value: 3 },
        { label: "已超时", value: 6 },
        { label: "已到账", value: 15 },
        // ...Object.keys(OrderStatus).map(key => ({
        //   label: OrderStatus[key],
        //   value: key,
        // })),
      ],
    },
    order_no__in: {
      type: "string",
      placeholder: "搜索单号",
    },
    notify: {
      type: "extra",
      content: () => (
        <Button
          shape="rounded"
          color={""}
          size="small"
          style={{
            padding: "3px 6px",
            ...(haveStatus2
              ? { "--background-color": "var(--adm-color-primary)" }
              : { "--background-color": "var(--grey-2)" }),
          }}
        >
          <BellFilled />
        </Button>
      ),
    },
  };

  return (
    <div className="order">
      <SearchBar fields={searchFields} onSubmit={onSearch} />
      <PullToRefresh onRefresh={onRefresh}>
        <div className="box-wrap">
          {list.map(item => (
            <Card
              key={item.id}
              className="mt-1"
              onClick={() => handleDetail(item)}
            >
              <div className="flex justify-between align-center">
                <span className="text-12 text-grey-2">
                  订单 {item.order_no}
                </span>
                <div className="flex align-center">
                  <HourglassOutlined />
                  <Countdown
                    value={moment(item.expired_at)}
                    valueStyle={{
                      color: "var(--adm-color-primary)",
                      fontSize: "12px",
                    }}
                  />
                </div>
              </div>
              <Divider className="divider" />
              <div className="flex">
                <div>
                  <Image
                    src={item.extra?.product?.url || ""}
                    width={50}
                    height={50}
                    fit="fill"
                    style={{ borderRadius: 4 }}
                  />
                </div>
                <div
                  className="flex flex-column ml-1"
                  style={{ flex: "1 1 auto" }}
                >
                  <Ellipsis
                    className="text-14"
                    direction="end"
                    rows={1}
                    content={item.extra?.product?.name || "商品名称"}
                  />
                  <div className="detail">
                    <p>付款方：{item.payer_name}</p>
                    <p>
                      收款方：{item.payer_cred.bank_code}(
                      {item.payer_cred.last_6}) - {item.payer_cred.real_name}
                    </p>
                    <p>{dateFormat(item.created)}</p>
                  </div>
                  <Space className="mt-auto justify-between align-center">
                    <span className="text-16 text-w-600 text-orange">
                      {priceFormat({
                        val: item.amount,
                        currency: item.currency,
                      })}
                    </span>

                    {orderStatusHandleMap[item.status] ? (
                      orderStatusHandleMap[item.status](item)
                    ) : (
                      <span className="text-12 text-grey-2">
                        {OrderStatus[item.status]}
                      </span>
                    )}
                  </Space>
                </div>
              </div>
            </Card>
          ))}

          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </div>
      </PullToRefresh>
    </div>
  );
}

export default OrderList;
