import React, { useCallback, useState } from "react";
import { Form, InputNumber } from "antd";
import { priceFormat } from "@/utils/format";

const CurrencyHelpTextFormItemFactory = ({
  name,
  defaultValKey,
  label,
  row,
  min = 0,
  max = null,
  ...rest
}) => {
  const [value, setValue] = useState(null);
  const helpText = useCallback(() => {
    return `数量：${priceFormat({
      val: value || row[defaultValKey] || row[name] || 0,
      currency: row.currency,
    })}`;
    // eslint-disable-next-line
  }, [row, value]);
  const handleOnChange = val => {
    setValue(val);
    return val;
  };
  return (
    <Form.Item
      {...rest}
      name={name}
      label={label}
      validateStatus="warning"
      help={helpText()}
      getValueFromEvent={handleOnChange}
    >
      <InputNumber min={min} max={max} />
    </Form.Item>
  );
};

export default CurrencyHelpTextFormItemFactory;
