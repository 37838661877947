import React, { useState, useEffect, useMemo } from "react";
import { Button, Space, Modal, message } from "antd";
import moment from "moment";
import {
  selectOrder,
  getOrders,
  getOrdersSum,
  getOrder,
  approveOrder,
  denyOrder,
  // cancelOrder,
  notifyOrder,
  exportOrder,
} from "@/store/slice/order";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Currency, IsBoolEnum } from "@/utils/enum";
import {
  useList,
  useDetail,
  useExportExcel,
  useSearchCache,
} from "@/utils/hook";
import {
  rangeLimitDays,
  searchFieldsInitialValuesFormat,
} from "@/utils/format";
import SearchBar from "@/components/SearchBar";
import { NormalTable } from "@/components/factory/TableFactory";
import Edit from "./Edit";
import JsonModal from "@/components/JsonModal";
import ListColumns, {
  sumColumns,
  exportColumnsRenderMap,
  defaultColumns,
} from "./Columns";
import { useHistory, generatePath } from "react-router-dom";
import { useDispatch } from "react-redux";
import SumTable from "@/components/SumTable";
import { IS_MOBILE } from "@/const";

const searchFields = {
  ...(IS_MOBILE && {
    created__btw: {
      type: "rangeDate",
      label: "创建日期",
      initialValue: [moment().startOf("days"), moment().endOf("day")],
    },
  }),
  id__in: { type: "string", label: "ID" },
  order_no__in: { type: "string", label: "订单号" },
  trans_no__in: { type: "string", label: "第三方订单号" },
  currency: { type: "select", label: "货币类型", options: Currency },
  userid__in: { type: "string", label: "会员ID" },
  gateway_name__k: { type: "string", label: "通道名称" },
  is_online: {
    type: "select",
    label: "是否在线订单",
    options: IsBoolEnum,
  },
  succeeded: {
    type: "select",
    label: "是否成功",
    options: IsBoolEnum,
    isBool: true,
  },
  approved: {
    type: "select",
    label: "审核通过",
    options: IsBoolEnum,
    isBool: true,
  },
  developer_id__in: { type: "string", label: "商户ID" },
  ...(IS_MOBILE || {
    created__btw: {
      type: "rangeDate",
      label: "创建日期",
      initialValue: [moment().startOf("days"), moment().endOf("day")],
    },
  }),
  paid_at__btw: { type: "rangeDate", label: "支付时间" },
};

const handleList = resList =>
  resList.map(ll => ({ ...ll, payer_card: ll.payer_cred.last_6 }));

const Order = () => {
  const fieldsInitialValues = useMemo(() => {
    return searchFieldsInitialValuesFormat(searchFields);
  }, []);
  const { handleGetPageSearchCache } = useSearchCache();

  const {
    res: { list, meta, sum },
    loading: listLoading,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
  } = useList(getOrders, selectOrder, { ...fieldsInitialValues }, true);

  const dispatch = useDispatch();
  const handleGetOrdersSum = (search = {}) => {
    dispatch(
      getOrdersSum({
        ...fieldsInitialValues,
        ...(IS_MOBILE || handleGetPageSearchCache()),
        ...search,
      }),
    );
  };
  useEffect(() => {
    handleGetOrdersSum();
    // eslint-disable-next-line
  }, []);
  const handleCustomSearch = formModel => {
    if (!formModel.created__btw && !formModel.paid_at__btw) {
      message.warning("「创建时间」与「支付时间」必填其中一项");
      return;
    }
    if (formModel.created__btw) {
      if (!rangeLimitDays(62, formModel.created__btw)) {
        message.warning("「创建时间」不得超過62天");
        return;
      }
    }
    if (formModel.paid_at__btw) {
      if (!rangeLimitDays(62, formModel.paid_at__btw)) {
        message.warning("「支付时间」不得超過62天");
        return;
      }
    }
    handleSearch(formModel);
    handleGetOrdersSum(formModel);
  };
  const [detailId, setDetailId] = useState(null);
  const {
    currentRow,
    loading: detailLoading,
    handleEdit,
  } = useDetail({ action: getOrder, id: detailId }, selectOrder);

  const history = useHistory();
  const handleDetailClick = async id => {
    history.push(generatePath("/OrderDetail/:id", { id }));
  };

  const [jsonVisible, setJsonVisible] = useState(false);
  const handleJsonClick = async id => {
    setDetailId(id);
    setJsonVisible(true);
  };
  useEffect(() => {
    jsonVisible || setDetailId(null);
  }, [jsonVisible]);

  const [editVisible, setEditVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const handleEditClick = async (record, mode) => {
    setEditVisible(true);
    setDetailId(record.id);
    setEditMode(mode);
  };
  const handleEditOk = async formModel => {
    const action = editMode === "approve" ? approveOrder : denyOrder;
    const { status } = await handleEdit({
      action,
      id: detailId,
      ...formModel,
      amount_paid: currentRow.amount,
    });
    if (status !== 200) return;
    setEditVisible(false);
    setDetailId(null);
    await handleGetList({ page: meta.page });
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportOrder,
    handleExportData: handleList,
  });

  // const handleCancelClick = id => {
  //   Modal.confirm({
  //     title: "是否取消订单",
  //     icon: <ExclamationCircleOutlined />,
  //     content: `即将取消订单 ${id}，是否继续？`,
  //     okText: "确认",
  //     cancelText: "取消",
  //     onOk: close => handleCancelOrder(close, id),
  //   });
  // };
  // const handleCancelOrder = async (close, id) => {
  //   const { status } = await cancelOrder(id);
  //   close();
  //   if (status !== 200) return;
  //   message.success("訂單已取消！");
  //   await handleGetList({ page: meta.page });
  // };

  const handleNotifyClick = async id => {
    Modal.confirm({
      title: "是否通知订单",
      icon: <ExclamationCircleOutlined />,
      content: `即将通知订单 ${id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleNotify(close, id),
    });
  };
  const handleNotify = async (close, id) => {
    const { status } = await notifyOrder(id);
    close();
    if (status !== 200) return;
    message.success("订单已通知！");
    await handleGetList({ page: meta.page });
  };

  const columns = [
    ...ListColumns,
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          {IS_MOBILE || (
            <Button
              size="small"
              onClick={() => handleJsonClick(record.id)}
              type="link"
              className="p-0"
            >
              json
            </Button>
          )}
          <Button
            size="small"
            onClick={() => handleDetailClick(record.id)}
            type="link"
            className="p-0"
          >
            查看
          </Button>
          {[2, 5].includes(record.status) && (
            <>
              <Button
                size="small"
                type="text"
                onClick={() => handleEditClick(record, "approve")}
                className="p-0"
              >
                确认
              </Button>
              {/* <Button
                size="small"
                onClick={() => handleEditClick(record, "deny")}
                type="text"
                danger
                className="p-0"
              >
                拒绝
              </Button>
              <Button
                size="small"
                onClick={() => handleCancelClick(record.id)}
                type="text"
                danger
                className="p-0"
              >
                取消
              </Button> */}
            </>
          )}
          {record.paid && !record.notified && (
            <Button
              size="small"
              type="text"
              onClick={() => handleNotifyClick(record.id)}
              className="p-0"
            >
              通知
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const payerCredExpandedRowRender = record => {
    return <div>{record}</div>;
  };
  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchBar
        fields={searchFields}
        handleSubmit={handleCustomSearch}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      <SumTable data={sum} labels={sumColumns} />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={handleList(list)}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        setSelectedColumns={handleSelectedColumns}
        expandable={{
          rowExpandable: record => true,
          expandedRowRender: record =>
            payerCredExpandedRowRender(JSON.stringify(record.payer_cred)),
        }}
        onShowSizeChange={handleChangePage}
      />
      <JsonModal
        width={650}
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
        loading={detailLoading}
      />
      <Edit
        visible={editVisible}
        onOk={handleEditOk}
        onCancel={() => setEditVisible(false)}
        loading={detailLoading}
        data={currentRow}
        mode={editMode}
      />
    </Space>
  );
};
export default Order;
