import React from "react";
import { Space, Button } from "antd-mobile";

function BlockBtnGroup({ source = [] }) {
  return (
    <div className="block-btn-group mt-1">
      <Space>
        {source.map(({ key, label, ...p }) => (
          <Button key={key} block {...p}>
            {label}
          </Button>
        ))}
      </Space>
    </div>
  );
}

export default BlockBtnGroup;
