/**
 * 搶單 列表
 */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  PullToRefresh,
  Card,
  Space,
  Image,
  Ellipsis,
  Button,
  InfiniteScroll,
} from "antd-mobile";

import useFetch from "@/hook/useFetch";
import useLoadMoreList from "@/hook/useLoadMoreList";
import {
  getTransfers,
  getTransfersSum,
  selectTransfer,
  claimTransfer,
} from "@/store/slice/transfer";
import { priceFormat } from "@/utils/format";
import { todayRangeFormat } from "@/utils/dateTime";
import LightTable from "@/components/mobile/LightTable";
import NoticeBar from "@/components/mobile/NoticeBar";

const defaultSearch = { status__in: "8,20" };

const sumColumns = [
  {
    key: "total_succeeded_amount",
    label: "今日总佣金",
    render: val => priceFormat({ val, currency: 0 }),
  },
  { key: "total_succeeded_times", label: "今日已抢单数" },
  {
    key: "3",
    label: "昨日总佣金",
    render: val => priceFormat({ val, currency: 0 }),
  },
  { key: "total_times", label: "今日已刷单数" },
];

function TransferList() {
  const history = useHistory();
  const goPath = state => {
    history.push({ pathname: "/transfer", state });
  };

  const { hasMore, list, loadMore, onRefresh } = useLoadMoreList({
    action: getTransfers,
    defaultSearch,
  });

  const dispatch = useDispatch();
  const { sum } = useSelector(selectTransfer);
  const handleGetTransfersSum = () => {
    dispatch(
      getTransfersSum({
        created__btw: todayRangeFormat(),
        // ...defaultSearch,
      }),
    );
  };
  useEffect(() => {
    handleGetTransfersSum();
    // eslint-disable-next-line
  }, []);

  const { loading: claimLoading, handelFetch: getClaimTransfer } = useFetch({
    action: claimTransfer,
  });
  const handleClaimTransfer = async item => {
    const { status, data } = await getClaimTransfer({ id: item.id });
    if (status === 200) {
      goPath(data);
    }
  };

  return (
    <PullToRefresh onRefresh={onRefresh}>
      <div style={{ backgroundColor: "var(--adm-color-background)" }}>
        <NoticeBar
          style={{
            "--background-color": "var(--adm-color-box)",
            padding: "0 1em",
            borderRadius: "2em",
          }}
        />
      </div>
      <div className="transfer-list">
        <Card>
          <LightTable
            columns={sumColumns}
            source={sum}
            size="small"
            gridColumns={4}
          />
        </Card>
        <div className="box-wrap">
          {list.map(item => (
            <Card key={item.id} className="mt-1">
              <div className="flex">
                <div>
                  <Image
                    src={item.extra?.product?.url || ""}
                    width={95}
                    height={95}
                    fit="fill"
                    style={{ borderRadius: 4 }}
                  />
                </div>
                <div className="flex flex-column ml-1 w-100">
                  <Ellipsis
                    direction="end"
                    rows={2}
                    content={item.extra?.product?.name || "商品名称"}
                  />
                  <Space className="mt-auto justify-between">
                    <span
                      style={{
                        fontSize: "var(--adm-font-size-8)",
                        color: "var(--adm-color-warning)",
                      }}
                    >
                      {priceFormat({
                        val: item.amount,
                        currency: item.currency,
                      })}
                    </span>
                    <Button
                      color="primary"
                      size="small"
                      loading={claimLoading}
                      onClick={() => handleClaimTransfer(item)}
                    >
                      马上抢购
                    </Button>
                  </Space>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </PullToRefresh>
  );
}

export default TransferList;
