import React from "react";
import { PullToRefresh, Card, InfiniteScroll, Divider } from "antd-mobile";

import useLoadMoreList from "@/hook/useLoadMoreList";
import { getAgentDaily } from "@/store/slice/agentDaily";

import { priceFormat, dateFormat } from "@/utils/format";
import { todayRangeFormat } from "@/utils/dateTime";

import SearchBar from "@/components/mobile/SearchBar";
import LightTable from "@/components/mobile/LightTable";

const searchFields = {
  created__btw: {
    type: "rangeDate",
    label: "时间",
  },
};

const userColumns = [
  {
    key: "total_trans_amount",
    label: "进货金额",
    render: val => priceFormat({ val }),
  },
  {
    key: "total_succeeded_amount",
    label: "出货金额",
    render: val => priceFormat({ val }),
  },
  { key: "total_succeeded_times", label: "交易笔数" },
  {
    key: "total_order_profit",
    label: "利润",
    render: val => priceFormat({ val }),
  },
];

const defaultSearch = { created__btw: todayRangeFormat() };

function AgentDaily() {
  const { hasMore, list, loadMore, onRefresh, onSearch } = useLoadMoreList({
    action: getAgentDaily,
    defaultSearch,
  });

  return (
    <>
      <SearchBar fields={searchFields} onSubmit={onSearch} />
      <PullToRefresh onRefresh={onRefresh}>
        <div className="box-wrap mt-1">
          {list.map(item => (
            <Card key={item.id} className="mt-1">
              <span className="tex-14 text-w-500">
                {dateFormat(item.created, "YYYY-MM-DD")}
              </span>
              <Divider style={{ margin: "0.6em 0" }} />
              <LightTable
                columns={userColumns}
                source={item}
                size="small"
                gridColumns={4}
              />
            </Card>
          ))}
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </div>
      </PullToRefresh>
    </>
  );
}

export default AgentDaily;
