/**
 * 搶單 列表
 */
import React from "react";
import { useHistory } from "react-router-dom";
import {
  PullToRefresh,
  Card,
  Space,
  Image,
  Ellipsis,
  Button,
  InfiniteScroll,
  Divider,
} from "antd-mobile";
import {
  RightOutlined,
  CopyOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";

import SearchBar from "@/components/mobile/SearchBar";

import useLoadMoreList from "@/hook/useLoadMoreList";
import { getTransfersClaim } from "@/store/slice/transfer";
import { todayRangeFormat } from "@/utils/dateTime";
import { priceFormat, dateFormat } from "@/utils/format";
import { transferStatus } from "@/utils/enum";
import { copyText } from "@/utils/others";

const StatusDisplay = ({ icon, text, color }) => {
  return (
    <div className="text-center" style={{ color }}>
      {icon({ style: { fontSize: "1.6em" } })}
      <div className="text-12">{text}</div>
    </div>
  );
};

/**
 * 完成 9,15,
 * 已取消 ： 12
 * 未付款 ：  3
 */
const transferStatusHandleMap = {
  3: {
    color: "var(--adm-color-primary)",
    text: "未付款",
    icon: p => <CloseCircleFilled {...p} />,
  },
  9: {
    color: "var(--adm-color-success)",
    text: "已完成",
    icon: p => <CheckCircleFilled {...p} />,
  },
  12: {
    color: "var(--grey-1)",
    text: "已取消",
    icon: p => <CloseCircleFilled {...p} />,
  },
  15: {
    color: "var(--adm-color-success)",
    text: "已完成",
    icon: p => <CheckCircleFilled {...p} />,
  },
};

const searchFields = {
  status__in: {
    type: "selector",
    label: "状态",
    initialValue: "9,10,12,13,15,16,19",
    options: [
      { label: "全部", value: "9,10,12,13,15,16,19" },
      { label: "未付款", value: "3" },
      { label: "已超时", value: "6" },
      { label: "已完成", value: "9,15" },
    ],
  },
  created__btw: {
    type: "rangeDate",
    label: "时间",
  },
};

function TransferSearchList() {
  const history = useHistory();
  const handleDetail = state => {
    history.push({ pathname: "/transferSearchDetail", state });
  };

  const { hasMore, list, loadMore, onRefresh, onSearch } = useLoadMoreList({
    action: getTransfersClaim,
    defaultSearch: {
      status__in: "9,10,12,13,15,16,19",
      created__btw: todayRangeFormat(),
    },
  });

  return (
    <>
      <SearchBar fields={searchFields} onSubmit={onSearch} />
      <PullToRefresh onRefresh={onRefresh}>
        <div className="transfer-list">
          <div className="box-wrap">
            {list.map(item => (
              <Card key={item.id} className="mt-1">
                {/* header */}
                <div className="flex align-center">
                  <span className="text-12 text-grey-2">
                    订单号：{item.order_no}
                  </span>
                  <Button
                    size="small"
                    fill="none"
                    className="p-0"
                    style={{ marginLeft: "0.4em" }}
                    onClick={() => copyText(item.order_no)}
                  >
                    <CopyOutlined
                      style={{ color: "var(--adm-color-info-2)" }}
                    />
                  </Button>
                  <div
                    className="flex align-center"
                    style={{
                      flex: "1 1 auto",
                      height: "27px",
                      justifyContent: "flex-end",
                    }}
                    onClick={() => handleDetail(item)}
                  >
                    <RightOutlined
                      style={{
                        fontSize: "0.8em",
                        color: "var(--grey-2)",
                      }}
                    />
                  </div>
                </div>
                <Divider
                  style={{ marginTop: "0.2em", marginBottom: "0.8em" }}
                />

                {/* body */}
                <div className="flex">
                  {/* left */}
                  <div>
                    <Image
                      src={item.extra?.product?.url || ""}
                      width={42}
                      height={42}
                      fit="fill"
                      style={{ borderRadius: 4 }}
                    />
                  </div>

                  {/* right */}
                  <div
                    className="flex flex-column ml-1"
                    style={{ flex: "1 1 auto" }}
                  >
                    <div className="flex justify-between">
                      <div>
                        <Ellipsis
                          className="text-14"
                          direction="end"
                          rows={1}
                          content={item.extra?.product?.name || "商品名称"}
                        />
                        <Space className="mt-auto justify-between align-center">
                          <span className="text-16 text-w-600 text-orange">
                            {priceFormat({
                              val: item.amount,
                              currency: item.currency,
                            })}
                          </span>
                        </Space>
                      </div>

                      <div>
                        {transferStatusHandleMap[item.status] ? (
                          StatusDisplay({
                            ...transferStatusHandleMap[item.status],
                          })
                        ) : (
                          <span className="text-12 text-grey-2">
                            {transferStatus[item.status]}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="flex justify-between align-center text-12 text-grey-2">
                      <span>{item.payer_name}</span>
                      <span>{dateFormat(item.created)}</span>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
      </PullToRefresh>
    </>
  );
}

export default TransferSearchList;
