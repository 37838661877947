import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import mobileRoutes from "@/router/mobile";
import { getRouter } from "@/utils/format";

import { selectAuth } from "@/store/slice/auth";
import { getAgentAccts } from "@/store/slice/agentAccts";
import { getNotices } from "@/store/slice/notices";

import NavBar from "@/components/mobile/NavBar";
import TabBar from "@/components/mobile/TabBar";
import ServicePopup from "@/components/mobile/ServicePopup";

const MobileGlobalLayout = () => {
  const { pathname } = useLocation();
  const { user } = useSelector(selectAuth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAgentAccts());
    dispatch(getNotices());
  }, [dispatch]);

  const renderRoutes = [...mobileRoutes];
  const route = getRouter(pathname, renderRoutes) || {};

  return (
    <Route path="/">
      {user !== null ? (
        <div className={`global-layout ${route.showNavBar && "have-nav-bar"}`}>
          <NavBar route={route} />
          <div
            className="container"
            style={{
              backgroundColor: route?.containerBg || "var(--body-background)",
            }}
          >
            <Switch>
              {mobileRoutes.map(i => (
                <Route
                  exact={i.exact}
                  path={i.path}
                  key={i.path}
                  children={<i.component />}
                />
              ))}
            </Switch>
          </div>
          <TabBar currentRouteName={route.name} />
          <ServicePopup />
        </div>
      ) : (
        <Redirect to={{ pathname: "/Login" }} />
      )}
    </Route>
  );
};
export default MobileGlobalLayout;
