import React from "react";
import { Form, Button, Input, Toast } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  postSetFundPassword,
  postFundPassword,
  selectFundPassword,
} from "@/store/slice/fundPassword";
import { selectAuth, setUser } from "@/store/slice/auth";
import useDetail from "@/hook/useDetail";
import FormExtraLabel from "@/components/mobile/FormExtraLabel";

function FundPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(selectAuth);

  const { loading, handleEdit } = useDetail(
    { id: null },
    selectFundPassword,
    false,
  );

  const isUpdate = user.fund_password;

  const onFinish = val => {
    const params = {
      action: isUpdate ? postFundPassword : postSetFundPassword,
      ...(isUpdate ? { old_fund_password: val.old_fund_password } : {}),
      fund_password: val.fund_password,
    };
    handleEdit({
      id: user.id,
      ...params,
      onSuccess: async () => {
        isUpdate || (await dispatch(setUser({ ...user, fund_password: true })));
        Toast.show({ icon: "success", content: "操作成功" });
        history.push("/setting");
      },
    });
  };

  const checkMobile = (val, fun_pass) => {
    if (val !== fun_pass) return Promise.reject(new Error("密码需一致"));
    return Promise.resolve();
  };

  return (
    <>
      <Form
        name="fund_password_form"
        onFinish={onFinish}
        footer={
          <Button block type="submit" color="primary" loading={loading}>
            确定
          </Button>
        }
      >
        {isUpdate && (
          <Form.Item
            name="old_fund_password"
            label={
              <FormExtraLabel
                label="原始资金密码"
                extra="忘记密码?"
                isService={true}
              />
            }
            rules={[
              { required: true, message: "必填" },
              { max: 6, message: "请输入6位数资金密码" },
              { min: 6, message: "请输入6位数资金密码" },
            ]}
          >
            <Input placeholder="请输入6位数资金密码" type="password" />
          </Form.Item>
        )}

        <Form.Item
          name="fund_password"
          label={isUpdate ? "新密码" : "密码"}
          rules={[
            { required: true, message: "必填" },
            { max: 6, message: "请输入6位数资金密码" },
            { min: 6, message: "请输入6位数资金密码" },
          ]}
        >
          <Input placeholder="请输入6位数资金密码" type="password" />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={true}>
          {({ getFieldValue }) => {
            const fun_pass = getFieldValue("fund_password");
            return (
              <Form.Item
                name="password"
                label="确认密码"
                dependencies={["fund_password"]}
                validateFirst={true}
                rules={[
                  { required: true, message: "必填" },
                  { max: 6, message: "请输入6位数资金密码" },
                  { min: 6, message: "请输入6位数资金密码" },
                  { validator: (_, val) => checkMobile(val, fun_pass) },
                ]}
              >
                <Input placeholder="请再次输入6位数资金密码" type="password" />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Form>
    </>
  );
}

export default FundPassword;
