import { createSlice } from "@reduxjs/toolkit";
import request from "@/utils/request";

// 第一次設置資金密碼
export const postSetFundPassword = async ({ formModel }) => {
  const res = await request({
    url: `/api/agent/users/set-fund-password`,
    method: "post",
    data: formModel,
  });
  return res;
};

// 更新資金密碼
export const postFundPassword = async ({ formModel }) => {
  const res = await request({
    url: `/api/agent/users/fund-password`,
    method: "post",
    data: formModel,
  });
  return res;
};

export const slice = createSlice({
  name: "fundPassword",
  initialState: {
    currentRow: {},
  },
  reducers: {},
  extraReducers: {},
});

// export const { setFundPassword } = slice.actions;
export const selectFundPassword = state => state.fundPassword;
export default slice.reducer;
