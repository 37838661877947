import React, { useState } from "react";
import { Space, Button } from "antd";
import {
  selectAgentAcctLog,
  getAgentAcctLogs,
  exportAgentAccLog,
} from "@/store/slice/agentAcctLog";
import { NormalTable } from "@/components/factory/TableFactory";
import SearchBar from "@/components/SearchBar";
import { useList, useExportExcel } from "@/utils/hook";
import Detail from "@/components/Detail";
import { IS_MOBILE } from "@/const";
import { listColumns, defaultColumns, exportColumnsRenderMap } from "./Column";

const searchFields = {
  ...(IS_MOBILE && { created__btw: { type: "rangeDate", label: "创建日期" } }),
  id__in: { type: "string", label: "ID" },
  agent_acct_id__in: { type: "string", label: "代理账户ID" },
  agent_user_id__in: { type: "string", label: "代理用户ID" },
  agent_name__k: { type: "string", label: "代理名称" },
  order_no__in: { type: "string", label: "订单号" },
  funding_type: {
    type: "select",
    label: "资金类型",
    options: {
      '{"type__ne":9}': "余额",
      '{"type__eq":9}': "圈存",
    },
  },
  ...(IS_MOBILE || { created__btw: { type: "rangeDate", label: "创建日期" } }),
};

const AgentAccLog = () => {
  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleChangePage,
    handleChange,
  } = useList(getAgentAcctLogs, selectAgentAcctLog, {}, true);

  const handleSearchSubmit = (params = {}) => {
    handleSearch(params);
  };

  const [currentRow, setCurrentRow] = useState({});
  const [detailVisible, setDetailVisible] = useState(false);
  const handleDetailClick = record => {
    setCurrentRow(record);
    setDetailVisible(true);
  };

  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportAgentAccLog,
  });

  const columns = [
    ...listColumns,
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleDetailClick(record)}
            type="link"
            className="p-0"
          >
            查看
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchBar
        fields={searchFields}
        handleSubmit={handleSearchSubmit}
        showExportBtn={true}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
        setSelectedColumns={handleSelectedColumns}
      />
      <Detail
        title="代理帐户资金纪录明細"
        visible={detailVisible}
        data={currentRow}
        onCancel={() => setDetailVisible(false)}
        loading={false}
        columns={columns.filter(i => i.dataIndex !== "action")}
      />
    </Space>
  );
};

export default AgentAccLog;
