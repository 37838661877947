import moment from "moment";
import { CurrencyFormat, Perms } from "@/utils/enum";
import Area from "@/utils/enum/area";

export const dateFormat = (date, formatStr = "YYYY-MM-DD HH:mm:ss") => {
  return date && moment(date)._isValid && typeof date === "string"
    ? moment(date).format(formatStr)
    : "";
};
export const priceFormat = ({ val, currency = 0, showUnit = true }) => {
  try {
    const { unit, rate, tofix } = CurrencyFormat.find(i => i.key === currency);
    const tofixVal = (val / rate).toLocaleString("en-US", {
      maximumFractionDigits: tofix,
    });
    return showUnit ? `${unit} ${tofixVal}` : tofixVal;
  } catch (e) {
    return "";
  }
};

export const cnyPriceFormat = val => {
  if (!val) return "";
  const tofixVal = (val / 1000).toLocaleString("en-US", {
    maximumFractionDigits: 4,
  });
  return tofixVal;
};

export const rangeDateFormat = val => {
  if (!val) return;
  if (moment.isMoment(val)) {
    return `${val[0]._d.toISOString()}~${val[1]._d.toISOString()}`;
  }
  return `${val[0].toISOString()}~${val[1].toISOString()}`;
};

export const rangeLimitDays = (limitDays = 0, dateStr = "") => {
  const dateArr = dateStr.split("~");
  let dt1 = new Date(dateArr[0]).getTime();
  let dt2 = new Date(dateArr[1]).getTime();
  const days = (dt2 - dt1) / (1000 * 60 * 60 * 24);
  return days <= limitDays;
};

const fieldVerifyHasVal = val => val !== undefined && val !== "";

export const objFormateToSearchFields = (obj = {}) => {
  let data = {};
  if (!obj) return data;
  Object.keys(obj).forEach(
    k => (data[k] = { type: "hidden", initialValue: obj[k] }),
  );
  return data;
};

/**
 * filter 所有 fields 的 initialValue
 * @param {*} fields
 * @returns {}
 */
export const searchFieldsFilterInitialValues = fields => {
  if (!Object.keys(fields).length) return {};
  let data = {};
  Object.keys(fields).forEach(f => {
    fields[f].initialValue && (data[f] = fields[f].initialValue);
  });
  return data;
};

/**
 * format 所有 fields for server
 * @param {*} param0
 * @returns {}
 */
export const searchFieldsFormat = ({ fields, formModel }) => {
  let params = {};
  Object.keys(fields).forEach(i => {
    if (fields[i].type === "rangeDate") {
      fieldVerifyHasVal(formModel[i]) &&
        (params[i] = rangeDateFormat(formModel[i]));
    } else if (fields[i].type === "select" && i === "funding_type") {
      fieldVerifyHasVal(formModel[i]) &&
        (params = { ...params, ...JSON.parse(formModel[i]) });
    } else {
      fieldVerifyHasVal(formModel[i]) && (params[i] = formModel[i]);
    }
  });
  return params;
};

/**
 * 處理 searchFields for server
 * @param {*} 頁面層定義的 searchFields
 * @returns {}
 */
export const searchFieldsInitialValuesFormat = (searchFields = {}) => {
  const formModel = searchFieldsFilterInitialValues(searchFields);
  return searchFieldsFormat({ fields: searchFields, formModel });
};

export const metaToPagin = meta => {
  if (!meta) return;
  return {
    pageSize: meta.per_page,
    current: meta.page,
    total: meta.total,
    pages: meta.pages,
  };
};

export const treeToPermsFormat = selectedPerms => {
  let formModel = {};
  Perms.forEach(i => {
    i.children.forEach(j => {
      formModel[j.key] = selectedPerms.indexOf(j.key) >= 0;
    });
  });
  return formModel;
};
export const getDefaultCheckKeys = perms => {
  if (perms === null) return [];
  try {
    let defaultCheckedKeys = [];
    Object.keys(perms).forEach(i => {
      perms[i] && defaultCheckedKeys.push(i);
    });
    return defaultCheckedKeys;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getRouterDisplayName = (path, router = []) =>
  router.find(i => i.path === path)?.displayName ||
  router.find(i => i.name === path.split("/")[1])?.displayName;

export const getRouter = (path, router = []) =>
  router.find(i => i.path === path) ||
  router.find(i => i.name === path.split("/")[1]);

export const getRouterParam = path => {
  const pathLength = path.split("/").length;
  return pathLength > 2 ? ` - ${path.split("/")[pathLength - 1]}` : "";
};

export const permsToArrayFormat = perms => {
  const permsArr = [];
  perms &&
    Object.keys(perms).forEach(i => {
      if (i.split(".").length <= 1 || !perms[i]) return;
      let name;
      Perms.forEach(j => {
        j.children.forEach(k => {
          k.key === i && (name = k.title);
        });
      });
      permsArr.push({ key: i, name });
    });
  return permsArr;
};

export const getColumns = () => {
  return JSON.parse(localStorage.getItem("columns") || "{}")[
    window.location.pathname
  ];
};
export const getCityArr = prov => {
  const cityItem = Object.entries(Area).filter(
    item => item[0].replace("省", "") === prov.replace("省", ""),
  );
  return prov && cityItem.length > 0 ? Object.keys(cityItem[0][1].items) : [];
};
