import React, { useEffect, useState } from "react";
import { Image, TabBar } from "antd-mobile";
import { useHistory } from "react-router-dom";

const tabsImgs = {
  home: {
    inactive: require("@/assets/mobile/home/tabBar/home-inactive.png").default,
    active: require("@/assets/mobile/home/tabBar/home-active.png").default,
  },
  reload: {
    inactive: require("@/assets/mobile/home/tabBar/reload.png").default,
    active: require("@/assets/mobile/home/tabBar/reload.png").default,
  },
  userCenter: {
    inactive: require("@/assets/mobile/home/tabBar/userCenter-inactive.png")
      .default,
    active: require("@/assets/mobile/home/tabBar/userCenter-active.png")
      .default,
  },
};

const tabs = [
  {
    key: "home",
    title: "首页",
    icon: active => {
      const _src = active ? tabsImgs.home.active : tabsImgs.home.inactive;
      return <Image src={_src} width={24} height={24} />;
    },
  },
  {
    key: "reload",
    title: "刷新",
    icon: () => <Image src={tabsImgs.reload.active} width={24} height={24} />,
  },
  {
    key: "userCenter",
    title: "我的",
    icon: active => {
      const _src = active
        ? tabsImgs.userCenter.active
        : tabsImgs.userCenter.inactive;
      return <Image src={_src} width={24} height={24} />;
    },
  },
];

const TabBarC = ({ currentRouteName = "" }) => {
  const [activeKey, setActiveKey] = useState("");

  useEffect(() => {
    if (currentRouteName) {
      const _activeKey = currentRouteName.replace(
        /^./,
        currentRouteName[0].toLowerCase(),
      );
      setActiveKey(_activeKey);
    }
  }, [currentRouteName]);

  const history = useHistory();
  const onChange = path => {
    setActiveKey(path);
    if (path === "reload") {
      window.location.reload();
      return;
    }
    const pushPath = path === "home" ? "/" : `/${path}`;
    history.push(pushPath);
  };

  return (
    <div className="fixed-bottom tab-bar">
      <TabBar activeKey={activeKey} onChange={onChange}>
        {tabs.map(tab => (
          <TabBar.Item key={tab.key} title={tab.title} icon={tab.icon} />
        ))}
      </TabBar>
    </div>
  );
};

export default TabBarC;
