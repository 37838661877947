import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popup, FloatingBubble, DotLoading } from "antd-mobile";
import { CustomerServiceFilled } from "@ant-design/icons";
import {
  selectService,
  setServiceVisible,
  setNewMsg,
} from "@/store/slice/service";

import withService from "@/hoc/withService";

const _FloatingBubble = ({ loading = false, onClick = () => {} }) => {
  const [offset, setOffset] = useState({ x: -10, y: -30 });
  return (
    <FloatingBubble
      axis="xy"
      onClick={onClick}
      offset={offset}
      onOffsetChange={offset => {
        setOffset(offset);
      }}
      style={{
        "--initial-position-bottom": "40px",
        "--initial-position-right": "20px",
        "--edge-distance": "24px",
        "--background": "var(--adm-color-info-2)",
        "--z-index": "5",
      }}
    >
      {loading ? (
        <DotLoading color="white" />
      ) : (
        <CustomerServiceFilled style={{ fontSize: 30 }} />
      )}
    </FloatingBubble>
  );
};
const CSFloatingBtn = withService(_FloatingBubble);

function ServicePopup() {
  const dispatch = useDispatch();
  const { url: serviceUrl, visible: serviceVisible } =
    useSelector(selectService);

  const listener = e => {
    const typeMap = {
      back: () => dispatch(setServiceVisible(false)),
      unread: () => dispatch(setNewMsg(e.data)),
    };
    typeMap[e.data?.type] && typeMap[e.data?.type]();
  };

  useEffect(() => {
    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  });

  return (
    <>
      <CSFloatingBtn />
      <Popup visible={serviceVisible} bodyStyle={{ height: "100%" }}>
        <iframe
          title="service"
          src={serviceUrl}
          frameBorder="0"
          scrolling="no"
          style={{ border: 0, height: "100%", width: "100%" }}
        />
      </Popup>
    </>
  );
}

export default ServicePopup;
