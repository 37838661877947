import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getBanks = createAsyncThunk(
  "bank/getList",
  async (params = {}) => {
    const res = await request({ url: "/api/agent/banks", method: "get", params });
    return res;
  },
);

export const slice = createSlice({
  name: "bank",
  initialState: {
    list: [],
    meta: {},
  },
  reducers: {
    setBanks: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {
    [getBanks.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
  },
});
export const { setBanks } = slice.actions;
export const selectBank = state => state.bank;
export default slice.reducer;
