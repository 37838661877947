import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { PullToRefresh, Card, Divider, InfiniteScroll } from "antd-mobile";
import {
  RightOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { dateFormat, priceFormat, cnyPriceFormat } from "@/utils/format";
import { todayRangeFormat } from "@/utils/dateTime";
import { OrderStatus } from "@/utils/enum";

import useLoadMoreList from "@/hook/useLoadMoreList";
import { getOrderSell } from "@/store/slice/usdt";
import { selectUuii } from "@/store/slice/uuii";

import SearchBar from "@/components/mobile/SearchBar";
import { ListItem } from "@/components/mobile/List";

const navTypeMap = {
  // 進行中
  inProgress: {
    defaultSearch: { status__in: "2,3,11,15,18", succeeded: false },
    statusOptions: [
      { label: "全部", value: 1 },
      { label: "未付款", value: 2 },
      { label: "已付款", value: 3 },
    ],
    statusSearchMap: {
      1: { status__in: "2,3,11,15,18", succeeded: false },
      2: { status__in: "2,3", succeeded: false },
      3: { status__in: "11,15,18", succeeded: false },
    },
    created: {},
  },
  // 已完成
  completed: {
    defaultSearch: {
      status__in: "5,6,7,8,9,10,13,14,15,16",
      created__btw: todayRangeFormat(),
    },
    statusOptions: [
      { label: "全部", value: 1 },
      { label: "已完成", value: 2 },
      { label: "已取消", value: 3 },
    ],
    statusSearchMap: {
      1: { status__in: "5,6,7,8,9,10,13,14,15,16" },
      2: { status__in: "9,13,14,15", succeeded: true },
      3: { status__in: "5,6,7,10,13,14,16", succeeded: false },
    },
    created: {
      created__btw: {
        type: "rangeDate",
        label: "时间",
      },
    },
  },
};

const StatusDisplay = ({ icon, text, color }) => {
  return (
    <div className="text-center" style={{ color }}>
      {icon({ style: { fontSize: "2.8em" } })}
      <div className="text-12">{text}</div>
    </div>
  );
};

const orderStatusHandleMap = {
  2: {
    color: "var(--adm-color-primary)",
    text: "未付款",
    icon: p => <CloseCircleFilled {...p} />,
  },
  3: {
    color: "var(--adm-color-primary)",
    text: "未付款",
    icon: p => <CloseCircleFilled {...p} />,
  },
  5: {
    color: "var(--grey-1)",
    text: "失败",
    icon: p => <CloseCircleFilled {...p} />,
  },
  6: {
    color: "var(--grey-1)",
    text: "已超時",
    icon: p => <CloseCircleFilled {...p} />,
  },
  7: {
    color: "var(--grey-1)",
    text: "已取消",
    icon: p => <CloseCircleFilled {...p} />,
  },
  10: {
    color: "var(--adm-color-danger)",
    text: "审核拒绝",
    icon: p => <CloseCircleFilled {...p} />,
  },
  11: {
    color: "var(--adm-color-success)",
    text: "待通知",
    icon: p => <CheckCircleFilled {...p} />,
  },
  14: {
    color: "var(--grey-1)",
    text: "通知失败",
    icon: p => <CloseCircleFilled {...p} />,
  },
  15: {
    color: "var(--adm-color-success)",
    text: "已完成",
    icon: p => <CheckCircleFilled {...p} />,
  },
  16: {
    color: "var(--adm-color-danger)",
    text: "订单支付失败",
    icon: p => <CloseCircleFilled {...p} />,
  },
  18: {
    color: "var(--adm-color-success)",
    text: "已付款",
    icon: p => <CheckCircleFilled {...p} />,
  },
};

function SellUsdtOrderList() {
  const { navBar } = useSelector(selectUuii);
  const currentNav = navTypeMap[navBar.SellUsdtOrderList];

  const history = useHistory();
  const handleDetail = state => {
    history.push({ pathname: "/sellUsdtOrderDetail", state });
  };

  const { hasMore, list, loadMore, onRefresh, onSearch } = useLoadMoreList({
    action: getOrderSell,
    defaultSearch: currentNav.defaultSearch,
  });

  const searchFields = useMemo(
    () => ({
      status__in: {
        type: "tab",
        initialValue: 1,
        options: currentNav.statusOptions,
      },
      ...currentNav.created,
    }),
    [currentNav],
  );

  const _onSearch = val => {
    let { status__in, created__btw } = val;

    if (navBar.SellUsdtOrderList === "completed") {
      created__btw = created__btw || todayRangeFormat();
    }

    const params = {
      ...currentNav.statusSearchMap[status__in],
      created__btw,
    };
    onSearch(params);
  };

  return (
    <>
      <SearchBar
        fields={searchFields}
        onSubmit={_onSearch}
        fieldsWrapProps={{ style: { justifyContent: "center" } }}
      />
      <PullToRefresh onRefresh={onRefresh}>
        <div className="box-wrap">
          {list.map(item => (
            <Card key={item.id} className="mt-1">
              {/* header */}
              <div className="flex align-center">
                <span className="text-12 text-grey-2">
                  订单号：{item.order_no}
                </span>
                <div
                  className="flex align-center"
                  style={{
                    flex: "1 1 auto",
                    height: "27px",
                    justifyContent: "flex-end",
                  }}
                  onClick={() => handleDetail(item)}
                >
                  <RightOutlined
                    style={{
                      fontSize: "0.8em",
                      color: "var(--grey-2)",
                    }}
                  />
                </div>
              </div>
              <Divider style={{ marginTop: "0.2em", marginBottom: "0.8em" }} />

              {/* body */}
              <div className="flex">
                {/* left */}
                <div>
                  {orderStatusHandleMap[item.status] ? (
                    StatusDisplay({ ...orderStatusHandleMap[item.status] })
                  ) : (
                    <span className="text-12 text-grey-2">
                      {OrderStatus[item.status]}
                    </span>
                  )}
                </div>

                {/* right */}
                <div
                  className="flex flex-column ml-1"
                  style={{ flex: "1 1 auto" }}
                >
                  <ListItem
                    label="出售"
                    value={priceFormat({
                      val: item.amount,
                      currency: item.currency,
                    })}
                    showBorderBottom={false}
                    style={{ color: "var(--adm-color-primary)" }}
                  />
                  <ListItem
                    label="总价"
                    value={`${cnyPriceFormat(
                      item.credential.data.cny_price,
                    )} CNY`}
                    showBorderBottom={false}
                  />
                  <ListItem
                    label="汇率"
                    value={`¥ ${item.credential.data.price} / USDT`}
                    showBorderBottom={false}
                    style={{ color: "var(--grey-2)" }}
                  />
                  <ListItem
                    label="交易时间"
                    value={dateFormat(item.created)}
                    showBorderBottom={false}
                    style={{ color: "var(--grey-2)" }}
                  />
                </div>
              </div>
            </Card>
          ))}

          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </div>
      </PullToRefresh>
    </>
  );
}

export default SellUsdtOrderList;
