import React from "react";
import { PullToRefresh, Card, InfiniteScroll, Divider } from "antd-mobile";

import useLoadMoreList from "@/hook/useLoadMoreList";
import { getAgentSubDaily } from "@/store/slice/agentSubDaily";

import { priceFormat, dateFormat } from "@/utils/format";
import { todayRangeFormat } from "@/utils/dateTime";
import SearchBar from "@/components/mobile/SearchBar";
import LightTable from "@/components/mobile/LightTable";

const searchFields = {
  created__btw: {
    type: "rangeDate",
    label: "时间",
  },
  agent_name__k: { type: "string", placeholder: "请输入账号" },
};

const userColumns = [
  {
    key: "total_trans_amount",
    label: "进货金额",
    render: val => priceFormat({ val, currency: 0 }),
  },
  {
    key: "total_succeeded_amount",
    label: "出货金额",
    render: val => priceFormat({ val, currency: 0 }),
  },
  { key: "total_succeeded_times", label: "交易笔数" },
  {
    key: "total_order_profit",
    label: "利润",
    render: val => priceFormat({ val, currency: 0 }),
  },
];

const defaultSearch = { created__btw: todayRangeFormat() };

function AgentSubDaily() {
  const { hasMore, list, loadMore, onRefresh, onSearch } = useLoadMoreList({
    action: getAgentSubDaily,
    defaultSearch,
  });

  return (
    <>
      <SearchBar fields={searchFields} onSubmit={onSearch} />
      <PullToRefresh onRefresh={onRefresh}>
        <div className="box-wrap">
          {list.map(item => (
            <Card key={item.id} className="mt-1">
              <div className="flex justify-between">
                <div>
                  <span className="tex-12 text-w-500">{item.agent_name}</span>
                  <span className="tex-12 text-w-500">
                    ({item.agent_user_name})
                  </span>
                </div>
                <span className="tex-12 text-grey-2">
                  {dateFormat(item.created, "YYYY-MM-DD")}
                </span>
              </div>

              <Divider style={{ margin: "0.6em 0" }} />
              <LightTable
                columns={userColumns}
                source={item}
                size="small"
                gridColumns={4}
              />
            </Card>
          ))}
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </div>
      </PullToRefresh>
    </>
  );
}

export default AgentSubDaily;
