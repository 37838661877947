import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getTransfers = createAsyncThunk(
  "tranfser/getListTransfers",
  async (params = {}) => {
    const res = await request({
      url: "/api/agent/transfers",
      method: "get",
      params,
    });
    return res;
  },
);

export const getTransfersSum = createAsyncThunk(
  "tranfser/getListSum",
  async (params = {}) => {
    const res = await request({
      url: "/api/agent/transfers/sum",
      method: "get",
      params,
    });
    return res;
  },
);

// 代付 認領清單
export const getTransfersClaim = createAsyncThunk(
  "tranfser/getListClaim",
  async (params = {}) => {
    const res = await request({
      url: "/api/agent/transfers/claim",
      method: "get",
      params,
    });
    return res;
  },
);

// 提現
export const postTransfers = async (data = {}) => {
  const res = await request({
    url: "/api/agent/transfers",
    method: "post",
    data,
  });
  return res;
};

export const succeededTransfer = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/agent/transfers/${id}/succeeded`,
    method: "post",
    data: formModel,
  });
  return res;
};

export const succeededTransferV2 = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/agent/v2/transfers/${id}/succeeded`,
    method: "post",
    data: formModel.formData,
  });
  return res;
};

export const failedTransfer = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/agent/transfers/${id}/failed`,
    method: "post",
    data: { id, ...formModel },
  });
  return res;
};

// 認領
export const claimTransfer = async ({ id, formModel = {} }) => {
  const res = await request({
    url: `/api/agent/transfers/${id}/claim`,
    method: "post",
    data: { id, ...formModel },
  });
  return res;
};

export const cancelClaimTransfer = async ({ id }) => {
  const res = await request({
    url: `/api/agent/transfers/${id}/cancel/claim`,
    method: "post",
    data: { id },
  });
  return res;
};

// 匯出 excel
export const exportOrder = async (params = {}) => {
  const res = await request({
    url: `/api/agent/transfers/export`,
    method: "get",
    params: { per_page: 9999, ...params },
  });
  return res;
};

export const slice = createSlice({
  name: "transfer",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
    gateways: [],
    offlineGateways: [],
    status2Total: 0,
    sum: {},
  },
  extraReducers: {
    [getTransfers.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    [getTransfersClaim.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    [getTransfersSum.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.sum = data;
    },
  },
});
export const selectTransfer = state => state.transfer;
export default slice.reducer;
