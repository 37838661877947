import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Card, Avatar, Divider, Image } from "antd-mobile";
import { Descriptions } from "antd";

import { getAgentsInfo, selectAgentAccts } from "@/store/slice/agentAccts";
import { getImg } from "@/utils/others";
import { priceFormat } from "@/utils/format";
import { formattedMinTime } from "@/utils/dateTime";

import LightTable from "@/components/mobile/LightTable";

const _getImg = name => {
  return getImg(`userCenter/${name}`);
};

const bannerImg = _getImg("banner.png");

const subInfoList = [
  {
    key: "total_success_times",
    label: "30日成单数",
    render: val => <>{val}</>,
  },
  { key: "success_rate", label: "当日成单率", render: val => <>{val}%</> },
  {
    key: "average_order_time_interval",
    label: "平均放行",
    render: val => <>{formattedMinTime(val)}</>,
  },
  {
    key: "average_transfer_time_interval",
    label: "平均付款",
    render: val => <>{formattedMinTime(val)}</>,
  },
];

const mainInfoList = [
  {
    key: "balance",
    label: "库存余额(￥)",
    render: val => priceFormat({ val }),
  },
  {
    key: "today_order_profit",
    label: "今日收益(￥)",
    render: val => priceFormat({ val }),
  },
  {
    key: "yesterday_order_profit",
    label: "昨日收益(￥)",
    render: val => priceFormat({ val }),
  },
];

const frequentlyToolList = [
  {
    key: "1",
    label: "进货订单",
    path: "/transferSearchList",
    img: _getImg("frequentlyTool-1.png"),
  },
  {
    key: "2",
    label: "出货订单",
    path: "/orderSearchList",
    img: _getImg("frequentlyTool-2.png"),
  },
  {
    key: "3",
    label: "收款方式",
    path: "/card",
    img: _getImg("frequentlyTool-3.png"),
  },
  {
    key: "4",
    label: "推广下级",
    path: "/promoteSubAgent",
    img: _getImg("frequentlyTool-4.png"),
  },
];

const myToolList = [
  {
    key: "1",
    label: "团队中心",
    path: "/subAgent",
    img: _getImg("myTool-1.png"),
  },
  {
    key: "2",
    label: "我要提现",
    path: "/withdraw",
    img: _getImg("myTool-2.png"),
  },
  {
    key: "3",
    label: "收货地址",
    path: "/addresses",
    img: _getImg("myTool-3.png"),
  },
  { key: "4", label: "设置", path: "/setting", img: _getImg("myTool-4.png") },
];

function UserCenter() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { agentInfo } = useSelector(selectAgentAccts);

  const goPath = path => {
    history.push(path);
  };

  useEffect(() => {
    dispatch(getAgentsInfo());
  }, [dispatch]);

  return (
    <div className="user-center box-wrap">
      <Grid columns={1} gap={16}>
        <Grid.Item span={1}>
          <Card>
            <Grid columns={2}>
              <Grid.Item span={1} className="user-block">
                <div>
                  <Avatar
                    src=""
                    style={{ "--size": "44px", "--border-radius": "50%" }}
                  />
                </div>
                <div className="username">
                  <span>{agentInfo?.agent_name || ""}</span>
                  <span>ID{agentInfo?.agent_id || ""}</span>
                </div>
              </Grid.Item>

              <Grid.Item span={1}>
                <div className="sub-info-section">
                  <Descriptions layout="vertical" size="small" column={2}>
                    {subInfoList.map(item => (
                      <Descriptions.Item
                        key={item.key}
                        label={item.label}
                        span={1}
                        labelStyle={{ fontSize: "12px", color: "#aaaaaa" }}
                        contentStyle={{ fontSize: "12px", color: "#e54949" }}
                      >
                        <div className="text-center">
                          {item.render(agentInfo[item.key])}
                        </div>
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </div>
              </Grid.Item>

              <Grid.Item span={2}>
                <Divider />
              </Grid.Item>

              <Grid.Item span={2}>
                <LightTable columns={mainInfoList} source={agentInfo} />
              </Grid.Item>
            </Grid>
          </Card>
        </Grid.Item>
        <Grid.Item span={1}>
          <Image src={bannerImg} />
        </Grid.Item>
        <Grid.Item span={1}>
          <ToolSection
            title="常用工具"
            source={frequentlyToolList}
            onClick={goPath}
          />
        </Grid.Item>
        <Grid.Item span={1}>
          <ToolSection title="我的工具" source={myToolList} onClick={goPath} />
        </Grid.Item>
      </Grid>
    </div>
  );
}

const ToolSection = ({ title = "", source = [], onClick = () => {} }) => {
  return (
    <div className="tool-section">
      <h3 className="title">{title}</h3>
      <Card>
        <Grid columns={4} gap={16}>
          {source.map(item => (
            <Grid.Item
              span={1}
              key={item.key}
              onClick={() => onClick(item.path)}
              className="flex-center"
            >
              <Image src={item.img} width={38} />
              <span className="label">{item.label}</span>
            </Grid.Item>
          ))}
        </Grid>
      </Card>
    </div>
  );
};

export default UserCenter;
