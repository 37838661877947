import React from "react";
import { Grid } from "antd-mobile";

function lightTable({
  columns = [],
  source = {},
  size = "default",
  gridColumns = 3,
  gridGap = 0,
  span = 1,
  labelStyle = {},
  valueStyle = {},
}) {
  return (
    <div className={`light-table size-${size}`}>
      <Grid columns={gridColumns} gap={gridGap}>
        {columns.map(item => (
          <Grid.Item key={item.key} span={span} className="text-center">
            {item.children ? (
              item.children()
            ) : (
              <>
                <span className="label" style={labelStyle}>
                  {item.label}
                </span>
                <h5
                  className="value"
                  style={{ marginBottom: 0, ...valueStyle }}
                >
                  {item.render
                    ? item.render(source[item.key] || 0)
                    : source[item.key] || 0}
                </h5>
              </>
            )}
          </Grid.Item>
        ))}
      </Grid>
    </div>
  );
}

export default lightTable;
