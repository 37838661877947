import React, { useState } from "react";
import { Form, Button, Input, TextArea } from "antd-mobile";
import { useHistory, useLocation } from "react-router-dom";

import useDetail from "@/hook/useDetail";
import {
  addAddress,
  editAddress,
  selectAddresses,
} from "@/store/slice/addresses";

import { PickerArea } from "@/components/mobile/FormItemSelectorPicker";
import ModalConfirm from "@/components/mobile/ModalConfirm";

function AddressAddEdit() {
  const { state } = useLocation();
  const isEditMode = Boolean(state);
  const initialValues = isEditMode
    ? {
        ...state,
        picker: [state.province, state.city, state.district],
      }
    : {};

  const history = useHistory();
  const goPath = () => history.push("/addresses");

  const [visibleSuccessModal, setSuccessModal] = useState(false);

  const { loading, handleAdd, handleEdit } = useDetail(
    { id: null },
    selectAddresses,
    false,
  );

  const onFinish = ({ picker, ...val }) => {
    const data = { ...val };
    const keys = ["province", "city", "district"];
    picker.forEach((element, idx) => {
      data[keys[idx]] = element;
    });
    const params = {
      action: isEditMode ? editAddress : addAddress,
      onSuccess: () => setSuccessModal(true),
      ...(isEditMode ? { id: state.id } : {}),
      ...data,
    };
    isEditMode ? handleEdit(params) : handleAdd(params);
  };

  return (
    <div>
      <Form
        name="form"
        onFinish={onFinish}
        initialValues={initialValues}
        footer={
          <Button block type="submit" color="primary" loading={loading}>
            {isEditMode ? "确认" : "立即添加"}
          </Button>
        }
      >
        <Form.Item
          name="receiver_name"
          label="收件人"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请输入收件人" />
        </Form.Item>
        <Form.Item
          name="receiver_mobile"
          label="收件人手机"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请输入收件人手机" />
        </Form.Item>

        <Form.Item
          name="picker"
          label="城市"
          rules={[{ required: true, message: "必填" }]}
        >
          <PickerArea />
        </Form.Item>
        <Form.Item
          name="address"
          label="街道详细地址"
          rules={[{ required: true, message: "必填" }]}
        >
          <TextArea
            placeholder="请输入街道详细地址"
            maxLength={100}
            rows={2}
            showCount
          />
        </Form.Item>
      </Form>

      <ModalConfirm
        type="success"
        visible={visibleSuccessModal}
        text={
          <>
            操作成功
            <br />
            反回收货地址
          </>
        }
        onConfirm={goPath}
        onClose={() => setSuccessModal(false)}
      />
    </div>
  );
}

export default AddressAddEdit;
