import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getUsers = createAsyncThunk(
  "user/getList",
  async (params = {}) => {
    const res = await request({
      url: "/api/agent/users",
      method: "get",
      params,
    });
    return res;
  },
);

export const getUsersV2 = createAsyncThunk(
  "user/getList",
  async (params = {}) => {
    const res = await request({
      url: "/api/agent/v2/users",
      method: "get",
      params,
    });
    return res;
  },
);

export const getUsersSumV2 = createAsyncThunk(
  "user/getSum",
  async (params = {}) => {
    const res = await request({
      url: "/api/agent/v2/users/sum",
      method: "get",
      params,
    });
    return res;
  },
);

export const addUser = async params => {
  const res = await request({
    url: `/api/agent/users`,
    method: "post",
    data: params,
  });
  return res;
};

export const resetUserPsw = async params => {
  const res = await request({
    url: `/api/agent/users/${params.id}/password`,
    method: "post",
    data: params.formModel,
  });
  return res;
};

export const resetUserOtp = async id => {
  const res = await request({
    url: `/api/agent/users/${id}/otp_reset`,
    method: "post",
    data: {},
  });
  return res;
};

export const slice = createSlice({
  name: "user",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
    sum: {},
  },
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    [getUsersSumV2.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.sum = data;
    },
  },
});
export const selectUser = state => state.user;
export default slice.reducer;
