import React from "react";
import ReactDOM from "react-dom";
import MobileApp from "./MobileApp";
import DesktopApp from "./DesktopApp";
import { IS_MOBILE } from "./const";
import reportWebVitals from "./reportWebVitals";

const App = IS_MOBILE ? MobileApp : DesktopApp;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
