import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import DatePicker from "react-mobile-datepicker";
import { Input, Form, message } from "antd";
import moment from "moment";

const MobileDatePicker = forwardRef(
  (
    { name = "", label = "", form, initialValue = null, showTime = true },
    ref,
  ) => {
    const startName = `${name}.start`;
    const endName = `${name}.end`;
    const currName = useRef("");
    const mainRef = useRef(null);
    const startRef = useRef(null);
    const endRef = useRef(null);
    const [datePickerValue, setDatePickerValue] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);
    const formateStr = showTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD";

    useImperativeHandle(ref, () => ({
      reset: init,
    }));

    const handleClick = name => {
      let val = form.getFieldValue(name);
      if (val) {
        // 解決 new Date() Invalid Date 格式问题
        const date = new Date(Date.parse(val.replace(/-/g, "/")));
        setDatePickerValue(date);
      }
      currName.current = name;
      name === endName && endRef.current?.focus();
      name === startName && startRef.current?.focus();
      setIsOpen(true);
    };
    const handleCancel = () => {
      // let formDateRangeValue = form.getFieldValue(name);
      // if (!formDateRangeValue || formDateRangeValue.length <= 2) {
      //   form.setFieldsValue({ [name]: undefined });
      //   form.setFieldsValue({ [startName]: undefined });
      //   form.setFieldsValue({ [endName]: undefined });
      // }
      setIsOpen(false);
    };

    const handleSelect = time => {
      const timeMoment = moment(time);
      const timeFormat = moment(time).format(formateStr);
      let formStartVal = form.getFieldValue(startName);
      let formEndVal = form.getFieldValue(endName);
      const tempName = currName.current;
      let dateArr = [];
      if (tempName.indexOf(".start") > 0) {
        if (!moment(timeMoment).isBefore(formEndVal)) {
          message.warning("请小于结束时间");
          return;
        }
        formEndVal
          ? (dateArr = [timeMoment, moment(formEndVal)])
          : (dateArr = [timeMoment]);
        form.setFieldsValue({ [tempName]: timeFormat });
        form.setFieldsValue({ [name]: dateArr });
        handleClick(endName);
      }
      if (tempName.indexOf(".end") > 0) {
        if (!moment(formStartVal).isBefore(timeMoment)) {
          message.warning("请大于开始时间");
          return;
        }
        formStartVal
          ? (dateArr = [moment(formStartVal), timeMoment])
          : handleClick(startName);
        form.setFieldsValue({ [tempName]: timeFormat });
        form.setFieldsValue({ [name]: dateArr });
        formStartVal && handleCancel();
      }
    };

    const init = useCallback(() => {
      if (!initialValue) {
        setDatePickerValue(new Date());
        return;
      }
      form.setFieldsValue({ [startName]: initialValue[0].format(formateStr) });
      form.setFieldsValue({ [endName]: initialValue[1].format(formateStr) });
    }, [endName, form, formateStr, initialValue, startName]);
    useEffect(() => {
      init();
    }, [init]);

    return (
      <>
        <Form.Item name={name} hidden={true}>
          <Input ref={mainRef} />
        </Form.Item>
        <Form.Item label={label}>
          <Form.Item
            name={startName}
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              marginRight: "4px",
              marginBottom: "0px",
            }}
          >
            <Input
              ref={startRef}
              placeholder="开始日期"
              onClick={() => handleClick(startName)}
              readOnly
            />
          </Form.Item>
          <Form.Item
            name={endName}
            style={{
              display: "inline-block",
              width: "calc(50% - 4px)",
              marginLeft: "4px",
              marginBottom: "0px",
            }}
          >
            <Input
              ref={endRef}
              placeholder="结束日期"
              onClick={() => handleClick(endName)}
              readOnly
            />
          </Form.Item>
        </Form.Item>
        <DatePicker
          value={datePickerValue}
          isOpen={isOpen}
          onCancel={handleCancel}
          onSelect={handleSelect}
          headerFormat={showTime ? "YYYY-MM-DD hh:mm:ss" : "YYYY-MM-DD"}
          dateConfig={{
            year: {
              format: "YYYY",
              caption: "Year",
              step: 1,
            },
            month: {
              format: "MM",
              caption: "Mon",
              step: 1,
            },
            date: {
              format: "DD",
              caption: "Day",
              step: 1,
            },
            ...(showTime && {
              hour: {
                format: "hh",
                caption: "Hour",
                step: 1,
              },
              minute: {
                format: "mm",
                caption: "Min",
                step: 1,
              },
              second: {
                format: "ss",
                caption: "Sec",
                step: 1,
              },
            }),
          }}
        />
      </>
    );
  },
);

export default MobileDatePicker;
