import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Selector,
  DatePickerView,
  Button,
  Toast,
  SearchBar as SearchInput,
  Tabs,
} from "antd-mobile";
import { RightOutlined } from "@ant-design/icons";
import {
  todayDate,
  todayEndDate,
  threeDaysAgoDate,
  sevenDaysAgoDate,
  thirtyDaysAgoDate,
  formatDateStr,
  compareRangeDateStrSize,
} from "@/utils/dateTime";

import { searchFieldsFormat } from "@/utils/format";

export const defaultRangeDateOptions = [
  // {
  //   label: "全部",
  //   value: null,
  // },
  {
    label: "今天",
    value: todayDate,
  },
  {
    label: "近3天",
    value: threeDaysAgoDate,
  },
  {
    label: "近7天",
    value: sevenDaysAgoDate,
  },
  {
    label: "近30天",
    value: thirtyDaysAgoDate,
  },
  {
    label: "自定义",
    value: "customize",
  },
];

export const defaultSelectorOptions = [
  {
    label: "全部",
    value: null,
  },
];

const labelRenderer = (type, data) => {
  switch (type) {
    case "year":
      return data + "年";
    case "month":
      return data + "月";
    case "day":
      return data + "日";
    case "hour":
      return data + "时";
    case "minute":
      return data + "分";
    case "second":
      return data + "秒";
    default:
      return data;
  }
};

const handleInitialValue = fields => {
  let _fields = { ...fields };
  Object.keys(_fields).forEach(key => {
    if (_fields[key].type === "rangeDate") {
      _fields[key].options || (_fields[key].options = defaultRangeDateOptions);
      const initialOption = _fields[key].options.find(
        option => option.value === _fields[key].initialValue,
      );
      if (initialOption) {
        _fields[key].selectValueLabel = initialOption.label;
      } else {
        _fields[key].initialValue = defaultRangeDateOptions[0].value;
        _fields[key].selectValueLabel = defaultRangeDateOptions[0].label;
      }
    }
  });
  return fields;
};

/**
 * TODO: 目前只提供 每種type各一個元件
 */
function SearchBar({
  fields: _fields = {},
  onSubmit = () => {},
  fieldsWrapProps = { style: {} },
}) {
  const [visible, setVisible] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [currClickField, setCurrClickField] = useState({});

  const [fields, setFields] = useState({});
  useEffect(() => {
    setFields(handleInitialValue(_fields));
    setSearchData({});
  }, [_fields]);

  const handleSubmit = useCallback(
    data => {
      Object.keys(data).forEach(k => {
        if (!data[k]) {
          delete data[k];
        }
      });
      const params = searchFieldsFormat({ fields, formModel: data });
      onSubmit(params);

      // 恢復預設
      setVisible(false);
    },
    [fields, onSubmit],
  );

  const handleSearchData = useCallback(
    (key, val) => {
      let _data = { ...searchData };
      _data[key] = val;
      setSearchData(_data);
      handleSubmit(_data);
    },
    [handleSubmit, searchData],
  );

  const onClickField = data => {
    return () => {
      if (dropTypeMap[data.type]) {
        setCurrClickField(data);
        setVisible(!visible);
      } else {
        setVisible(false);
      }
    };
  };

  const [selectorLabel, setSelectorLabel] = useState(
    defaultSelectorOptions[0].label,
  );

  const handleChangeSelectLabel = useCallback(
    (key, label) => {
      setFields({
        ...fields,
        [key]: { ...fields[key], selectValueLabel: label },
      });
    },
    [fields],
  );

  const fieldsTypeMap = useMemo(
    () => ({
      rangeDate: props => {
        return (
          <Button
            fill="solid"
            size="small"
            shape="rounded"
            style={{
              "--background-color": "var(--adm-color-fill-content)",
            }}
          >
            {props.selectValueLabel}
          </Button>
        );
      },
      string: ({ key, ...p }) => (
        <SearchInput
          placeholder="请输入"
          style={{ "--border-radius": "30px", paddingBottom: "0.1em" }}
          onSearch={val => handleSearchData(key, val)}
          onClear={() => handleSearchData(key, "")}
          {...p}
        />
      ),
      selector: () => (
        <Button
          fill="solid"
          size="small"
          shape="rounded"
          style={{
            "--background-color": "var(--adm-color-fill-content)",
          }}
        >
          {selectorLabel}
        </Button>
      ),
      tab: ({ key, options = [], initialValue, ...p }) => {
        return (
          <Tabs
            activeKey={searchData[key] || initialValue}
            onChange={val => handleSearchData(key, val)}
            style={{ "--title-font-size": "14px" }}
          >
            {options.map(option => (
              <Tabs.Tab title={option.label} key={option.value} />
            ))}
          </Tabs>
        );
      },
      extra: ({ content }) => {
        return <>{content()}</>;
      },
    }),
    [handleSearchData, selectorLabel, searchData],
  );

  const dropTypeMap = useMemo(
    () => ({
      rangeDate: ({ key, options, initialValue }) => (
        <RangeDateField
          onChange={rangeDateArr => {
            handleSearchData(key, rangeDateArr);
          }}
          onChangeSelect={obj => handleChangeSelectLabel(key, obj.label)}
          rangeDateOptions={options}
          initialValue={initialValue}
        />
      ),
      selector: ({ key, options, ...p }) => (
        <SelectorField
          onChange={val => handleSearchData(key, val)}
          onChangeSelect={obj => setSelectorLabel(obj.label)}
          options={options}
          {...p}
        />
      ),
    }),
    [handleSearchData, handleChangeSelectLabel],
  );

  const DropContentComp = useMemo(
    () =>
      dropTypeMap[currClickField.type]
        ? dropTypeMap[currClickField.type]({ ...currClickField })
        : null,
    [dropTypeMap, currClickField],
  );

  return (
    <>
      <div className={`search-bar ${visible ? "visible" : ""}`}>
        <div className="search-bar-content box-wrap">
          <div
            className="flex align-center"
            style={{ whiteSpace: "nowrap", ...fieldsWrapProps.style }}
          >
            {Object.keys(fields).map((key, idx, arr) => {
              const { type, label, ...p } = fields[key];
              const isLast = arr.length - 1 === idx;

              return (
                <div
                  key={key}
                  align="center"
                  onClick={onClickField({ key, ...fields[key] })}
                  className="flex align-center"
                  style={{
                    paddingRight: isLast ? "0" : "0.4em",
                    ...(type !== "tab" ? { marginBottom: "0.2em" } : {}),
                  }}
                >
                  {label && (
                    <label style={{ marginRight: "0.2em" }}>{label}</label>
                  )}
                  {fieldsTypeMap[type]
                    ? fieldsTypeMap[type]({ key, ...p })
                    : null}
                </div>
              );
            })}
          </div>
        </div>

        <div id="DROPDOWN" className={`dropdown`}>
          <div className={`dropdown-content  ${visible ? "visible" : ""}`}>
            {DropContentComp}
          </div>

          <div className="dropdown-mask" onClick={() => setVisible(false)} />
        </div>
      </div>
    </>
  );
}

const SelectorField = ({
  options = [],
  onChange = () => {},
  onChangeSelect = () => {},
  initialValue = null,
}) => {
  const [rangeDateSelectorValue, setRangeDateSelectorValue] =
    useState(initialValue);

  const onChangeRangeDateSelector = val => {
    if (!val.length) {
      return;
    }
    const _v = val[0];
    onChangeSelect(options.find(option => option.value === _v));
    setRangeDateSelectorValue(_v);
    onChange(_v);
  };

  return (
    <div className="box-wrap mb-1">
      <Selector
        options={options}
        defaultValue={[null]}
        value={[rangeDateSelectorValue]}
        onChange={onChangeRangeDateSelector}
        showCheckMark={false}
        style={{
          "--border-radius": "100px",
          "--border": "solid transparent 1px",
          "--padding": "6px 16px",
          "--checked-border": "solid var(--adm-color-primary) 1px",
          "--checked-color": "var(--adm-color-primary)",
          "--checked-text-color": "white",
          "--text-color": "var(--grey-3)",
        }}
      />
    </div>
  );
};

const RangeDateField = ({
  onChange = () => {},
  onChangeSelect = () => {},
  rangeDateOptions = [],
  initialValue = null,
}) => {
  const [rangeDateSelectorValue, setRangeDateSelectorValue] =
    useState(initialValue);

  const onChangeRangeDateSelector = val => {
    if (!val.length) {
      return;
    }

    const _v = val[0];

    setRangeDateSelectorValue(_v);

    onChangeSelect(rangeDateOptions.find(option => option.value === _v));

    if (_v === "customize") {
      setVisibleRangeDate(true);
    } else {
      setVisibleRangeDate(false);
      _v ? onChange([_v, todayEndDate]) : onChange(null);
    }
  };

  const [visibleRangeDate, setVisibleRangeDate] = useState(false);

  const [formatDisplay, setFormatDisplay] = useState([
    formatDateStr(todayDate),
    formatDateStr(todayDate),
  ]);

  const [visibleDatePickerView, setVisibleDatePickerView] = useState(null);
  const handleVisibleDatePickerView = index => {
    return () => {
      visibleDatePickerView === index
        ? setVisibleDatePickerView(null)
        : setVisibleDatePickerView(index);
    };
  };

  const [cusDatePickerValue, setCusDatePickerValue] = useState([
    todayDate,
    todayEndDate,
  ]);

  const onChangeCusDatePickerView = index => {
    return val => {
      let _v = [...cusDatePickerValue];
      _v[index] = val;
      setCusDatePickerValue(_v);

      let _vFormat = [...formatDisplay];
      _vFormat[index] = formatDateStr(val);
      setFormatDisplay(_vFormat);
    };
  };

  const onConfirmCusRangeDate = () => {
    const isValid = compareRangeDateStrSize(...cusDatePickerValue);
    if (!isValid) {
      Toast.show({
        icon: "fail",
        content: "开始时间不可大于结束时间",
      });
      return;
    }

    onChange(cusDatePickerValue);
    setVisibleDatePickerView(null);
  };

  return (
    <div className="range-date">
      {/* 時間區間 快速選擇 */}
      <div className="box-wrap">
        <Selector
          options={rangeDateOptions}
          defaultValue={[null]}
          value={[rangeDateSelectorValue]}
          onChange={onChangeRangeDateSelector}
          showCheckMark={false}
          style={{
            "--border-radius": "100px",
            "--border": "solid transparent 1px",
            "--padding": "6px 18px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
            "--checked-color": "var(--adm-color-primary)",
            "--checked-text-color": "white",
            "--text-color": "var(--grey-3)",
            paddingBottom: "1em",
          }}
        />
      </div>

      {/* 時間區間 自定義 */}
      <div
        className={`range-date-picker-view ${
          visibleRangeDate ? "visible" : ""
        }`}
      >
        <div className="box-wrap">
          {["开始时间", "结束时间"].map((item, idx) => (
            <React.Fragment key={idx}>
              <div
                className="flex justify-between mb-1"
                onClick={handleVisibleDatePickerView(idx)}
              >
                <span>{item}</span>
                <span>
                  {formatDisplay[idx]}
                  <RightOutlined />
                </span>
              </div>
              {visibleDatePickerView === idx && (
                <DatePickerView
                  defaultValue={cusDatePickerValue[idx]}
                  value={cusDatePickerValue[idx]}
                  onChange={onChangeCusDatePickerView(idx)}
                  renderLabel={labelRenderer}
                  style={{ "--height": "125px" }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex">
          <Button
            color="primary"
            shape="rectangular"
            block
            onClick={onConfirmCusRangeDate}
          >
            确定
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
