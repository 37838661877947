import React from "react";
import { Modal } from "antd-mobile";
import { ExclamationCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import BlockBtnGroup from "@/components/mobile/BlockBtnGroup";

function ModalConfirm({
  type = "warning",
  visible = false,
  loading = false,
  text = "",
  textNode = null,
  onClose = () => {},
  onConfirm = () => {},
  closeText = "取消",
  confirmText = "确认",
}) {
  const iconTypeMap = {
    warning: (
      <ExclamationCircleFilled
        style={{
          fontSize: "4em",
          color: "var(--adm-color-warning)",
          marginBottom: "0.2em",
        }}
      />
    ),
    success: (
      <CheckCircleFilled
        style={{
          fontSize: "4em",
          color: "var(--adm-color-success)",
          marginBottom: "0.2em",
        }}
      />
    ),
  };

  return (
    <Modal
      visible={visible}
      closeOnMaskClick={true}
      onClose={onClose}
      style={{ zIndex: 2000 }}
      content={
        <div className="text-center">
          {iconTypeMap[type]}
          {textNode ? textNode : <p className="text-center">{text}</p>}
          <BlockBtnGroup
            source={[
              {
                key: "1",
                label: closeText,
                color: "primary",
                fill: "outline",
                loading: loading,
                onClick: onClose,
              },
              {
                key: "2",
                label: confirmText,
                color: "primary",
                loading: loading,
                onClick: onConfirm,
              },
            ]}
          />
        </div>
      }
    />
  );
}

export default ModalConfirm;
