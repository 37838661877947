import { useState } from "react";
import { Descriptions, Modal, Table, Button } from "antd";
import { IS_MOBILE } from "@/const";

export const SumTableSelect = ({ data = {}, labels = [] }) => {
  return (
    <Descriptions
      bordered
      layout="vertical"
      size="small"
      column={{ xs: 2, sm: 4, md: 8 }}
      style={{ backgroundColor: "#fff" }}
    >
      {labels.map(i => {
        return (
          <Descriptions.Item label={i.title} key={i.dataIndex}>
            {i.render ? i.render(data[i.dataIndex] || 0) : data[i.dataIndex]}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
};
export const SumScrollTable = ({ data = {}, labels = [] }) => {
  const [visible, setVisible] = useState(false);
  const handleClick = () => setVisible(true);
  const handleCancel = () => setVisible(false);
  return (
    <>
      <div onClick={handleClick}>
        <Table
          size="small"
          rowKey="id"
          scroll={{ x: "auto" }}
          columns={labels}
          dataSource={[{ id: "1", ...data }]}
          pagination={false}
        />
      </div>
      <Modal
        title="总和"
        visible={visible}
        onCancel={handleCancel}
        centered
        width={"100%"}
        bodyStyle={{ padding: "0px" }}
        footer={[
          <Button key="close" type="primary" onClick={handleCancel}>
            关闭
          </Button>,
        ]}
      >
        <Descriptions column={1} bordered size="small">
          {labels.map(i => {
            return (
              <Descriptions.Item label={i.title} key={i.dataIndex}>
                {i.render
                  ? i.render(data[i.dataIndex] || 0)
                  : data[i.dataIndex]}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </Modal>
    </>
  );
};

const SumTable = props => {
  return IS_MOBILE ? (
    <SumScrollTable {...props} />
  ) : (
    <SumTableSelect {...props} />
  );
};

export default SumTable;
