import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Button, Divider, Input, Popup, Space } from "antd-mobile";
import {
  ReloadOutlined,
  SyncOutlined,
  RightOutlined,
  PlusCircleOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import { getCardsV2, selectCard } from "@/store/slice/card";
import { getExchange, postSellUsdt, selectUsdt } from "@/store/slice/usdt";
import useDetail from "@/hook/useDetail";

import { DeviceType } from "@/utils/enum";
import { round, limitDecimalPoint, zeroPadding } from "@/utils/others";
import LightTable from "@/components/mobile/LightTable";
import FullLoading from "@/components/mobile/FullLoading";

const exchangeColumns = [
  { key: "otc_price", label: "博通实时汇率" },
  { key: "binance_price", label: "币安实时汇率" },
  { key: "huobi_price", label: "火币实时汇率" },
];

function SellUsdt() {
  const history = useHistory();
  const goPath = (pathname, state) => history.push({ pathname, state });

  const dispatch = useDispatch();
  const { list: bankCardList } = useSelector(selectCard);

  const [visibleBankPop, setVisibleBankPop] = useState(false);
  const [bankValue, setBankValue] = useState(undefined);
  const [exchangeData, setExchangeData] = useState({});

  useEffect(() => {
    if (bankCardList.length) {
      let defaultCard = undefined;
      defaultCard =
        bankCardList.find(card => card.is_default) ||
        bankCardList.find(card => card.is_active);
      setBankValue(defaultCard);
    }
  }, [bankCardList]);

  const [exchangeLoading, setExchangeLoading] = useState(false);
  const handleGetExchange = async () => {
    setExchangeLoading(true);
    const { data, status } = await getExchange();
    if (status === 200) setExchangeData(data);
    setExchangeLoading(false);
  };
  useEffect(() => {
    handleGetExchange();
    dispatch(getCardsV2());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [amount, setAmount] = useState("");
  const [usdtAmount, setUsdtAmount] = useState("");

  const onChangeAmount = value => {
    if (!limitDecimalPoint(value)) return;
    setAmount(value);
    const roundNum = round(value * exchangeData.price);
    setUsdtAmount(roundNum);
  };

  const onChangeUsdt = value => {
    if (!limitDecimalPoint(value)) return;
    setUsdtAmount(value);
    const roundNum = round(value / exchangeData.price);
    setAmount(roundNum);
  };

  const { loading, handleAdd } = useDetail({ id: null }, selectUsdt);

  const handleSellUsdt = () => {
    const _usdtAmount = zeroPadding(String(usdtAmount), 2);

    const params = {
      amount: _usdtAmount,
      pay_type: 4,
      currency: 2,
      device_type: DeviceType["H5"],
      recipient_cred: {
        name: bankValue.name,
        account: bankValue.account,
        sub_bank: bankValue.sub_bank,
        bank_code: bankValue.bank_code,
        open_bank: bankValue.bank_name,
      },
    };
    handleAdd({
      action: postSellUsdt,
      onSuccess: data => {
        goPath("/qrcodeTransfer", data);
      },
      ...params,
    });
  };

  const greyCardStyle = {
    backgroundColor: "var(--body-background)",
    borderRadius: "10px",
    padding: "0.8em 1em",
  };

  if (!bankCardList.length) {
    return (
      <div className="flex-center mt-1">
        <ExclamationCircleFilled
          style={{
            fontSize: "4em",
            color: "var(--adm-color-primary)",
            marginBottom: "0.2em",
          }}
        />
        <p className="text-center">
          未设新增银行卡
          <br />
          请先新增银行卡
        </p>
        <Button color="primary" onClick={() => goPath("/cardAdd")}>
          去新增
        </Button>
      </div>
    );
  }

  if (!bankValue) {
    return (
      <div className="flex-center mt-1">
        <ExclamationCircleFilled
          style={{
            fontSize: "4em",
            color: "var(--adm-color-primary)",
            marginBottom: "0.2em",
          }}
        />
        <p className="text-center">
          未设置预设或启用任何一张银行卡
          <br />
          请先设置银行卡
        </p>
        <Button color="primary" onClick={() => goPath("/card")}>
          去设置
        </Button>
      </div>
    );
  }

  return (
    <div className=" box-wrap mt-1 mb-1">
      <Card>
        <div className="flex align-center justify-between">
          <FullLoading loading={exchangeLoading}>
            <LightTable
              columns={exchangeColumns}
              source={exchangeData}
              size="small"
              gridColumns={3}
              gridGap={12}
            />
          </FullLoading>
          <Button
            onClick={handleGetExchange}
            shape="rounded"
            style={{
              "--background-color": "var(--adm-color-info-1)",
              padding: "4px 7px",
            }}
          >
            <ReloadOutlined style={{ color: "white" }} />
          </Button>
        </div>
      </Card>

      <Card className="mt-1">
        <Item label="单价" value={`${exchangeData.price} / USDT`} />

        <Divider />

        <div style={greyCardStyle} className="mb-1">
          <div className="flex align-centare">
            <Input
              placeholder="请输入金额"
              value={amount}
              onChange={onChangeAmount}
              type="number"
            />
            <span className="text-grey-3">CNY</span>
          </div>

          <div className="flex align-center">
            <Divider
              style={{
                flex: "1 1 auto",
                margin: "10px 10px 10px 0",
              }}
            />
            <div
              style={{
                borderRadius: "20px",
                backgroundColor: "var(--grey-1)",
                width: "20px",
                height: "20px",
              }}
              className="flex-center"
            >
              <SyncOutlined style={{ color: "var(--grey-0)" }} />
            </div>
          </div>

          <div className="flex align-centare">
            <Input
              placeholder="请输入数量"
              value={usdtAmount}
              onChange={onChangeUsdt}
              type="number"
            />
            <span className="text-grey-3">USDT</span>
          </div>
        </div>

        <Item label="数量" value={`${usdtAmount || "--"} USDT`} />
        <Item label="总价" value={`${amount || "--"} CNY`} />

        <div
          className="flex align-center mt-1"
          style={greyCardStyle}
          onClick={() => setVisibleBankPop(true)}
        >
          <Input
            value={`${bankValue.bank_name}`}
            placeholder={"选择收款银行卡"}
            style={{ pointerEvents: "none" }}
          />
          <RightOutlined style={{ color: "var(--grey-2)" }} />
        </div>

        <Button
          block
          color="primary"
          className="mt-1"
          onClick={handleSellUsdt}
          loading={loading}
        >
          0手续费出售
        </Button>
      </Card>

      <Card className="mt-1">
        <span className="text-16">交易信息</span>
        <Item
          label="付款时限"
          value="30分钟"
          valueProps={{ className: "text-grey-3" }}
        />
        <Item
          label="买家支付方式"
          value="银行借记卡"
          valueProps={{ className: "text-grey-3" }}
        />
      </Card>

      {/* 銀行卡 */}
      <Popup
        visible={visibleBankPop}
        onMaskClick={() => setVisibleBankPop(false)}
      >
        <div className="text-center mt-1">
          <span className="text-14">选择收款银行卡</span>
        </div>
        <div style={{ padding: "1.4em 1.4em" }}>
          <Space
            direction="vertical"
            className="w-100"
            style={{ "--gap": "12px" }}
          >
            {bankCardList.map(item => {
              const isActive = bankValue.id === item.id;
              return (
                <div
                  key={item.id}
                  className={`flex align-center w-100 ${
                    isActive ? "" : "text-grey-2"
                  }`}
                  onClick={() => setBankValue(item)}
                >
                  <div
                    className="flex-center"
                    style={{ width: "20px", marginRight: "0.8em" }}
                  >
                    {item.logo_url && (
                      <img src={item.logo_url} width={20} alt="" />
                    )}
                  </div>
                  {item.bank_name} ({item.account})
                  {isActive && (
                    <CheckCircleFilled
                      style={{
                        fontSize: "14",
                        color: "var(--adm-color-success)",
                        marginLeft: "auto",
                      }}
                    />
                  )}
                </div>
              );
            })}
            <div
              className="flex align-center"
              onClick={() => goPath("/cardAdd")}
            >
              <div
                className="flex-center"
                style={{ width: "20px", marginRight: "0.8em" }}
              >
                <PlusCircleOutlined
                  style={{
                    fontSize: "24px",
                    color: "var(--grey-1)",
                    marginRight: "0.2em",
                  }}
                />
              </div>
              <span className="text-grey-2">添加银行卡</span>
            </div>
          </Space>
        </div>
      </Popup>
    </div>
  );
}

const Item = ({ label = "", value = "", valueProps = {} }) => {
  return (
    <div className="flex align-center justify-between text-14">
      <span className="text-grey-2">{label}</span>
      <span className="text-red" {...valueProps}>
        {value}
      </span>
    </div>
  );
};

export default SellUsdt;
