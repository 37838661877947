import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavBar, Button } from "antd-mobile";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { selectUuii, setNavBar } from "@/store/slice/uuii";

const RouteButton = ({ label = "", onClick }) => (
  <Button
    size="small"
    fill="none"
    style={{ "--text-color": "var(--adm-color-info-1)", paddingRight: 0 }}
    onClick={onClick}
  >
    {label}
  </Button>
);
const NavBarC = ({ route = {} }) => {
  const history = useHistory();

  const onBack = () => {
    route.goBackPath ? history.push(route.goBackPath) : window.history.back();
  };

  const routeRight = {
    OrderList: (
      <RouteButton
        label="出货订单"
        onClick={() => history.push("/orderSearchList")}
      />
    ),
    TransferList: (
      <RouteButton
        label="进货订单"
        onClick={() => history.push("/transferSearchList")}
      />
    ),
    SellUsdt: (
      <RouteButton
        label="订单记录"
        onClick={() => history.push("/sellUsdtOrderList")}
      />
    ),
  };

  if (!route.showNavBar) return null;

  return (
    <>
      <NavBar
        onBack={onBack}
        className="nav-bar"
        right={routeRight[route.name]}
      >
        <h2 className="title">{route.displayName}</h2>
      </NavBar>
      {route.name === "SellUsdtOrderList" && <SellUsdtOrderListExtra />}
    </>
  );
};

const SellUsdtOrderListExtra = () => {
  const { navBar } = useSelector(selectUuii);
  const dispatch = useDispatch();

  const handleActive = val => {
    dispatch(setNavBar({ SellUsdtOrderList: val }));
  };

  const active = {
    color: "primary",
  };
  const unActive = {
    fill: "none",
    style: { "--text-color": "var(--grey-1)" },
  };

  const selects = [
    { key: "inProgress", label: "进行中" },
    { key: "completed", label: "已完成" },
  ];

  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        zIndex: 6,
        backgroundColor: "var(--grey-0)",
        borderRadius: "1000px",
      }}
    >
      {selects.map(item => (
        <Button
          key={item.key}
          size="mini"
          shape="rounded"
          onClick={() => handleActive(item.key)}
          {...(item.key === navBar.SellUsdtOrderList ? active : unActive)}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
};

export default NavBarC;
