import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Card,
  Ellipsis,
  Image,
  Modal,
  // Input,
  SpinLoading,
  // Form,
  // Button,
  // TextArea,
} from "antd-mobile";

import {
  getOrder,
  selectOrder,
  approveOrder,
  // denyOrder,
} from "@/store/slice/order";
import useDetail from "@/hook/useDetail";

import { ListItem } from "@/components/mobile/List";
import BlockBtnGroup from "@/components/mobile/BlockBtnGroup";
import ModalConfirm from "@/components/mobile/ModalConfirm";
// import ImageUploader from "@/components/mobile/ImageUploader";

import { dateFormat, priceFormat } from "@/utils/format";
// import { zeroPadding } from "@/utils/others";
import { OrderStatus } from "@/utils/enum";

function OrderDetail() {
  const { state } = useLocation();
  const history = useHistory();

  // const [visibleDenyModal, setDenyModal] = useState(false); // 核對
  const [visibleApproveModal, setApproveModal] = useState(false);
  const [visibleApproveConfirmModal, setVisibleApproveConfirmModal] =
    useState(false);

  // const [denyForm] = Form.useForm();
  // const [approveForm] = Form.useForm();

  const { currentRow, loading } = useDetail(
    { action: getOrder, id: state.id },
    selectOrder,
  );

  const { loading: activeLoading, handleEdit } = useDetail(
    { id: null },
    selectOrder,
  );

  // const handleDenyOrder = async () => {
  //   await denyForm.validateFields();
  //   const formModel = denyForm.getFieldsValue();

  //   handleEdit({
  //     action: denyOrder,
  //     id: currentRow.id,
  //     ...formModel,
  //     onSuccess: () => {
  //       setDenyModal(false);
  //       history.push("/orderList");
  //     },
  //   });
  // };

  const onClickApprove = async () => {
    // await approveForm.validateFields();
    setVisibleApproveConfirmModal(true);
  };
  const handleApprove = async () => {
    // const formModel = approveForm.getFieldsValue();
    // const amount = zeroPadding(formModel.amount_paid);

    // const formData = new FormData();
    // formData.append("amount_paid", amount);
    // formData.append("image", formModel.image);

    handleEdit({
      action: approveOrder,
      id: currentRow.id,
      onSuccess: () => {
        setApproveModal(false);
        setVisibleApproveConfirmModal(false);
        history.push("/orderList");
      },
      // formData,
    });
  };

  const statusStyle = {
    2: { color: "var(--adm-color-info-1)" },
    6: { color: "var(--grey-1)" },
    15: { color: "var(--adm-color-info-3)" },
  };

  if (loading || !currentRow.id)
    return (
      <SpinLoading
        color="primary"
        style={{ margin: "auto", "--size": "48px" }}
      />
    );

  return (
    <div className="box-wrap mt-1 mb-4">
      <Card>
        <ListItem label="订单号" value={currentRow.order_no} showCopy={true} />
        <ListItem label="时间" value={dateFormat(currentRow.created)} />
        <ListItem
          label="有效时间"
          type="countdown"
          value={currentRow.expired_at}
        />
        <ListItem
          label="订单金额"
          value={priceFormat({
            val: currentRow.amount,
            currency: currentRow.currency,
          })}
          style={{ color: "var(--adm-color-primary)" }}
        />

        <ListItem label="收款银行" value={currentRow.payer_cred.bank_code} />
        <ListItem label="收款人姓名" value={currentRow.payer_cred.real_name} />
        <ListItem label="付款人姓名" value={currentRow.payer_name} />
        <ListItem
          label="订单状态"
          value={OrderStatus[currentRow.status]}
          showBorderBottom={false}
          style={{
            ...(statusStyle[currentRow.status] || { color: "var(--grey-3)" }),
          }}
        />
      </Card>
      <Card className="mt-1">
        <div className="flex justify-between">
          <h3 className="text-14 text-red mr-2">购买品项</h3>
          <Ellipsis
            direction="end"
            rows={1}
            content={currentRow.extra?.product?.name || "商品名称"}
          />
        </div>
        <div className="flex justify-between">
          <div>
            <div>
              <span className="text-12 text-grey-2">运费：</span>
              <span className="text-12 text-grey-2">包邮</span>
            </div>
            <div>
              <span className="text-12 text-grey-2">价格：</span>
              <span className="text-14 text-orange">
                {priceFormat({
                  val: currentRow.amount,
                  currency: currentRow.currency,
                })}
              </span>
            </div>
            <div>
              <span className="text-12 text-grey-2">数量：</span>
              <span className="text-12 text-grey-2">1</span>
            </div>
          </div>
          <div>
            <Image
              src={currentRow.extra?.product?.url || ""}
              width={66}
              height={66}
              fit="fill"
              style={{ borderRadius: 4 }}
            />
          </div>
        </div>
      </Card>
      {[2, 6].includes(currentRow.status) && (
        <BlockBtnGroup
          source={[
            // {
            //   key: "1",
            //   label: "金额错误",
            //   color: "primary",
            //   fill: "outline",
            //   onClick: () => setDenyModal(true),
            // },
            {
              key: "2",
              label: "确认收款",
              color: "primary",
              onClick: () => setApproveModal(true),
            },
          ]}
        />
      )}

      {/* deny */}
      {/* <Modal
        visible={visibleDenyModal}
        title={"金额错误"}
        closeOnMaskClick={true}
        onClose={() => setDenyModal(false)}
        content={
          <>
            <Form form={denyForm}>
              <Form.Item
                name="comments"
                label="备注"
                rules={[{ required: true, message: "必填" }]}
              >
                <TextArea placeholder="请输入备注" maxLength={100} rows={2} />
              </Form.Item>
            </Form>
            <Button
              block
              color="primary"
              loading={loading}
              onClick={handleDenyOrder}
            >
              提交
            </Button>
          </>
        }
      /> */}

      {/* approve */}
      <Modal
        visible={visibleApproveModal}
        title={"核对到账信息"}
        bodyStyle={{ maxHeight: "100%" }}
        closeOnMaskClick={true}
        onClose={() => setApproveModal(false)}
        content={
          <div className="mt-1">
            <ListItem
              label="订单号"
              value={currentRow.order_no}
              showCopy={true}
            />
            <ListItem label="账号信息" value={currentRow.order_no} />
            <ListItem
              label="订单金额"
              value={priceFormat({
                val: currentRow.amount,
                currency: currentRow.currency,
              })}
              style={{ color: "var(--adm-color-primary)" }}
            />
            <ListItem label="创建时间" value={dateFormat(currentRow.created)} />
            {/* <h6 className="text-12 text-red text-center">请输入实际入账金额</h6> */}

            {/* <Form form={approveForm}>
              <Form.Item
                name="amount_paid"
                noStyle
                rules={[{ required: true, message: "必填" }]}
              >
                <Input
                  style={{
                    "--text-align": "center",
                    border: "1px solid var(--adm-color-border)",
                    borderRadius: "0.2em",
                    padding: "0.1em",
                  }}
                />
              </Form.Item>

              <Form.Item
                name="image"
                noStyle
                rules={[{ required: true, message: "必填" }]}
              >
                <ImageUploader />
              </Form.Item>
            </Form> */}

            <BlockBtnGroup
              source={[
                {
                  key: "1",
                  label: "取消",
                  color: "primary",
                  fill: "outline",
                  loading: activeLoading,
                  onClick: () => setApproveModal(false),
                },
                {
                  key: "2",
                  label: "确认",
                  color: "primary",
                  loading: activeLoading,
                  onClick: onClickApprove,
                },
              ]}
            />
          </div>
        }
      />

      {/* approve confirm */}
      <ModalConfirm
        visible={visibleApproveConfirmModal}
        text="请再次确认信息无误再点确认，否则货品会直接发给买家。"
        loading={activeLoading}
        onClose={() => setVisibleApproveConfirmModal(false)}
        onConfirm={handleApprove}
      />
    </div>
  );
}

export default OrderDetail;
