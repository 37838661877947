import React from "react";
import { SpinLoading } from "antd-mobile";
function FullLoading({
  children,
  loading = false,
  wrapStyle = {},
  style = {},
  loadingText = null,
}) {
  return (
    <div className="full-loading" style={{ ...wrapStyle }}>
      <div className={`opacity-bg ${loading ? "is-loading" : ""}`}>
        {children}
      </div>
      {loading && (
        <div className={`loading`}>
          <div style={{ margin: "auto" }}>
            <SpinLoading
              color="primary"
              style={{ "--size": "20px", ...style }}
            />
            {loadingText && <div className="mt-1">{loadingText}</div>}
          </div>
        </div>
      )}
    </div>
  );
}

export default FullLoading;
