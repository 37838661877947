import { IS_MOBILE } from "@/const";
import { message } from "antd";
import { Toast } from "antd-mobile";
import { CurrencyFormat } from "@/utils/enum";

const getDeviceType = IS_MOBILE ? "mobile" : "desktop";

export const getComponent = name => {
  try {
    let cpn = require(`@/views/${getDeviceType}/${name}`);
    return cpn.default();
  } catch (e) {
    console.log(e);
  }
};

export const getImg = name => {
  try {
    let img = require(`@/assets/${getDeviceType}/${name}`);
    return img.default;
  } catch (e) {
    console.log(e);
  }
};

export const copyText = (val = "") => {
  if (!val) return;
  navigator.clipboard.writeText(val);
  IS_MOBILE
    ? Toast.show({
        icon: "success",
        content: "复制成功",
      })
    : message.success("复制成功");
};

// 取到小數點後兩位
export const round = num => {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
};

// 補零
export const zeroPadding = (amount, currency = 0) => {
  const { tofix } = CurrencyFormat.find(i => i.key === currency);
  const dotNum = amount.split(".")[1];
  if (dotNum) {
    const amountStr = amount.replace(".", "");
    const repeatNum = tofix - dotNum.length;
    return Number(`${amountStr}${"0".repeat(repeatNum)}`);
  } else {
    return Number(`${amount}${"0".repeat(tofix)}`);
  }
};

// 限制小數點輸入位數
export const limitDecimalPoint = (val, limit = 2) => {
  const dotNum = val.split(".")[1];
  if (dotNum && dotNum.length > limit) return false;
  return true;
};
