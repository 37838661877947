import { useState } from "react";
import { Space, Button } from "antd";
import { selectUser, getUsers, addUser } from "@/store/slice/user";
import SearchBar from "@/components/SearchBar";
import Detail from "@/components/Detail";
import { NormalTable } from "@/components/factory/TableFactory";
import Tag from "@/components/Tag";
import { useList } from "@/utils/hook";
import { IsBoolEnum } from "@/utils/enum";
import { dateFormat } from "@/utils/format";
import AddEdit from "./AddEdit";

const User = () => {
  const searchFields = {
    name__k: { type: "string", label: "名称" },
    username__k: { type: "string", label: "帐号" },
    is_active: {
      type: "select",
      label: "是否启用",
      options: IsBoolEnum,
      isBool: true,
    },
  };
  const {
    res: { list, meta },
    loading: listLoading,
    handleSearch,
    handleAdd: handleAddHook,
    handleChangePage,
    handleChange,
  } = useList(getUsers, selectUser, {}, true);

  const [addVisible, setAddVisible] = useState(false);
  // const handleAddClick = () => {
  //   setAddVisible(true);
  // };
  const handleAdd = async formModel => {
    handleAddHook({ action: addUser, ...formModel });
    setAddVisible(false);
  };
  const [currentRow, setCurrentRow] = useState({});
  const [detailVisible, setDetailVisible] = useState(false);
  const handleDetailClick = record => {
    setCurrentRow(record);
    setDetailVisible(true);
  };

  const columns = [
    { title: "ID", dataIndex: "id", sorter: true },
    {
      title: "姓名",
      dataIndex: "name",
      editable: true,
      inputType: "string",
      sorter: true,
    },
    {
      title: "帐号",
      dataIndex: "username",
      editable: true,
      inputType: "string",
      sorter: true,
    },
    { title: "电话", dataIndex: "phone", editable: true, inputType: "string" },
    { title: "email", dataIndex: "email", editable: true, inputType: "string" },
    {
      title: "创建日期",
      dataIndex: "created",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "更新日期",
      dataIndex: "updated",
      render: val => dateFormat(val),
      className: "text-nowrap",
      sorter: true,
    },
    {
      title: "启用",
      dataIndex: "is_active",
      render: val => <Tag val={val} />,
    },
    {
      title: "是否为管理员",
      dataIndex: "is_admin",
      render: val => <Tag val={val} />,
    },
    {
      title: "是否为代理",
      dataIndex: "is_agent",
      render: val => <Tag val={val} />,
    },
    {
      title: "是否为开发者",
      dataIndex: "is_developer",
      render: val => <Tag val={val} />,
    },
    {
      title: "是否为职员",
      dataIndex: "is_staff",
      render: val => <Tag val={val} />,
    },
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            type="text"
            className="p-0"
            onClick={() => handleDetailClick(record)}
          >
            查看
          </Button>
        </Space>
      ),
    },
  ];

  const defaultColumns = [
    "id",
    "order_no",
    "name",
    "amount",
    "amount_paid",
    "currency",
    "created",
    "succeeded",
    "status",
    "action",
  ];

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchBar fields={searchFields} handleSubmit={handleSearch} />
      {/* <Button type="primary" icon={<PlusOutlined />} onClick={handleAddClick}>
        添加
      </Button> */}
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading}
        onShowSizeChange={handleChangePage}
      />
      <AddEdit
        visible={addVisible}
        onOk={handleAdd}
        onCancel={() => setAddVisible(false)}
        loading={listLoading}
        mode="add"
      />
      <Detail
        title="子代理帐户明細"
        visible={detailVisible}
        data={currentRow}
        onCancel={() => setDetailVisible(false)}
        loading={false}
        columns={columns.filter(i => i.dataIndex !== "action")}
      />
    </Space>
  );
};
export default User;
