import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  Image,
  Ellipsis,
  Space,
  Button,
  Card,
  ErrorBlock,
  Toast,
} from "antd-mobile";

import useDetail from "@/hook/useDetail";
import {
  getTransfers,
  succeededTransferV2,
  selectTransfer,
  claimTransfer,
} from "@/store/slice/transfer";

import { priceFormat, dateFormat } from "@/utils/format";

import { ListItem } from "@/components/mobile/List";
import FullLoading from "@/components/mobile/FullLoading";
import ImageUploader from "@/components/mobile/ImageUploader";
import TransferSuccess from "@/components/mobile/TransferSuccess";

const defaultSearch = { status__in: "8,20" };

function Transfer() {
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isError, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [currentRow, setCurrentRow] = useState(null);
  const [successData, setSuccessData] = useState(null);

  const [files, setFiles] = useState([]);

  const getTransfer = useCallback(async () => {
    const { payload } = await dispatch(getTransfers(defaultSearch));
    if (payload.status !== 200) {
      setError(true);
      return;
    }
    if (payload.data?.data && payload.data?.data[0]) {
      const _currentRow = payload.data.data[0];
      const { status, data } = await claimTransfer({ id: _currentRow.id });
      if (status === 200) {
        setCurrentRow(data);
      }
      if (status === 500) {
        setError(true);
        setErrorMsg(data);
      }
    } else {
      setCurrentRow(null);
    }
  }, [dispatch]);

  const { loading: activeLoading, handleEdit } = useDetail(
    { id: null },
    selectTransfer,
  );

  const onSubmit = async () => {
    if (!files.length) {
      Toast.show({
        icon: "fail",
        content: (
          <div className="text-center">
            請上传回单，若不上传回单，将无法进行下一步操作
          </div>
        ),
      });
      return;
    }
    const formData = new FormData();
    files.forEach(f => formData.append("images", f));

    handleEdit({
      action: succeededTransferV2,
      id: currentRow.id,
      formData,
      onSuccess: resData => {
        setSuccessData(resData);
      },
    });
  };

  useEffect(() => {
    state ? setCurrentRow(state) : getTransfer();
  }, [state, getTransfer]);

  if (isError)
    return (
      <ErrorBlock
        status="empty"
        style={{ margin: "auto" }}
        {...(errorMsg ? { description: <>{errorMsg}</> } : {})}
      >
        <Button
          color="primary"
          onClick={() => history.push("/transferSearchList")}
        >
          前往进货订单
        </Button>
      </ErrorBlock>
    );

  if (!currentRow)
    return (
      <FullLoading
        loading={true}
        color="primary"
        wrapStyle={{ height: "100%" }}
        style={{ margin: "auto", "--size": "48px" }}
        loadingText={<span className="text-grey-3">抢单中...</span>}
      />
    );

  if (successData) return <TransferSuccess data={successData} />;

  return (
    <div className="transfer box-wrap mt-1 pb-2">
      <Card>
        <div className="header-content flex text-14 mb-1">
          <div>
            <Image
              src={currentRow.extra?.product?.url || ""}
              width={95}
              height={95}
              fit="fill"
              style={{ borderRadius: 4 }}
            />
          </div>

          <div className="flex flex-column ml-1">
            <Ellipsis
              direction="end"
              rows={2}
              content={currentRow.extra?.product?.name || "商品名称"}
            />
            <Space className="mt-auto justify-between">
              <h6 className="text-16 text-orange">
                {priceFormat({
                  val: currentRow.amount,
                  currency: currentRow.currency,
                })}
              </h6>
            </Space>
          </div>
        </div>

        <ListItem label="订单号" value={currentRow.order_no} showCopy={true} />
        <ListItem label="时间" value={dateFormat(currentRow.created)} />
        <ListItem
          label="有效时间"
          value={currentRow.expired_at}
          style={{ color: "var(--adm-color-primary)" }}
          type="countdown"
        />
        <ListItem
          label="应付金额"
          value={priceFormat({
            val: currentRow.amount,
            currency: currentRow.currency,
          })}
          style={{ color: "var(--adm-color-primary)" }}
          showCopy={true}
        />
        <ListItem showBorderBottom={false}>
          <div className="flex-center">
            <h6>
              请于<span className="text-red">有效时间</span>内付款到以下账户
            </h6>
          </div>
        </ListItem>
        <ListItem
          label="户名"
          value={currentRow.name}
          style={{ color: "var(--adm-color-primary)" }}
          showCopy={true}
        />
        <ListItem label="账号" value={currentRow.account} showCopy={true} />
        <ListItem label="银行" value={currentRow.bank_name} showCopy={true} />
        <ListItem
          label="开户行"
          value={currentRow.sub_bank}
          showCopy={true}
          showBorderBottom={false}
        />
      </Card>

      <ImageUploader
        onChange={file =>
          setFiles(pre => {
            return [...pre, file];
          })
        }
      />

      <Button color="primary" block onClick={onSubmit} loading={activeLoading}>
        我已完成付款
      </Button>
    </div>
  );
}

export default Transfer;
