import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Input } from "antd-mobile";

import useDetail from "@/hook/useDetail";
import { postTransfers, selectTransfer } from "@/store/slice/transfer";
import { selectAgentAccts, getAgentAccts } from "@/store/slice/agentAccts";

import FormExtraLabel from "@/components/mobile/FormExtraLabel";
import { PickerBank } from "@/components/mobile/FormItemSelectorPicker";
import ModalConfirm from "@/components/mobile/ModalConfirm";

import { priceFormat } from "@/utils/format";
import { zeroPadding, limitDecimalPoint } from "@/utils/others";

function Withdraw() {
  const { currentRow } = useSelector(selectAgentAccts);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { loading, handleAdd } = useDetail({ id: null }, selectTransfer, false);

  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);

  const handleAll = () => {
    form.setFieldsValue({
      amount: priceFormat({ val: currentRow.profit, showUnit: false }),
    });
  };

  const onFinish = values => {
    const amount = zeroPadding(values.amount);
    const params = {
      ...values,
      amount,
      bank_name: values.bank_name.join(""),
      agent_id: currentRow.agent_id,
      agent_acct_id: currentRow.id,
    };

    handleAdd({
      action: postTransfers,
      onSuccess: () => {
        dispatch(getAgentAccts());
        setVisibleSuccessModal(true);
      },
      ...params,
    });
  };

  return (
    <div className="mt-1">
      <Form
        form={form}
        onFinish={onFinish}
        style={{ "--padding-left": "10px" }}
        footer={
          <Button block type="submit" color="primary" loading={loading}>
            提交
          </Button>
        }
      >
        <Form.Header>
          可提现
          <span className="text-red">
            {currentRow.profit ? priceFormat({ val: currentRow.profit }) : "-"}
          </span>
        </Form.Header>

        <Form.Item
          name="amount"
          label={
            <FormExtraLabel
              label="提现金额"
              extra="全部提现"
              onClick={handleAll}
            />
          }
          rules={[{ required: true, message: "必填" }]}
        >
          <AmountFormField />
        </Form.Item>
        <Form.Item
          name="name"
          label="持卡人姓名"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请输入持卡人姓名" />
        </Form.Item>
        <Form.Item
          name="account"
          label="银行卡号"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请输入银行卡号" type="number" />
        </Form.Item>
        <Form.Item
          name="bank_name"
          label="开户行"
          rules={[{ required: true, message: "必填" }]}
        >
          <PickerBank
            valueType="label"
            placeholder="请输入开户行"
            showSearch={true}
          />
        </Form.Item>
        <Form.Item name="sub_bank" label="支行">
          <Input placeholder="请输入支行名称" />
        </Form.Item>
        <Form.Item
          name="fund_password"
          label={
            <FormExtraLabel
              label="资金密码"
              extra="忘记资金密码?"
              isService={true}
            />
          }
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请输入资金密码" />
        </Form.Item>
      </Form>

      <ModalConfirm
        type="success"
        visible={visibleSuccessModal}
        text={
          <>
            您提现金额 ¥{form.getFieldValue("amount")}元的申请
            <br />
            已提交平台审核
          </>
        }
        onClose={() => setVisibleSuccessModal(false)}
        onConfirm={() => {
          form.resetFields();
          setVisibleSuccessModal(false);
        }}
      />
    </div>
  );
}

const AmountFormField = ({ value = "", onChange = () => {} }) => {
  const _onChange = val => {
    if (limitDecimalPoint(val)) {
      onChange(val);
    }
  };
  return (
    <div className="flex justify-between align-center">
      <Input
        placeholder="请输入提现金额"
        type="number"
        value={value}
        onChange={_onChange}
      />
      <span className="text-14">CNY</span>
    </div>
  );
};

export default Withdraw;
