import React from "react";
import { Button, message } from "antd";
import { IS_MOBILE } from "@/const";
import { generatePath } from "react-router-dom";
import Notification from "@/components/factory/NotifFactory";
import { priceFormat } from "@/utils/format";
import history from "@/utils/history";
import Niuw from "@/assets/niuw.mp3";
import Dingdong from "@/assets/dingdong.mp3";
const OrderAlertMp3 = new Audio(Niuw);
const TransferAlertMp3 = new Audio(Dingdong);
const mp3Type = {
  "order.pending": OrderAlertMp3,
  "transfer.pending": TransferAlertMp3,
};
const playMp3 = audio => {
  if (!audio) return;
  const playAudioPromise = audio.play();
  if (playAudioPromise) {
    playAudioPromise
      .then(() => {})
      .catch(err => {
        console.log("playMp3", err);
        err?.name === "NotAllowedError"
          ? message.warning("请启用浏览器声音播放权限")
          : message.error(err);
      });
  }
};

const OrderNotif = res => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const { type, data } = res;

  const handleDetailClick = () => {
    history.push(generatePath("/OrderDetail/:id", { id: data.id }));
  };
  const handleClick = () => {
    history.push(generatePath("/"));
  };
  const wsType = {
    "order.pending": {
      title: `您有一笔新的订单 ${data.id} 需要审核`,
      message: (
        <>
          <div>商户：{data.app_id}</div>
          <div>
            金额：{priceFormat({ val: data.amount, currency: data.currency })}
            <Button size="small" className="ml-1" onClick={handleDetailClick}>
              查看详情
            </Button>
          </div>
        </>
      ),
    },
    "transfer.paid": {
      title: `有一笔新的订单 ${data.id} 需要出款`,
      message: (
        <>
          <div>商户：{data.app_id}</div>
          <div>
            金额：{priceFormat({ val: data.amount, currency: data.currency })}
            <Button size="small" className="ml-1" onClick={handleClick}>
              查看
            </Button>
          </div>
        </>
      ),
    },
  };
  if (wsType[type]) {
    if (type === "order.pending") {
      if (!data.agent_id === user.id) return;
    }
    playMp3(mp3Type[type]);
    if (!IS_MOBILE) {
      return Notification({
        type: "info",
        title: wsType[type]?.title,
        message: wsType[type]?.message,
      });
    }
  } else {
    return null;
  }
};
export default OrderNotif;
