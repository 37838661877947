import React from "react";
import { Button } from "antd-mobile";

function TouchAble({ children, onClick = () => {}, style = {}, ...props }) {
  return (
    <Button
      fill="none"
      style={{ padding: 0, ...style }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}

export default TouchAble;
