import React from "react";
import {
  PullToRefresh,
  Card,
  Divider,
  Image,
  Ellipsis,
  Space,
  Button,
  InfiniteScroll,
} from "antd-mobile";
import {
  RightOutlined,
  CopyOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { dateFormat, priceFormat } from "@/utils/format";
import { todayRangeFormat } from "@/utils/dateTime";
import { OrderStatus } from "@/utils/enum";
import { copyText } from "@/utils/others";

import useLoadMoreList from "@/hook/useLoadMoreList";
import { getOrders } from "@/store/slice/order";

import SearchBar, {
  defaultSelectorOptions,
} from "@/components/mobile/SearchBar";

const StatusDisplay = ({ icon, text, color }) => {
  return (
    <div className="text-center" style={{ color }}>
      {icon({ style: { fontSize: "1.6em" } })}
      <div className="text-12">{text}</div>
    </div>
  );
};

const orderStatusHandleMap = {
  3: {
    color: "var(--adm-color-primary)",
    text: "未付款",
    icon: p => <CloseCircleFilled {...p} />,
  },
  6: {
    color: "var(--grey-1)",
    text: "已超时",
    icon: p => <CloseCircleFilled {...p} />,
  },
  7: {
    color: "var(--grey-1)",
    text: "已取消",
    icon: p => <CloseCircleFilled {...p} />,
  },
  13: {
    color: "var(--adm-color-success)",
    text: "通知成功",
    icon: p => <CheckCircleFilled {...p} />,
  },
  15: {
    color: "var(--adm-color-success)",
    text: "已完成",
    icon: p => <CheckCircleFilled {...p} />,
  },
};

const searchFields = {
  status: {
    type: "selector",
    label: "状态",
    initialValue: null,
    options: [
      ...defaultSelectorOptions,
      { label: OrderStatus[3], value: 3 },
      { label: "已超时", value: 6 },
      { label: "已完成", value: 15 },
    ],
  },
  created__btw: {
    type: "rangeDate",
    label: "时间",
  },
  order_no__in: {
    type: "string",
    placeholder: "搜索单号",
  },
};

const defaultSearch = { created__btw: todayRangeFormat() };

function OrderSearchList() {
  const history = useHistory();
  const handleDetail = state => {
    history.push({ pathname: "/orderSearchDetail", state });
  };

  const { hasMore, list, loadMore, onRefresh, onSearch } = useLoadMoreList({
    action: getOrders,
    defaultSearch,
  });

  return (
    <>
      <SearchBar fields={searchFields} onSubmit={onSearch} />
      <PullToRefresh onRefresh={onRefresh}>
        <div className="box-wrap">
          {list.map(item => (
            <Card key={item.id} className="mt-1">
              {/* header */}
              <div className="flex align-center">
                <span className="text-12 text-grey-2">
                  订单号：{item.order_no}
                </span>
                <Button
                  size="small"
                  fill="none"
                  className="p-0"
                  style={{ marginLeft: "0.4em" }}
                  onClick={() => copyText(item.order_no)}
                >
                  <CopyOutlined style={{ color: "var(--adm-color-info-2)" }} />
                </Button>
                <div
                  className="flex align-center"
                  style={{
                    flex: "1 1 auto",
                    height: "27px",
                    justifyContent: "flex-end",
                  }}
                  onClick={() => handleDetail(item)}
                >
                  <RightOutlined
                    style={{
                      fontSize: "0.8em",
                      color: "var(--grey-2)",
                    }}
                  />
                </div>
              </div>
              <Divider style={{ marginTop: "0.2em", marginBottom: "0.8em" }} />

              {/* body */}
              <div className="flex">
                {/* left */}
                <div>
                  <Image
                    src={item.extra?.product?.url || ""}
                    width={42}
                    height={42}
                    fit="fill"
                    style={{ borderRadius: 4 }}
                  />
                </div>

                {/* right */}
                <div
                  className="flex flex-column ml-1"
                  style={{ flex: "1 1 auto" }}
                >
                  <div className="flex justify-between">
                    <div>
                      <Ellipsis
                        className="text-14"
                        direction="end"
                        rows={1}
                        content={item.extra?.product?.name || "商品名称"}
                      />
                      <Space className="mt-auto justify-between align-center">
                        <span className="text-16 text-w-600 text-orange">
                          {priceFormat({
                            val: item.amount,
                            currency: item.currency,
                          })}
                        </span>
                      </Space>
                    </div>

                    <div>
                      {orderStatusHandleMap[item.status] ? (
                        StatusDisplay({ ...orderStatusHandleMap[item.status] })
                      ) : (
                        <span className="text-12 text-grey-2">
                          {OrderStatus[item.status]}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-between align-center text-12 text-grey-2">
                    <span>{item.payer_name}</span>
                    <span>{dateFormat(item.created)}</span>
                  </div>
                </div>
              </div>
            </Card>
          ))}

          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </div>
      </PullToRefresh>
    </>
  );
}

export default OrderSearchList;
