import React from "react";
// import { Provider } from "react-redux";
import { ErrorBlock } from "antd-mobile";
// import zh_CN from "antd/lib/locale/zh_CN";
// import MainRoute from "@/views/MainRoute";
// import store from "@/store";
// import "@/less/desktop/main.less";
// import { interceptor } from "@/utils/request";
// import DesktopGlobalLayout from "@/DesktopGlobalLayout";
// interceptor(store);

function DesktopApp() {
  return (
    <ErrorBlock
      status="default"
      fullPage
      title="请用手机版打开"
      description=""
    />

    // <ConfigProvider locale={zh_CN}>
    //   <Provider store={store}>
    //     <MainRoute>
    //       <DesktopGlobalLayout />
    //     </MainRoute>
    //   </Provider>
    // </ConfigProvider>
  );
}

export default DesktopApp;
