import React from "react";
import { Button } from "antd-mobile";
import { CopyOutlined } from "@ant-design/icons";
import { copyText } from "@/utils/others";

function CopyBottom({ text }) {
  return (
    <Button
      size="small"
      fill="none"
      className="p-0"
      style={{ marginLeft: "0.4em" }}
      onClick={() => copyText(text)}
    >
      <CopyOutlined style={{ color: "var(--adm-color-info-2)" }} />
    </Button>
  );
}

export default CopyBottom;
