import React from "react";
import { Divider } from "antd-mobile";
import { Statistic } from "antd";
import moment from "moment";
import CopyBottom from "./CopyBottom";
const { Countdown } = Statistic;

const typeMap = {
  default: ({ label, value, ...p }) => (
    <>
      <Label text={label} />
      <Value text={value} {...p} />
    </>
  ),
  countdown: ({ label, value }) => (
    <>
      <Label text={label} />
      {value ? (
        <Countdown
          value={moment(value)}
          format="H 时 m 分 s 秒"
          valueStyle={{
            // color: "var(--adm-color-primary)",
            fontSize: "12px",
          }}
        />
      ) : (
        "-"
      )}
    </>
  ),
};

export const ListItem = ({
  children,
  showBorderBottom = true,
  type = "default",
  wrapProps = {},
  ...props
}) => {
  return (
    <div {...wrapProps}>
      <div className={children ? "" : "flex justify-between align-center"}>
        {typeMap[type] ? typeMap[type](props) : children}
      </div>
      {showBorderBottom && <Divider style={{ margin: "10px 0" }} />}
    </div>
  );
};

const Label = ({ text = "" }) => {
  return <span className="text-12 text-grey-2">{text}</span>;
};

const Value = ({ text = "", showCopy = false, style = {} }) => {
  return (
    <div className="flex align-center">
      <h6 className={`text-12 mb-0`} style={style}>
        {text}
      </h6>
      {showCopy && <CopyBottom text={text} />}
    </div>
  );
};

function ListC({ source = [] }) {
  return <></>;
}

export default ListC;
