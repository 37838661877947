import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Grid, AutoCenter, Switch } from "antd-mobile";
import TouchAble from "@/components/mobile/TouchAble";
import {
  selectAuth,
  setUser,
  putIsTransfer,
  putIsOrder,
} from "@/store/slice/auth";

const menu = [
  {
    path: "/transferList",
    title: "自助进货",
    subTitle: "拼多多",
    icon: require("@/assets/mobile/home/menu-0.png").default,
  },
  {
    path: "/orderList",
    title: "自助出货",
    subTitle: "京东",
    icon: require("@/assets/mobile/home/menu-1.png").default,
  },
  {
    path: "/financialCenter",
    title: "财务中心",
    subTitle: "账单查询",
    icon: require("@/assets/mobile/home/menu-2.png").default,
  },
  {
    path: "/subAgent",
    title: "团队中心",
    subTitle: "团队佣金",
    icon: require("@/assets/mobile/home/menu-3.png").default,
  },
  {
    path: "/sellUsdt",
    title: "出售虚拟货币",
    subTitle: "高额收益",
    icon: require("@/assets/mobile/home/menu-4.png").default,
  },
  {
    path: "/card",
    title: "银行卡管理",
    subTitle: "收款方式",
    icon: require("@/assets/mobile/home/menu-5.png").default,
  },
];

function BodyMenu({ goPath }) {
  const { user } = useSelector(selectAuth);
  const dispatch = useDispatch();

  const handleUpdate = async ({ action, sw }) => {
    const { status, data } = await action({ sw });
    if (status === 200) {
      dispatch(setUser(data));
    }
  };

  return (
    <div className="box-wrap body-menu">
      <Card>
        <div className="card-title">
          <h2 className="title">商品大厅</h2>
          <div className="control" style={{ marginRight: "10px" }}>
            <span className="label">进货</span>
            <Switch
              checked={user.is_transfer}
              onChange={val => handleUpdate({ action: putIsTransfer, sw: val })}
              style={{
                "--height": "20px",
                "--width": "40px",
                "--adm-color-primary": "#35cc90",
              }}
            />
          </div>
          <div className="control">
            <span className="label">出货</span>
            <Switch
              checked={user.is_order}
              onChange={val => handleUpdate({ action: putIsOrder, sw: val })}
              style={{
                "--height": "20px",
                "--width": "40px",
                "--adm-color-primary": "#35cc90",
              }}
            />
          </div>
        </div>
        <Grid columns={3} gap={8} className="item">
          {menu.map(item => (
            <Grid.Item key={item.path}>
              <TouchAble
                onClick={() => goPath(item.path)}
                fill=""
                style={{ width: "100%" }}
              >
                <Card>
                  <div className="flex-center" style={{ height: "40px" }}>
                    <div
                      className="bg-img-center"
                      style={{ backgroundImage: `url(${item.icon})` }}
                    />
                  </div>
                  <AutoCenter>
                    <span className="sub-title">{item.subTitle}</span> <br />
                    <span className="title">{item.title}</span>
                  </AutoCenter>
                </Card>
              </TouchAble>
            </Grid.Item>
          ))}
        </Grid>
      </Card>
    </div>
  );
}

export default BodyMenu;
