import { configureStore } from "@reduxjs/toolkit";
import routerTabReducer from "./slice/routerTab";
import authReducer from "./slice/auth";
import transferReducer from "./slice/transfer";
import userReducer from "./slice/user";
import cardReducer from "./slice/card";
import bankReducer from "./slice/bank";
import cardAcctReducer from "./slice/cardAcct";
import orderReducer from "./slice/order";
import agentAcctLogReducer from "./slice/agentAcctLog";
import agentAcctsReducer from "./slice/agentAccts";
import agentDailyReducer from "./slice/agentDaily";
import agentSubDailyReducer from "./slice/agentSubDaily";
import addressesReducer from "./slice/addresses";
import noticesReducer from "./slice/notices";
import serviceReducer from "./slice/service";
import fundPasswordReducer from "./slice/fundPassword";
import usdtReducer from "./slice/usdt";
import uiReducer from "./slice/uuii";

export default configureStore({
  reducer: {
    routerTab: routerTabReducer,
    auth: authReducer,
    transfer: transferReducer,
    user: userReducer,
    card: cardReducer,
    bank: bankReducer,
    cardAcct: cardAcctReducer,
    order: orderReducer,
    agentAcctLog: agentAcctLogReducer,
    agentAccts: agentAcctsReducer,
    agentDaily: agentDailyReducer,
    agentSubDaily: agentSubDailyReducer,
    addresses: addressesReducer,
    notices: noticesReducer,
    service: serviceReducer,
    fundPassword: fundPasswordReducer,
    usdt: usdtReducer,
    uuii: uiReducer,
  },
});
