import { getTransfers } from "@/store/slice/transfer";
import TransferTypes from "./indexTemplate";
const TransferDefault = () => {
  return (
    <TransferTypes
      key="default"
      action={getTransfers}
      params={{ status__in: "8,20", o: "created" }}
    />
  );
};
export default TransferDefault;
