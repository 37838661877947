import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { metaToPagin } from "@/utils/format";

export const getOrders = createAsyncThunk(
  "order/getList",
  async (params = {}) => {
    const res = await request({
      url: "/api/agent/orders",
      method: "get",
      params,
    });
    return res;
  },
);
export const getOrdersSum = createAsyncThunk(
  "order/getListSum",
  async (params = {}) => {
    const res = await request({
      url: "/api/agent/orders/sum",
      method: "get",
      params,
    });
    return res;
  },
);
export const getOrder = createAsyncThunk("order/getDetail", async id => {
  const res = await request({
    url: `/api/agent/orders/${id}`,
    method: "get",
  });
  return res;
});
export const addOrder = async params => {
  const res = await request({
    url: `/api/agent/orders`,
    method: "post",
    data: params,
  });
  return res;
};
export const editOrder = async params => {
  const res = await request({
    url: `/api/agent/orders/${params.id}`,
    method: "post",
    data: params.formModel,
  });
  return res;
};

export const approveOrder = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/agent/orders/${id}/approve`,
    method: "post",
    data: formModel,
  });
  return res;
};

export const approveOrderV2 = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/agent/v2/orders/${id}/approve`,
    method: "post",
    data: formModel.formData,
  });
  return res;
};

export const denyOrder = async ({ id, formModel }) => {
  const res = await request({
    url: `/api/agent/orders/${id}/deny`,
    method: "post",
    data: { id, ...formModel },
  });
  return res;
};
export const cancelOrder = async id => {
  const res = await request({
    url: `/api/agent/orders/${id}/cancel`,
    method: "post",
    data: {},
  });
  return res;
};
export const notifyOrder = async id => {
  const res = await request({
    url: `/api/agent/orders/${id}/notify`,
    method: "post",
    data: {},
  });
  return res;
};
export const deleteOrder = async id => {
  const res = await request({
    url: `/api/agent/orders/${id}`,
    method: "delete",
  });
  return res;
};
export const bindOrder = async params => {
  const res = await request({
    url: `/api/agent/orders/bind`,
    method: "post",
    data: params,
  });
  return res;
};

// 匯出 excel
export const exportOrder = async (params = {}) => {
  const res = await request({
    url: `/api/agent/orders/export`,
    method: "get",
    params: { per_page: 9999, ...params },
  });
  return res;
};

export const slice = createSlice({
  name: "order",
  initialState: {
    list: [],
    meta: {},
    currentRow: {},
    sum: {},
  },
  reducers: {
    setOrders: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {
    [getOrders.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.list = data.data;
      state.meta = metaToPagin(data.meta);
    },
    [getOrder.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.currentRow = data;
    },
    [getOrdersSum.fulfilled]: (state, action) => {
      const { status, data } = action.payload;
      if (status !== 200) return;
      state.sum = data;
    },
  },
});
export const { setOrders } = slice.actions;
export const selectOrder = state => state.order;
export default slice.reducer;
