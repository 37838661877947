import React, { useCallback, useEffect, useState, useRef } from "react";
import { DownloadOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { Card, Form, Row, Col, Space, Button, message } from "antd";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { IS_MOBILE } from "@/const";
import SearchSelect from "@/components/SearchSelect";
import MobileDatePicker from "@/components/MobileDatePicker";
import { formLayout } from "@/utils/enum";
import {
  searchFieldsFormat,
  rangeLimitDays,
  searchFieldsFilterInitialValues,
} from "@/utils/format";
import { useSearchCache } from "@/utils/hook";
import InputFactory from "./factory/InputFactory";

/**
 * fields
 * @param {object} fields
 * @param {object} fields.name - field key
 * @param {string} fields.name.type - input type [string, select, switch, checkbox, rangeDate]
 * @param {string} fields.name.label - form label
 * @param {string} fields.name.options - when type === 'select', pass options.
 * @function handleSubmit  - 送出表單
 */

const valuePropName = type =>
  type === "checkbox" || type === "switch" ? "checked" : "value";
export const SearchFormFactory = ({
  fields = {},
  handleSubmit = () => {},
  showExportBtn = false,
  exportLoading = false,
  handleExport = () => {},
  otherBtn = null,
}) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const handleReset = async () => {
    await form.resetFields();
    await form.setFieldsValue(initialValues);
    handleResetSearchCache({ pathname });
  };
  const { pathname } = useLocation();
  const {
    handleGetPageSearchCache,
    handleSetSearchCache,
    handleResetSearchCache,
  } = useSearchCache();
  const handleFields = useCallback(() => {
    const formModel = form.getFieldsValue();
    Object.keys(formModel).forEach(i => {
      formModel[i] === undefined && delete formModel[i];
    });
    const params = searchFieldsFormat({ fields, formModel });
    handleSetSearchCache({ pathname, formModel: params });
    return params;
  }, [fields, form, handleSetSearchCache, pathname]);

  const handleSearchClick = useCallback(() => {
    const params = handleFields();
    handleSubmit(params);
  }, [handleSubmit, handleFields]);

  const handleExportClick = useCallback(() => {
    const params = handleFields();
    if (!params.created__btw && !params.paid_at__btw) {
      message.warning("请选择时间区间");
      return;
    }
    // 下載天數不能超過 90 天
    const dateStr = params.created__btw || params.paid_at__btw;
    const isLimit = rangeLimitDays(90, dateStr);
    isLimit ? handleExport(params) : message.warning("下載量不得超過90天");
  }, [handleExport, handleFields]);

  const init = async () => {
    const fieldsInitialValues = searchFieldsFilterInitialValues(fields);
    setInitialValues(fieldsInitialValues);
    let searchCache = handleGetPageSearchCache();
    Object.keys(searchCache).forEach(i => {
      if (i.indexOf("__btw") !== -1) {
        searchCache[i] = searchCache[i].split("~").map(j => moment(j));
      }
      if (fields[i]?.type === "searchSelect") {
        const temp = searchCache[i].split(",");
        searchCache[i] = temp.map(item => Number(item));
      }
      // 资金类型格式處理
      if (["type__ne", "type__eq"].includes(i)) {
        searchCache["funding_type"] = `{"${i}":${searchCache[i]}}`;
      }
    });
    await form.setFieldsValue({ ...fieldsInitialValues, ...searchCache });
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <Card size="small">
      <Form form={form} {...formLayout}>
        <Row gutter={24}>
          {Object.keys(fields).map(i => {
            const inputFactoryProps = fields[i];
            const { optionLabel, ...rest } = inputFactoryProps;
            if (fields[i].type === "hidden") return null;
            return (
              <Col xs={24} md={12} xl={8} key={i}>
                <Form.Item
                  name={i}
                  label={fields[i].label}
                  valuePropName={valuePropName(fields[i].type)}
                >
                  {inputFactoryProps.type === "searchSelect" ? (
                    <SearchSelect
                      {...rest}
                      searchKey={inputFactoryProps.searchKey || "name"}
                      val={inputFactoryProps.val || "id"}
                      label={optionLabel}
                    />
                  ) : (
                    <InputFactory
                      {...inputFactoryProps}
                      id={`search_${fields[i].label}`}
                    />
                  )}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
        <Row justify="space-between">
          <Col span={8} className="text-left">
            <Space size="small">
              {showExportBtn && (
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={handleExportClick}
                  loading={exportLoading}
                >
                  汇出
                </Button>
              )}
              {otherBtn}
            </Space>
          </Col>
          <Col span={8} className="text-right">
            <Space size="small">
              <Button onClick={handleReset}>清除</Button>
              <Button type="primary" onClick={handleSearchClick}>
                搜寻
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export const SearchCollapseFormFactory = ({
  fields = {},
  handleSubmit = () => {},
}) => {
  const [form] = Form.useForm();
  const [expand, setExpand] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const mobileDatePickerRef = useRef(null);
  const handleReset = async () => {
    await form.resetFields();
    await form.setFieldsValue(initialValues);
    if (mobileDatePickerRef) mobileDatePickerRef.current.reset();
  };

  const handleFields = useCallback(() => {
    let formModel = form.getFieldsValue();
    Object.keys(formModel).forEach(i => {
      formModel[i] === undefined && delete formModel[i];
      if (i.indexOf(".") > 0) delete formModel[i];
    });
    const params = searchFieldsFormat({ fields, formModel });
    return params;
  }, [fields, form]);

  const handleSearchClick = useCallback(() => {
    const params = handleFields();
    handleSubmit(params);
    setExpand(false);
  }, [handleFields, handleSubmit]);
  const handleMoreClick = () => setExpand(pre => !pre);

  const init = async () => {
    const fieldsInitialValues = searchFieldsFilterInitialValues(fields);
    setInitialValues(fieldsInitialValues);
    await form.setFieldsValue({ ...fieldsInitialValues });
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card size="small" bodyStyle={{ padding: "8px 12px" }}>
      <Form form={form} className="ant-collapse-search-form">
        <Row gutter={24}>
          {Object.keys(fields).map((i, idx) => {
            const inputFactoryProps = fields[i];
            const { optionLabel, ...rest } = inputFactoryProps;
            const count = expand ? Object.keys(fields).length : 1;
            if (count < idx + 1) return null;
            if (fields[i].type === "hidden") return null;
            return (
              <Col xs={24} key={i}>
                {inputFactoryProps.type === "rangeDate" ? (
                  <MobileDatePicker
                    ref={mobileDatePickerRef}
                    name={i}
                    label={fields[i].label}
                    form={form}
                    initialValue={fields[i]?.initialValue}
                    {...rest}
                  />
                ) : (
                  <Form.Item
                    name={i}
                    label={fields[i].label}
                    valuePropName={valuePropName(fields[i].type)}
                  >
                    {inputFactoryProps.type === "searchSelect" ? (
                      <SearchSelect
                        {...rest}
                        searchKey={inputFactoryProps.searchKey || "name"}
                        val={inputFactoryProps.val || "id"}
                        label={optionLabel}
                      />
                    ) : (
                      <InputFactory
                        {...inputFactoryProps}
                        id={`search_${fields[i].label}`}
                      />
                    )}
                  </Form.Item>
                )}
              </Col>
            );
          })}
        </Row>
        <Row gutter={12}>
          <Col span={12} className="text-right">
            <Button block onClick={handleReset}>
              清除
            </Button>
          </Col>
          <Col span={12}>
            <Button block type="primary" onClick={handleSearchClick}>
              搜寻
            </Button>
          </Col>
          <Col span={24}>
            <Button block type="link" onClick={handleMoreClick}>
              {expand ? <UpOutlined /> : <DownOutlined />}更多搜寻条件
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
const SearchBar = props => {
  return IS_MOBILE ? (
    <SearchCollapseFormFactory {...props} />
  ) : (
    <SearchFormFactory {...props} />
  );
};

export default SearchBar;
