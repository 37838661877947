import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, Ellipsis, Image, SpinLoading, Button, Toast } from "antd-mobile";

import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";

import useDetail from "@/hook/useDetail";
import { succeededTransferV2, selectTransfer } from "@/store/slice/transfer";

import { dateFormat, priceFormat } from "@/utils/format";
import { OrderStatus } from "@/utils/enum";

import { ListItem } from "@/components/mobile/List";
import ImageUploader from "@/components/mobile/ImageUploader";
import TransferSuccess from "@/components/mobile/TransferSuccess";

const StatusDisplay = ({ icon, text, color }) => {
  return (
    <div className="text-center" style={{ color }}>
      {icon({ style: { fontSize: "3em" } })}
      <div className="text-14">{text}</div>
    </div>
  );
};

/**
 * 完成 9,15,
 * 已取消 ： 12
 * 未付款 ：  3
 */
const transferStatusHandleMap = {
  3: {
    color: "var(--adm-color-primary)",
    text: "未付款，请及时付款",
    icon: p => <CloseCircleFilled {...p} />,
  },
  9: {
    color: "var(--adm-color-success)",
    text: "已完成",
    icon: p => <CheckCircleFilled {...p} />,
  },
  12: {
    color: "var(--grey-1)",
    text: "已取消",
    icon: p => <CloseCircleFilled {...p} />,
  },
  15: {
    color: "var(--adm-color-success)",
    text: "已完成",
    icon: p => <CheckCircleFilled {...p} />,
  },
};

function TransferSearchDetail() {
  const { state } = useLocation();

  const [files, setFiles] = useState([]);
  const [successData, setSuccessData] = useState(null);
  const { loading: activeLoading, handleEdit } = useDetail(
    { id: null },
    selectTransfer,
  );

  const onSubmit = () => {
    if (!files.length) {
      Toast.show({
        icon: "fail",
        content: (
          <div className="text-center">
            請上传回单，若不上传回单，将无法进行下一步操作
          </div>
        ),
      });
      return;
    }
    const formData = new FormData();
    files.forEach((f, idx) => {
      formData.append("images", f);
    });

    handleEdit({
      action: succeededTransferV2,
      id: state.id,
      formData,
      onSuccess: resData => {
        setSuccessData(resData);
      },
    });
  };

  if (!state?.id)
    return (
      <SpinLoading
        color="primary"
        style={{ margin: "auto", "--size": "48px" }}
      />
    );

  if (successData) return <TransferSuccess data={successData} />;

  return (
    <div className="box-wrap mt-1">
      <Card>
        <div className="mt-1 mb-1">
          {transferStatusHandleMap[state.status] ? (
            StatusDisplay({ ...transferStatusHandleMap[state.status] })
          ) : (
            <span className="text-14">{OrderStatus[state.status]}</span>
          )}
        </div>
        <div className="flex mb-1">
          <div className="mr-1">
            <Image
              src={state.extra?.product?.url || ""}
              width={66}
              height={66}
              fit="fill"
              style={{ borderRadius: 4 }}
            />
          </div>
          <div style={{ flex: "1 1 auto", textAlign: "end" }}>
            <Ellipsis
              className="text-14"
              direction="end"
              rows={2}
              content={state.extra?.product?.name || "商品名称"}
            />
            <span className="text-16 text-orange text-w-600">
              {priceFormat({
                val: state.amount,
                currency: state.currency,
              })}
            </span>
          </div>
        </div>
        <ListItem label="订单号" value={state.order_no} showCopy={true} />
        <ListItem label="时间" value={dateFormat(state.created)} />
        <ListItem
          label="有效时间"
          value={state.expired_at}
          style={{ color: "var(--adm-color-primary)" }}
          type="countdown"
        />
        <ListItem
          label="应付金额"
          value={priceFormat({
            val: state.amount,
            currency: state.currency,
          })}
          style={{ color: "var(--adm-color-primary)" }}
          showCopy={true}
        />
        <ListItem showBorderBottom={false}>
          <div className="flex-center">
            <h6>
              请于<span className="text-red">有效时间</span>内付款到以下账户
            </h6>
          </div>
        </ListItem>
        <ListItem
          label="户名"
          value={state.name}
          style={{ color: "var(--adm-color-primary)" }}
          showCopy={true}
        />
        <ListItem label="账号" value={state.account} showCopy={true} />
        <ListItem label="银行" value={state.bank_name} showCopy={true} />
        <ListItem
          label="开户行"
          value={state.sub_bank}
          showCopy={true}
          showBorderBottom={false}
        />
      </Card>
      <ImageUploader
        onChange={file =>
          setFiles(pre => {
            return [...pre, file];
          })
        }
      />

      <Button
        color="primary"
        block
        onClick={onSubmit}
        className="mt-1 mb-4"
        loading={activeLoading}
      >
        我已完成付款
      </Button>
    </div>
  );
}

export default TransferSearchDetail;
